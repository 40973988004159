import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./device_actions";
import Api from '../../services/api';
export function* addDevice(data) {
    try {
        yield delay(300);
        let device = yield Api.put('/device/add', data.params);
        if(device && device.data){
            yield put({type: types.DEVICE_ADD + '_SUCCESS', payload: device.data});
            data.cb && data.cb(null, device.data);
        }
        else{
            data.cb && data.cb(device, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchAddDevice() {
    while (true){
        const watcher = yield takeLatest(types.DEVICE_ADD,addDevice);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
