import {delay} from 'redux-saga';
import {takeLatest, take, cancel} from 'redux-saga/effects';
import {registerLink, types} from "./company_actions";
import Api from '../../services/api';
import Config from '../../config';

//##############################
export function* get_company_by_id(data) {
    try {
        yield delay(300);
        let get_company_by_id = yield Api.put('/company/get',data.params);
        console.log("get_company_by_id",get_company_by_id)
        if(get_company_by_id && get_company_by_id.data){
            data.cb && data.cb(null, get_company_by_id.data);
        }
        else{
            if(data.cb) data.cb(get_company_by_id, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}
export function* watch_get_company_by_id() {
    while (true){
        const watcher = yield takeLatest(types.GET_COMPANY_BY_ID,get_company_by_id);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* register(data) {
    try {
        yield delay(300);
        // Config.getToken = true;
        let register = yield Api.put('/company/register',data.params);
        if(register && register.data){
            // yield localStorage.setItem('LOGIN', JSON.stringify(register.data));
            data.cb && data.cb(null, register.data);
            // return;
        }
        else{
            if(data.cb) data.cb(register, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchRegisterCompany() {
    while (true){
        const watcher = yield takeLatest(types.REGISTER,register);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* watchRegisterLink() {
    while (true){
        const watcher = yield takeLatest(types.REGISTERLINK,registerLink);
        //yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################
export function* editCompany(data) {
    try{
        yield delay(300);
        let edit = yield Api.put('/company/edit', data.params);
        if(edit && edit.data){
            data.cb && data.cb(null, edit.data);
        }
        else{
            if(data.cb) data.cb(edit, null);
        }
    }
    catch (e){
        data.cb && data.cb({code:1000 ,message:'Update info is error'}, null);
    }
}

export function* watchEditCompany() {
    while (true){
        const watcher = yield takeLatest(types.EDIT_COMPANY,editCompany);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################
export function* loginCompany(data) {
    try{
        yield delay(300);
        let login = yield Api.put('/company/login', data.params);
        if(login && login.data){
            Config.tokenLocal.token = login.token;
            yield localStorage.setItem('LOGIN', JSON.stringify(login.data));
            yield localStorage.setItem('TOKEN', JSON.stringify(Config.tokenLocal));
            data.cb && data.cb(null, login.data);
            return;
        }
        else{
            if(data.cb) data.cb(login, null);
        }
    }
    catch (e){
        data.cb && data.cb({code:1000 ,message:'Please attempt to log in again after some time'}, null);
    }
}

export function* watchLoginCompany() {
    while (true){
        const watcher = yield takeLatest(types.LOGIN_COMPANY,loginCompany);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* changePass(data) {
    try{
        yield delay(300);
        let changePass = yield Api.put('/company/reset', data.params);
        if(changePass && changePass.data){
            data.cb && data.cb(null, changePass.data);
            return;
        }
        else{
            if(data.cb) data.cb(changePass, null);
        }
    }
    catch (e){
        data.cb && data.cb({code:1000 ,message:'Change password is error'}, null);
    }
}

export function* watchChangePassCompany() {
    while (true){
        const watcher = yield takeLatest(types.CHANGE_PASS,changePass);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* inactive(data) {
    try{
        yield delay(300);
        let inactive = yield Api.delete('/company/inactive');
        if(inactive && inactive.data){
            data.cb && data.cb(null, inactive.data);
            return;
        }
        else{
            if(data.cb) data.cb(inactive, null);
        }
    }
    catch (e){
        data.cb && data.cb({code:1000 ,message:'Inactive company is error'}, null);
    }
}

export function* watchInactiveCompany() {
    while (true){
        const watcher = yield takeLatest(types.INACTIVE_COMPANY,inactive);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################


//##############################
export function* updatepayment(data) {
    try{
        yield delay(300);
        let login = yield Api.put('/company/change/payment', data.params);
        if(login && login.data){
            Config.tokenLocal.token = login.token;
            yield localStorage.setItem('LOGIN', JSON.stringify(login.data));
            data.cb && data.cb(null, login.data);
            return;
        }
        else{
            if(data.cb) data.cb(login, null);
        }
    }
    catch (e){
        data.cb && data.cb({code:1000 ,message:'Please attempt to log in again after some time'}, null);
    }
}

export function* watchUpdatepayment() {
    while (true){
        const watcher = yield takeLatest(types.CHANGE_PAYMENT,updatepayment);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
