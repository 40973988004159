// action types
export const types = {
    EVENT_LIST: 'EVENT_LIST',
    EVENT_LIST_SUCCESS: 'EVENT_LIST_SUCCESS',
    EVENT_ADD: 'EVENT_ADD',
    EVENT_ADD_SUCCESS: 'EVENT_ADD_SUCCESS',
    SUMMARY_EVENT: 'SUMMARY_EVENT',
    SUMMARY_EVENT_SUCCESS: 'SUMMARY_EVENT_SUCCESS',
};

export function getListEvent(params,cb) {
    return{
        type: types.EVENT_LIST,
        params,
        cb
    }
}

export function addEvent(params,cb) {
    return{
        type: types.EVENT_ADD,
        params,
        cb
    }
}

export function summaryEvent(params,cb) {
    return{
        type: types.SUMMARY_EVENT,
        params,
        cb
    }
}
