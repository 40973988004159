import React, {Component} from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

class PrintButton extends Component{

    parseNumber = (value) => {
        return value && parseFloat(value) ? parseFloat(value) : 0;
    };

    exportPDF = () => {
        let {id,headerClass,colItem,onStartEnd, nameSave, disable, onLogout} = this.props;
        const input = document.getElementById(id);
        if(disable || !input || (input && input.children.length<=0)) return;
        if(onStartEnd) onStartEnd();
        let colItemPDF = colItem ? colItem : 1;
        let pdf = new jsPDF('p', 'pt', 'letter');
        let wD= pdf.internal.pageSize.width ? pdf.internal.pageSize.width : pdf.internal.pageSize.getWidth();
        let hD= pdf.internal.pageSize.height ? pdf.internal.pageSize.height : pdf.internal.pageSize.getHeight();
        let wDs= wD - 80;
        let hDs= hD - 100;
        let height = 0;
        let temp = 0;
        pdf.setFontSize(9);
        pdf.text(wDs+20,hDs+60, 'Page 1'); //print number bottom right
        let total = input.children.length;
        if(headerClass){
            let itm = document.getElementsByClassName(headerClass)[0];
            let cln = itm.cloneNode(true);
            cln.style.marginBottom='40px';
            let heightHeader = 0;
            html2canvas(itm)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    heightHeader = wDs*canvas.height/canvas.width;
                    pdf.addImage(imgData, 'PNG', 40, 40, wDs, heightHeader);
                    html2canvas(input)
                        .then((canvas1) => {
                            let w = canvas1.width;
                            let h = canvas1.height;
                            height=heightHeader;
                            temp=height;
                            let pages = 1 ;
                            let arrPage = [0];
                            for(let i = 0; i < Math.ceil(total/colItemPDF) ; i++){
                                let itemChild = input.children[i*colItemPDF];
                                let itemChild1 = input.children[(i*colItemPDF)+1] ? input.children[(i*colItemPDF)+1] : null;
                                itemChild = !itemChild1 || (itemChild1 && this.parseNumber(window.getComputedStyle(itemChild).height) > this.parseNumber(window.getComputedStyle(itemChild1).height)) ? itemChild : itemChild1;
                                let heightItem = this.parseNumber(window.getComputedStyle(itemChild).height) + this.parseNumber(window.getComputedStyle(itemChild).marginBottom) + this.parseNumber(window.getComputedStyle(itemChild).marginTop);
                                heightItem = heightItem*w/(this.parseNumber(window.getComputedStyle(input).width));
                                height += heightItem;
                                if(height > hDs*w/wDs){
                                    arrPage[pages] = temp;
                                    pages+=1;
                                    height=heightItem;
                                }
                                temp += heightItem;
                                if(i===((Math.ceil(total/colItemPDF)-1))){
                                    arrPage[pages] = temp;
                                }
                            }
                            for (let i = 0; i < pages; i++) {
                                if (i > 0) {
                                    pdf.addPage(612, 791);
                                    pdf.setFontSize(9);
                                    pdf.text(wDs+20,hDs+60, 'Page ' + (parseInt(i,10)+1));
                                }
                                let srcImg = canvas1;
                                let onePageCanvas = document.createElement("canvas");
                                onePageCanvas.setAttribute('width', w);
                                onePageCanvas.setAttribute('height', h);
                                let ctx = onePageCanvas.getContext('2d');
                                ctx.drawImage(srcImg, 0, arrPage[i]-(i !== 0 ? heightHeader : 0), w, arrPage[i+1]-arrPage[i]-(i === 0 ? heightHeader : 0), 0, 0, w, arrPage[i+1]-arrPage[i]-(i === 0 ? heightHeader : 0));
                                let canvasDataURL = onePageCanvas.toDataURL("image/png");
                                let onePageCanvasWidth = onePageCanvas.width;
                                let onePageCanvasHight = onePageCanvas.height;
                                pdf.setPage(i + 1);
                                pdf.addImage(canvasDataURL, 'PNG', 40, 40+(i === 0 ? heightHeader : 0), wDs, wDs*onePageCanvasHight/onePageCanvasWidth-(i !== 0 ? heightHeader : 0));
                            }
                            pdf.save(nameSave ? (nameSave+'.pdf') : 'Export.pdf');
                            if(onStartEnd) onStartEnd();
                        });
                });
        }
        else{

            html2canvas(input)
                .then((canvas) => {
                    let w = canvas.width;
                    let h = canvas.height;
                    let pages = 1 ;
                    let arrPage = [0];
                    for(let i = 0; i < Math.ceil(total/colItemPDF) ; i++){
                        let itemChild = input.children[i*colItemPDF];
                        let itemChild1 = input.children[(i*colItemPDF)+1] ? input.children[(i*colItemPDF)+1] : null;
                        itemChild = !itemChild1 || (itemChild1 && this.parseNumber(window.getComputedStyle(itemChild).height) > this.parseNumber(window.getComputedStyle(itemChild1).height)) ? itemChild : itemChild1;
                        let heightItem = this.parseNumber(window.getComputedStyle(itemChild).height) + this.parseNumber(window.getComputedStyle(itemChild).marginBottom) + this.parseNumber(window.getComputedStyle(itemChild).marginTop);
                        heightItem = heightItem*w/(this.parseNumber(window.getComputedStyle(input).width));
                        height += heightItem;
                        if(height > hDs*w/wDs){
                            arrPage[pages] = temp;
                            pages+=1;
                            height=heightItem;
                        }
                        temp += heightItem;
                        if(i===((Math.ceil(total/colItemPDF)-1))){
                            arrPage[pages] = temp;
                        }
                    }
                    for (let i = 0; i < pages; i++) {
                        if (i > 0) {
                            pdf.addPage(612, 791);
                            pdf.setFontSize(9);
                            pdf.text(wDs+20,hDs+60, 'Page ' + (parseInt(i,10)+1));
                        }
                        let srcImg = canvas;
                        let onePageCanvas = document.createElement("canvas");
                        onePageCanvas.setAttribute('width', w);
                        onePageCanvas.setAttribute('height', h);
                        let ctx = onePageCanvas.getContext('2d');

                        ctx.drawImage(srcImg, 0, arrPage[i], w, arrPage[i+1]-arrPage[i], 0, 0, w, arrPage[i+1]-arrPage[i]);
                        let canvasDataURL = onePageCanvas.toDataURL("image/png");
                        let onePageCanvasWidth = onePageCanvas.width;
                        let onePageCanvasHight = onePageCanvas.height;
                        pdf.setPage(i + 1);
                        pdf.addImage(canvasDataURL, 'PNG', 40, 40, wDs, wDs*onePageCanvasHight/onePageCanvasWidth);
                    }
                    pdf.save(nameSave ? (nameSave+'.pdf') : 'Export.pdf');
                    if(onStartEnd) onStartEnd();
                });
        }

        onLogout && onLogout();
    };

    render(){
        const { label = '', className = '' } = this.props;
        return(
            className === 'export'
            ? <a class="header-roster">
                <div
                    onClick={this.exportPDF}>
                        {label}
                </div>
            </a>
            : <div className="tc mb4 mt2" style={{position:'absolute', opacity:`${className === "export" ? 100 : 0}`, width:'100%', height:'100%',margin: 0}}>
                <div id="myMm" style={{height: "1mm"}} />
                <div
                    className={`pa2 ba bw1 b--black bg-yellow black-90 br2 dib pointer dim shadow-1`}
                    onClick={this.exportPDF}>
                </div>
            </div>
        )
    }
}

export default PrintButton;