import {types} from "./welcome_actions";

const initialState = {
    welcomeList: null,
};

//Nhan action type tu Put va luu data vao store
export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.SEARCH_WELCOME + '_SUCCESS':
            return {
                ...state,
                welcomeList: action.payload
            };

        default:
            return state;
    }
}