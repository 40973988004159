/**
 * @author trang.hoang@spiritlabs.co
 * @create 2018/09/11 10:37
 */

import Config from '../config.js';
export default class Api {
    static headers() {
        let headers =  {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': 'content-type,token,secret',
        };
        headers.secret = Config.secret;
        if(Config.tokenLocal && Config.tokenLocal.token){
            headers.token = Config.tokenLocal.token;
        }
        return headers;
    }

    static get(route) {
        return this.xhr(route, null, 'GET');
    }

    static put(route, params) {
        return this.xhr(route, params, 'PUT');
    }

    static post(route, params) {
        return this.xhr(route, params, 'POST');
    }

    static delete(route, params) {
        return this.xhr(route, params, 'DELETE')
    }

    static upload(route,params){
        let url = route.indexOf('http') !== -1 ? route : (Config.host+route);
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'token': Config.tokenLocal.token,
            },
            body: params
        };
        return fetch(url, options)
            .then( resp => {
                let json = resp.json();
                if(resp.ok) {
                    console.log("===success");
                    return json
                }
                return resp.json().then(err => {
                    console.log("===error",err);
                    throw err;
                });
            })
            .catch(e => {
                throw  e;
            })
    }

    static xhr(route, params, verb) {
        let url = route.indexOf('http') !== -1 ? route : (Config.host+route);
        let options = {
            method: verb,
            headers: Api.headers(),
            body: params ? JSON.stringify(params): null
        };

        return fetch(url, options)
            .then( resp => {
                let json = resp.json();

                if(resp.ok && json) {
                    console.log("===success",url , json);
                    return json
                }
            })
            .then(function(json) {
                return json
            })
            .catch(e => {
                throw  e;
            });
    }

}