import React,{Component} from 'react';
import {Button, Image} from 'react-bootstrap';
import * as memberActions from "../../redux/member/member_actions";
import * as teamActions from "../../redux/team/team_actions";
import '../register/register.css'
import './create-password.css'

import Header from "../header/header";
import CForm from "../libs/cform/cform";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import Config from "../../config";

class OnBoardingEmailPage extends Component{


    constructor(props){
        super(props);

        this.state = {
            message : null,
            start : 0,
            infoMember: null
        };
        this.dataForm = [
            {
                type: 'TEXT',
                key: 'dataTitle',
                id: 'dataTitle',
                feedback: false,
                label: 'Full name *',
                placeholder: 'Your full name',
                className: 'shadow-1 full-name',
                def_value: '',
                validator: 'isName'
            },
            {
                type: 'TEXT',
                key: 'dataEmail',
                id: 'dataEmail',
                label: 'Email *',
                feedback: false,
                placeholder: 'Enter Email',
                className: 'shadow-1 email',
                def_value: '',
                disabled: true,
                validator: 'isRequired'
            },
            {
                type: 'PASSWORD',
                label : 'Password *',
                key: 'dataPassword',
                id: 'dataPassword',
                feedback: false,
                readonly : 'readonly',
                placeholder: 'Enter password',
                className: 'shadow-1 password',
                def_value: '',
                validator: 'isPassword'
            }
        ];
        this.vlRadio=false;
    }

    componentDidMount() {
        let id = window.location.search.split('mid=');
        id = id && id.length > 1 ? id[id.length - 1] : null;
        id = id.split('&token=');
        let idMember = id[0];
        idMember = idMember.split('&uid=');
        idMember = idMember[0];
        this.props.memberActions.getMemberInfo({id: idMember}, (err, data) => {
            if (err) {
                Config.alertMess(err);
            }
            else if (data) {
                this.setState({
                    infoMember: data
                })
            }
            if (data.status === "ACTIVE") {
                this.setState({
                    start: 1
                })
            }

        })
    }




    _onCcheckData = (e) => {
        let check = this.refs['CForm1'].formValidate();
        let dt = this.refs['CForm1'].formValue();

        if(!check || !this.vlRadio) {
            this.isSubmit=true;
            if(dt.password.validate === 'error'){
                this.setState({message: '* Password must include uppercase, lowercase, at least 1 number, at least 8 characters and maximum 20 characters'});
            }
            else{
                this.setState({message: 'Fill out all fields or input correct details to the fields and try again'});
            }
            setTimeout(()=>{
                this.setState({message: ''});
                this.isSubmit=false;
            },5000);
        }
        else {
            let id = window.location.search.split('mid=');
            id = id && id.length > 1 ? id[id.length - 1] : null;
            id = id.split('&token=');
            let idMember = id[0];
            idMember = idMember.split('&uid=');
            idMember = idMember[0];
            let token = id[1];
            let params = {
                name: dt.dataTitle.value,
                email: dt.dataEmail.value,
                password: dt.dataPassword.value,
                token: token,
                member: idMember,
                verified: '0zKc3mlR5aj7dU8nruN2woXp7qFj9zem0S2lah1Ad'
            };

            this.props.teamActions.joinTeam(params, (err, data) => {
                if (err) {
                    Config.alertMess(err);
                }
                else if (data) {
                }
            });
            this.setState({
                start:  1
            });
        }

    };

    onNext = () => {
        window.location.href = '/login';
    };

    onCheckRadio = (e) => {
        this.vlRadio = !this.vlRadio;
    };

    render(){

        if (!this.state.infoMember) return null;
        this.dataForm[0].def_value = this.state.infoMember.name;
        this.dataForm[1].def_value = this.state.infoMember.user.email;
        return(
            <div className="full_w full_h">
                <Header type={0} login={null}/>
                <div className="register row" style={{marginTop: 20}}>
                    <div className="col-sm-12" style={{padding:'0 60px'}}>
                        {this.state.start === 0 &&(
                            <div>
                                <div className="txt-condensed-extra-bold"
                                     style={{marginBottom: 20}}>
                                    Join the
                                    team {this.state.infoMember.team && this.state.infoMember.team.name ? this.state.infoMember.team.name : ''}
                                </div>
                                <p className="note-register text-center"
                                   style={{margin:'20px 0 0', fontSize: 23}}>
                                    We have sent you an email verification link to activate your account. Please click on this link to begin building your team
                                </p>
                                <div className="createpass">
                                    <CForm styleForm="form-col" ref='CForm1' data={this.dataForm}/>
                                    <input type="checkbox" name="radiocheck" onChange={(e)=>this.onCheckRadio(e)}/>
                                    <span className="txtradio">
                                        By clicking Join Now button, you have read and agree to our <a href="#">
                                    Teams & Privacy Policy.</a>
                                    </span>
                                    {this.state.message &&(
                                    <div className="alert alert-danger">
                                        {this.state.message}
                                    </div>
                                    )}
                                </div>


                                <Button onClick={this._onCcheckData} className="login_btn_login btn-linear shadow"
                                        style={{marginTop: 30}}>
                                    JOIN NOW
                                </Button>
                            </div>
                        )}

                        {this.state.start === 1 &&(
                            <div>
                                <p className="logoteam text-center">
                                    <Image style={{width: 150}} src={require('../../assets/images/bamboo.png')}/>
                                </p>

                                <div className="txt-condensed-extra-bold"
                                     style={{marginBottom:60,marginTop:60}}>
                                    Join the
                                    team {this.state.infoMember.team && this.state.infoMember.team.name ? this.state.infoMember.team.name : ''}
                                </div>

                                <p className="note-register text-center"
                                   style={{margin:'20px 0 0', fontSize: 23}}>
                                    Welcome to Teemie. Please login to start collaborating !
                                </p>
                                <Button className="login_btn_login btn-linear shadow"
                                        style={{marginTop:60}}
                                        onClick={this.onNext}>
                                    LOGIN
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
// export default OnBoardingEmailPage;
export default connect(state => ({
        // checkLogin : state.main.checkLogin,
        // teamList: state.team.teamList,
        // memberList: state.team.memberList
    }),
    (dispatch) => ({
        memberActions: bindActionCreators(memberActions, dispatch),
        // companyActions: bindActionCreators(companyActions, dispatch),
        // mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
    })
)(OnBoardingEmailPage);