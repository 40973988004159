
import React,{Component} from 'react';
import Config from "../../config";
import {Button, FormControl} from 'react-bootstrap';
import SocketIOClient from 'socket.io-client';
import * as Firebase from './push-notification';

class Messages extends Component{

    constructor(props){
        super(props);

        this.state = {
            message: '',
            content: '',
            room: '5bed33aa5aec010f21c51a21',
            team: '5bf27124742be6150f128479'
        };
    }

    componentDidMount() {
        this.onSocketConnection();
        this.onLoadMessage();
        // this.onNotification();
        // this.fbOnMessage();
    }

    onNotification() {
        Firebase.fbInitializeApp();
    }

    requestPermission() {
        Firebase.fbRequestPermission((error, token) => {
            console.log('================= error: ', error);
            console.log('================= token: ', token);
        });
    };

    fbOnMessage() {
        Firebase.fbOnMessage((error, payload) => {
            console.log('================= error: ', error);
            console.log('================= payload: ', payload);

        });
    }

    onSocketConnection() {
        console.log('================= onSocketConnection =================');

        const token = Config.tokenLocal.token;
        const socketOptions = {
            transports: ["websocket"],
            reconnection: true
        };

        // Connect socket to server
        const io = SocketIOClient(Config.host.replace('/api', '') + '?__sails_io_sdk_version=0.11.0&x-access-token=' + token, socketOptions);
        const that = this;

        io.on('connect', function () {
            console.log('================= connected =================');

            try {
                Config.socket = io;

                Config.socket.on('JOIN_ROOM', (data) => {
                    console.log('====== JOIN_ROOM => data: ', data);
                    that.setState({message: data.fullName + ': JOIN_ROOM'});
                });
                Config.socket.on('LEAVE_ROOM', (data) => {
                    console.log('====== LEAVE_ROOM => data: ', data);
                    that.setState({message: data.fullName+ ': LEAVE_ROOM'});
                });
                Config.socket.on('TYPING_MESSAGE', (data) => {
                    console.log('====== TYPING_MESSAGE => data: ', data);
                    that.setState({message: data.fullName + ': TYPING_MESSAGE..'});
                });
                Config.socket.on('RECEIVE_MESSAGE', (data) => {
                    console.log('====== RECEIVE_MESSAGE => data: ', data);
                    that.setState({message: data.fullName + ': ' + data.message});
                });

                Config.socket.on('disconnect', function(){
                    console.log('================= disconnected =================');

                    setTimeout(()=>{
                        Config.socket = null;
                    }, 1000);
                });

                that.setState({message: 'Connected the socket. Please join to any room !!!'});

                console.log('===== that.state.room: ', that.state.room);
                Config.socket.emit('put',
                    {
                        url: '/api/message/join',
                        data: {room: 'GENERAL'}
                    }, function (data) {
                        console.log("join room", data);
                    });
            } catch (error) {
                console.log("begin catch");
                console.log('error socket', error);
            }
        });
    }

    onLoadMessage() {
        let url = Config.host+'/message/load';
        let headers =  {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': 'content-type,token,secret',
            'token': Config.tokenLocal.token
        };
        let options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({"team": this.state.team})
        };

        const that = this;
        fetch(url, options)
            .then( resp => {
                let json = resp.json();

                if(resp.ok && json) {
                    return json
                }
            })
            .then(function(json) {
                console.log("=======================success", json);
                console.log("=======================json.data.room.id", json.data.room.id);
                that.setState({room: json.data.room.id});
            })
            .catch(e => {
                throw  e;
            })
    }

    onJoinRoom(room) {
        console.log('====== onJoinRoom ======');

        if(!Config.socket || Config.socket === null) {
            console.log("socket does not connected");
            return false;
        }

        const that = this;
        room = room || that.state.room;
        console.log('===== that.state.room: ', room);
        Config.socket.emit('put',
            {
                url: '/api/message/join',
                data: {room: room}
            }, function (data) {
                console.log("join room", data);
                that.setState({message: 'join room'});
            });
    }

    onLeaveRoom() {
        console.log('====== onLeaveRoom ======');

        if(!Config.socket || Config.socket === null) {
            console.log("socket does not connected");
            return false;
        }

        const that = this;
        Config.socket.emit('put',
            {
                url: '/api/message/leave',
                data: {room: that.state.room}
            }, function (data) {
                console.log("leave room", data);
                that.setState({message: 'leave room'});

                /* For mute option */
                // Config.socket.off('JOIN_ROOM');
                // Config.socket.off('LEAVE_ROOM');
                // Config.socket.off('TYPING_MESSAGE');
                // Config.socket.off('RECEIVE_MESSAGE');
            });
    }

    onSendMessage() {
        console.log('====== onSendMessage ======');

        if(!Config.socket || Config.socket === null) {
            console.log("socket does not connected");
            return false;
        }

        const that = this;
        Config.socket.emit('put',
            {
                url: '/api/message/send',
                data: {room: that.state.room, team: that.state.team, message: that.state.content}
            }, function (data) {
                console.log('send message', data);
                that.setState({message: 'send message: ' + that.state.content});
            });
    }

    onTypingMessage() {
        console.log('====== onTypingMessage ======');

        if(!Config.socket || Config.socket === null) {
            console.log("socket does not connected");
            return false;
        }

        const that = this;
        console.log('===== that.state.room: ', that.state.room);
        Config.socket.emit('put',
            {
                url: '/api/message/typing',
                data: {room: that.state.room}
            }, function (data) {
                console.log('typing message', data);
                that.setState({message: 'typing..'});
            });
    }

    onChangeMessage(e) {
        console.log('====== onChangeMessage ======', e.currentTarget.value);

        this.onTypingMessage();
        this.setState({content: e.currentTarget.value});
    }

    render(){

        return(
            <div>
                <div>{this.state.message}</div>
                <FormControl onChange={e => this.onChangeMessage(e)} />
                <Button onClick={()=>this.onJoinRoom()}>JOIN ROOM</Button>
                <Button onClick={()=>this.onJoinRoom('5bf2725f742be6150f12847d')}>JOIN ROOM 2</Button>
                <Button onClick={()=>this.onLeaveRoom()}>LEAVE ROOM</Button>
                <Button onClick={()=>this.onTypingMessage()}>TYPING</Button>
                <Button onClick={()=>this.onSendMessage()}>SEND</Button>

                <Button onClick={()=>this.requestPermission()}>Request Permission</Button>
            </div>
        )
    }
}


export default Messages;