/**
 * Created by Trang on 10/10/2017.
 */

import React, {Component} from 'react';
import DayPicker from 'react-day-picker';
import MaskedInput from 'react-text-mask';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import 'react-day-picker/lib/style.css';
import '../../libs/date-time-picker/date-time-picker.css';

import moment from 'moment';
moment.locale('en');

class DateTimePicker extends Component {
    constructor(props){
        super(props);
        this.state = {
            flagCalendar: false,
            value:''
        }

    }

    _onChange = (day) => {
        if (this.props.onChange && day.toString().length === 10){
            this.setState({
                value : moment(day,"DD/MM/YYYY").valueOf()
            });
            this.props.onChange(moment(day,"DD/MM/YYYY").valueOf());
        }
    };

    _onChangeDate = (day, {disabled }) => {

        if (disabled) {
            return;
        }
        this.setState({
            flagCalendar: false,
            value : day
        });
        this.refs.overlay.hide();
        if (this.props.onChange)
            this.props.onChange(moment(day).valueOf());
    };

    onHide = () => {
        this.setState({
            flagCalendar: false
        })
    };

    render(){

        // let value = this.props.flagValue ? this.props.value : (this.state.value ? this.state.value: this.props.defValue);
        // value = value > 0 ? moment(value).format('DD/MM/YYYY') : value ;

        return(
            <div className={'date-time-picker' + (this.props.bsClass ? ' '+ this.props.bsClass : '')} style={this.props.styleDef}>
                <MaskedInput
                    mask={[/[0-9]/, /\d/,'/',/\d/, /\d/,'/',/\d/, /\d/, /\d/,/\d/]}
                    onChange={(e)=>this._onChange(e.target.value)}
                    disabled={this.props.disabled}
                    value={this.props.flagValue ? moment(this.props.value).format('DD/MM/YYYY') : (this.state.value ? moment(this.state.value).format('DD/MM/YYYY') : moment(this.props.defValue).format('DD/MM/YYYY')) }
                    placeholder={this.props.placeholder ? this.props.placeholder : "dd/mm/yyyy"}
                    className="form-control"
                    guide={false}
                />
                <OverlayTrigger trigger="click" ref="overlay" rootClose placement={this.props.alignPopover ? this.props.alignPopover : "bottom"}
                                overlay={
                                    <Popover id="popover-trigger-click-root-close popover-date">
                                        <DayPicker onDayClick={(day, { disabled, selected }) => this._onChangeDate(day, { disabled, selected })}
                                                    month={this.props.fromMonth ? this.props.fromMonth :new Date(new Date().getFullYear(), new Date().getMonth())}
                                                    fromMonth={this.props.fromMonth ? this.props.fromMonth : null}
                                                    toMonth={this.props.toMonth ? this.props.toMonth : null}
                                                    disabledDays={this.props.disabledDays ? this.props.disabledDays : null}
                                      />
                                    </Popover>
                                }> 
                    <i className='fa fa-calendar' style={{cursor:'pointer'}}
                       onClick={() => {this.setState({flagCalendar:! this.state.flagCalendar})}}/>
                </OverlayTrigger>
            </div>
        )
    }
}


export default DateTimePicker;