import React,{Component} from 'react';
import {Image} from 'react-bootstrap';
import '../register/register.css'
import Header from "../header/header";
import {connect} from 'react-redux';
import * as userActions from "../../redux/user/user_actions";
import {bindActionCreators} from "redux";
import Config from "../../config";
import './email-welcome.css'


class OnBoardingEmailPage extends Component{

    constructor(props) {
        super(props);
        this.state = {
            infoActive: null,
        };
    }

    componentDidMount() {
        let code = window.location.search.split('code=');
        code = code && code.length > 1 ? code[code.length - 1] : null;
        this.props.userActions.verify({code: code}, (err, data) => {
            if (err) {
                Config.alertMess(err);
            }
            else if (data) {
                this.setState({
                    infoActive: data
                });
                setTimeout(()=>{
                    this.onNext();
                }, 2000)
            }

        })
    }

    onNext = () => {
        window.location.href = '/login';
    };

    render(){
        return(
            <div className="full_w full_h">
                <Header type={0} login={null}/>
                <div className="register row" style={{marginTop: 50}}>
                    <div className="col-sm-12" style={{padding:'0 60px'}}>

                        <p className="logoteam text-center hidden">
                            <Image style={{width: 150}} src={require('../../assets/images/bamboo.png')}/>
                        </p>

                        <div className="txt-condensed-extra-bold"
                             style={{marginBottom:60,marginTop:60}}>
                            Welcome to Teemie.
                        <br/>
                            Please login to start collaborating !
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//export default OnBoardingEmailPage;

export default connect(state => ({}),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
    })
)(OnBoardingEmailPage);