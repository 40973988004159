import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./event_actions";
import Api from '../../services/api';

export function* getListEvent(data) {
    try {
        yield delay(300);
        let event = yield Api.put('/event/search', data.params);
        if(event && event.data){
            yield put({type: types.EVENT_LIST + '_SUCCESS', payload: event.data});
            data.cb && data.cb(null, event.data);
        }
        else{
            yield put({type: types.EVENT_LIST + '_SUCCESS', payload: []});
            data.cb && data.cb(event, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetListEvent() {
    while (true){
        const watcher = yield takeLatest(types.EVENT_LIST,getListEvent);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* addEvent(data) {
    try {
        yield delay(300);
        let event = yield Api.put('/event/add', data.params);
        if(event && event.data){
            yield put({type: types.EVENT_ADD + '_SUCCESS', payload: event.data});
            data.cb && data.cb(null, event.data);
        }
        else{
            data.cb && data.cb(event, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchAddEvent() {
    while (true){
        const watcher = yield takeLatest(types.EVENT_ADD,addEvent);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* summaryEvent(data) {
    try {
        yield delay(300);
        let event = yield Api.put('/event/summary', data.params);
        if(event && event.data){
            yield put({type: types.SUMMARY_EVENT + '_SUCCESS', payload: event.data});
            data.cb && data.cb(null, event.data);
        }
        else{
            data.cb && data.cb(event, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchSummaryEvent() {
    while (true){
        const watcher = yield takeLatest(types.SUMMARY_EVENT,summaryEvent);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
