import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {mainTypes} from "./main_actions";
import Config from '../../config';
import Api from '../../services/api.js';

//##############################

export function* loading(data) {
    console.log('=========== loading');
    try {
        //ket noi server goi api de login
        yield delay(300);
        let time = Date.now();
        let tokenStore = yield localStorage.getItem('TOKEN');
        Config.tokenLocal = Config.isJSON(tokenStore) ? JSON.parse(tokenStore) : null;

        let settingLists = yield Api.put('/setting/list',{limit:10000, skip:0});
        if (settingLists && settingLists.data) {
            console.log("================================ settingLists:", settingLists);
            Config.setting = settingLists.data;
            localStorage.setItem('SETTING', JSON.stringify(settingLists.data));
            yield put({type: mainTypes.SETTING + '_SUCCESS', payload: settingLists.data});
            if(Config.getSetting("APP_VERSION") !== Config.version){
                alert('Please update version new!');
            }
        }
        else{
            // alert('Get setting error');
        }


        const token = Config.tokenLocal ? Config.tokenLocal.token : null
        if(!token){
            data.cb && data.cb(true, null);
            return;
        }
        else{
            let tokenResult = yield Api.put('/auth/check-token',{token ,deviceToken: Config.deviceToken});
            if(tokenResult && tokenResult.data){
                data.cb && data.cb(null, true);
            }
            else{
                yield localStorage.removeItem('LOGIN');
                yield localStorage.removeItem('TOKEN');
                Config.tokenLocal = null;
                data.cb && data.cb(true, null);
            }
        }

        if(!tokenStore || (tokenStore && Config.tokenLocal && !Config.tokenLocal.token)){
            console.log('=========== get token');

            Config.getToken = true;

            let tokenResult = yield Api.get('/auth/token');
            if(tokenResult && tokenResult.data){
                console.log("================================ tokenResult:", tokenResult);
                Config.getToken = false;
                const expire = tokenResult.data.expire - tokenResult.data.time;
                const localExpire = time + expire;
                tokenResult.data.localExpire = localExpire;
                Config.tokenLocal = tokenResult.data;
                localStorage.setItem('TOKEN', JSON.stringify(tokenResult.data));

                let settingLists = yield Api.put('/setting/list',{limit:10000, skip:0});
                if (settingLists && settingLists.data) {
                    console.log("================================ settingLists:", settingLists);
                    Config.setting = settingLists.data;
                    localStorage.setItem('SETTING', JSON.stringify(settingLists.data));
                    yield put({type: mainTypes.SETTING + '_SUCCESS', payload: settingLists.data});
                    if(Config.getSetting("APP_VERSION") !== Config.version){
                        alert('Please update version new!');
                    }
                    data.cb && data.cb(null, tokenResult.data);
                }
                else{
                    console.log("================================ errToken0:");
                    data.cb && data.cb(settingLists, null);
                }
            }
            else{
                console.log("================================ errToken1:");
                data.cb && data.cb(tokenResult, null);
            }

            Config.getToken = false;
        }
        // TODO: fix this
        //else if (tokenStore && Config.tokenLocal && Config.tokenLocal.token && Config.tokenLocal.token.expires <= (time + 7200000)) {
        else if (tokenStore && Config.tokenLocal && Config.tokenLocal.token) {
                // console.log("================================ updateToken:");
            Config.getToken = true;
            let updateToken = yield Api.put('/auth/refresh', {token: Config.tokenLocal.token});
            if(updateToken && updateToken.data){
                console.log("================================ updateToken:", updateToken);
                const expire = updateToken.data.expire - updateToken.data.time;
                const localExpire = time + expire;
                updateToken.data.local = localExpire;
                localStorage.setItem('TOKEN', JSON.stringify(updateToken.data));
                // console.log("================================ ");
                if(!tokenStore.local || time < tokenStore.local){
                    let settingLists = yield Api.put('/setting/list',{limit:10000, skip:0});
                    if (settingLists && settingLists.data) {
                        yield put({type: mainTypes.SETTING + '_SUCCESS', payload: settingLists.data});
                        // console.log("================================ settingLists:", settingLists);
                        Config.setting = settingLists.data;
                        localStorage.setItem('SETTING', JSON.stringify(settingLists));
                        if(Config.getSetting("APP_VERSION") !== Config.version){
                            alert('Please update version new!');
                        }
                        data.cb && data.cb(null, updateToken.data);
                    }
                    else{
                        data.cb && data.cb(settingLists,null)
                    }
                }
            }
            else{
                // console.log("================================ errToken:");
                data.cb && data.cb(updateToken, null);
            }

            Config.getToken = false;

        }
        else{
            console.log("================================ errToken2:");
            yield put({type: mainTypes.SETTING + '_SUCCESS', payload: JSON.parse(settingLists)});
            data.cb && data.cb(null, JSON.parse(tokenStore));
        }
    }
    catch (e) {
        console.log(e);
        data.cb && data.cb({code:1000,message:'loading is error'}, null);
    }
}

export function* watchLoading() {
    while (true){
        const watcher = yield takeLatest(mainTypes.LOADING,loading);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* uploadFile(data) {
    try{
        yield delay(300);
        let upload = yield Api.upload('/file/upload', data.params);
        if(upload && upload.data && upload.data[0] && data.cb){
            data.cb(null,upload.data[0])
        }
    }
    catch (e){
        data.cb && data.cb({code:1000 ,message:'Upload is error'}, null);
    }
}

export function* watchUploadFile() {
    while (true){
        const watcher = yield takeLatest(mainTypes.UPLOAD_FILE,uploadFile);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################
export function* checkLogin(data) {
    try{
        yield delay(300);

        yield localStorage.removeItem('LOGIN');
        data.cb && data.cb({code:1000 ,message:'Check login is error'}, null);
        yield put({type:mainTypes.CHECK_LOGIN+'_SUCCESS', payload: null });
    }
    catch (e){
        console.log(e);
        data.cb && data.cb({code:1000 ,message:'Check login is error'}, null);
    }
}

export function* watchCheckLogin() {
    while (true){
        const watcher = yield takeLatest(mainTypes.CHECK_LOGIN,checkLogin);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* checkOut(data) {
    try{
        yield delay(300);
        let checkOut = yield Api.put('/user/logout', {deviceToken: Config.deviceToken});
        if(checkOut && checkOut.data){
            yield localStorage.removeItem('LOGIN');
            yield localStorage.removeItem('TOKEN');
            Config.tokenLocal = null;
            data.cb && data.cb(null, checkOut.data);
            yield put({type: mainTypes.CHECK_OUT + '_SUCCESS', payload: null});
        }
        else{
            data.cb && data.cb(checkOut, null);
        }
        // yield localStorage.removeItem('LOGIN');
        // yield localStorage.removeItem('TOKEN');
        // Config.tokenLocal = null;
        // yield put({type:mainTypes.CHECK_OUT+'_SUCCESS', payload: null});
        // data.cb && data.cb();
    }
    catch (e){
        console.log(e);
        data.cb && data.cb({code:1000 ,message:'Check out is error'}, null);
    }
}

export function* watchCheckOut() {
    while (true){
        const watcher = yield takeLatest(mainTypes.CHECK_OUT,checkOut);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* checkOutNoUrl(data) {
    try{
        yield delay(300);
        yield localStorage.removeItem('LOGIN');
        yield localStorage.removeItem('TOKEN');
        Config.tokenLocal = null;
        data.cb && data.cb(null, null);
        yield put({type: mainTypes.CHECK_OUT + '_SUCCESS', payload: null});
    }
    catch (e){
        console.log(e);
        data.cb && data.cb({code:1000 ,message:'Check out is error'}, null);
    }
}

export function* watchCheckOutNoUrl() {
    while (true){
        const watcher = yield takeLatest(mainTypes.CHECK_OUT,checkOutNoUrl);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* getNumberBadge(data) {
    try{
        yield delay(300);
        let numberBadge = yield Api.put('/notification/badge');
        if(numberBadge && numberBadge.data){
            yield put({type:mainTypes.GET_BADGE_NUMBER+'_SUCCESS', payload: numberBadge.data.badge});
            data.cb && data.cb(null, numberBadge.data);
        }
        else{
            data.cb && data.cb(numberBadge, null);
        }

    }
    catch (e){
        console.log(e);
        data.cb && data.cb({code:1000 ,message:'Get badge number is error'}, null);
    }
}

export function* watchGetNumberBadge() {
    while (true){
        const watcher = yield takeLatest(mainTypes.GET_BADGE_NUMBER,getNumberBadge);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* updateNumberBadge(data) {
    try{
        yield delay(300);
        console.log("here i am updating");
        console.log(data);
        yield put({type:mainTypes.UPDATE_BADGE_NUMBER+'_SUCCESS', payload: data.params});
        data.cb && data.cb(null, data.params);

    }
    catch (e){
        console.log(e);
        data.cb && data.cb({code:1000 ,message:'Update badge number is error'}, null);
    }
}

export function* watchUpdateNumberBadge() {
    while (true){
        const watcher = yield takeLatest(mainTypes.UPDATE_BADGE_NUMBER,updateNumberBadge);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
