import React,{Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

import '../register/register.css'
import '../my-team-detail/my-team-detail.css'
import '../calendar/calendar.css'
import '../tasks-page/tasks-page.css'
import './my-account.css'
import './changeplan.js'
import CForm from "../libs/cform/cform";
import Config from "../../config";

import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as taskActions from "../../redux/task/task_actions";
import * as packageActions from "../../redux/package/package_actions";

import {Button, Image, Modal} from "react-bootstrap";
import ImageCrop from "../libs/drop-image1/drop-image";
import ReactLoading from "react-loading";
import ImageLazy from "../imageLazy/imageLazy";

import { CardElement,Elements,ElementsConsumer } from '@stripe/react-stripe-js';
import { stripePromise } from '../payment/stripe';
import '../payment/payment.scss';
import Header from '../header/header';

class MyAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadFiles:[],
            updatePlan:false,
            disableupdatePlan:false,
            updatePlanPayment: false,
            imgAva: null,
            listPackage:null,
            popupName:null,
            popupCompanyName:null,
            popupEmail:null,
            popupPassword:null,
            infoUser: null,
            cardToken: ''
        };
        this.dataForm1 = [
            {
                type: 'TEXT',
                key: "name",
                id: "name",
                label:'Name:',
                feedback: false,
                def_value: '',
                validator: 'isRequired',
            },
            {
                type: 'TEXT',
                key: "company",
                id: "company",
                label:'Company Name:',
                feedback: false,
                def_value: '',
                validator: 'isRequired',
            },
            {
                type: 'TEXT',
                key: "email",
                id: "email",
                label:"Email:",
                feedback: false,
                validator: 'isEmail',
            },
            {
                type: 'PASSWORD',
                key: 'password',
                id: 'password',
                feedback: false,
                passIcon: true,
                placeholder: 'Password',
                className: '',
                // passIcon:true,
                def_value: '',
                validator: 'isRequired'
            },
        ];
    }

    componentDidMount(){
        this.getPlan();
        this.setInfoUser();
        this.upgradeSubscription();
    };

    async upgradeSubscription(){
        if(window.location.search){
            let planInfo = []
            let dataPlan = this.state.listPackage
            let params = window.location.search.split('/')
            let isUpgrade = params[0].replace('?','')
            let plan = params[1]
            let team = params[2]
            let price = params[3]
            let companyId = params[4]
            console.log("params",params)
            if(plan === 'free_plan' && !price){
                price = "0"
            }
            if(dataPlan && dataPlan.length){
                dataPlan.map((plan)=>{
                    if(plan.type === plan){
                        planInfo.push(plan)
                    }
                })
            }
            if(isUpgrade === 'upgrade'){
                let newplan = {
                price:price,
                type:plan,
                packageUser:team
            }
            this.props.packageActions.upgradePackage(newplan,(err, data)=>{
                if(data){
                    let checkLogin = {...infoUser};
                    checkLogin.company = data.company;
                    checkLogin.plan = newPlan;
                    localStorage.setItem('LOGIN', JSON.stringify(checkLogin));
                    this.isSubmit=false;
                    window.location.reload();
                }
                else{
                    this.setState({errProfile: Config.alertMess(err)});
                }
            });
        }
    }
    }
    setInfoUser = () => {
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        console.log(checkLogin)
        let params = {
            id:checkLogin.company.id
        }
        this.props.companyActions.get_company_by_id(params,(err, data)=>{
            if(data){
                console.log("data=========>",data)
                checkLogin.company =  data
            }
            else{
                Config.popup.show('INFO',[Config.alertMess(err)]);
            }
        });
        console.log("checkLogin",checkLogin)
        this.setState({
            infoUser: checkLogin
        })
    };

    getPlan = ()=>{
        this.props.packageActions.getListPackage((err, data)=>{
            if(data){
                this.setState({
                    listPackage: data
                })
            }
            else{
                Config.popup.show('INFO',[Config.alertMess(err)]);
            }
        });
    };

    onUploadImage = (e) => {
        let reader = new FileReader();
        if(e.target.files.length===0) return null;
        let file = e.target.files[0];

        if(file.size > 1048576){
            alert('Maximum upload file size 1MB');
            return;
        }

        reader.onloadend = ()=> {
            this.setState({imgAva : reader.result});
        };

        reader.readAsDataURL(e.target.files[0]);
    };

    _uploadAva = (e) => {
        let base64Img = null;
        let reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onloadend = () => {
            base64Img = reader.result;

            if (this.vlType === 1) {
                this.saveProfile(null, null, null, null, base64Img, null, () => {
                    this.setState({imageProfile: {file: base64Img, src: base64Img}});
                });
            } else if (this.vlType === 2) {
                this.saveProfile(null, null, null, null, null, base64Img, () => {
                    this.setState({imageLogo: {file: base64Img, src: base64Img}});
                });
            }
            this.vlType = null;

            this.setState({imgCrop: null});
        }
    };

    saveProfile = (name, companyName, email, password, avatar, logo, cb) => {
        if(this.isSubmit) return;
        this.isSubmit=true;
        let isSubmit1=true;
        let isSubmit2=true;
        let {infoUser} = this.state;
        if(!infoUser || !infoUser.user || !infoUser.user.id) {
            this.isSubmit=false;
            return;
        }
        let params = {};
        if(name){
            params.fullName = name
            params.memberUpdate = true
        }
        if(email){
            params.email = email
        }
        if(password){
            params.passwordNew = password;
        }
        if(avatar){
            params.avatar = avatar;
        }
        
        if(Object.keys(params).length){
            params.id = infoUser.user.id;
            if(params.avatar){
                // params.memberUpdate = true
            }
            this.props.userActions.editUser(params, (err, data) => {
                if(err){
                    this.setState({errProfile: Config.alertMess(err)});
                }
                else{
                    let checkLogin = {...infoUser};
                    checkLogin.user = data[0];
                    localStorage.setItem('LOGIN', JSON.stringify(checkLogin));
                    this.setState({
                        infoUser: checkLogin,
                        errSave: 'Update success'
                    });
                    window.location.reload();
                }
                isSubmit1=false;
            });
        }
        else{
                isSubmit1=false;
        }
        let paramsCompany = {};
        if(companyName){
            paramsCompany.name = companyName;
        }
        if(logo){
            paramsCompany.logo = logo;
        }
        if(Object.keys(paramsCompany).length){
            paramsCompany.id = infoUser.company.id;
            this.props.companyActions.editCompany(paramsCompany, (err, data) => {
                if(err){
                    this.setState({errProfile: Config.alertMess(err)});
                    this.isSubmit=false;
                }
                else{
                    let checkLogin = {...infoUser};
                    checkLogin.company = data;
                    localStorage.setItem('LOGIN', JSON.stringify(checkLogin));
                    this.setState({
                        infoUser: checkLogin,
                        errSave: 'Update success'
                    });
                }
                isSubmit2=false;
            });
        }
        else{
            isSubmit2=false;
        }
        const timeOut = setInterval(()=>{
            if(!isSubmit1 && !isSubmit2){
                clearInterval(timeOut);
                this.isSubmit=false;
                cb && cb();
                this.offPopup();
            }
        },100);

    };

    _ConfirmRemove = (item) => {
        this.setState({ isCancelMembershipConfirmed: false });        
        this.inactive(item);
      };

    inactiveCompany=(item)=>{
        this.setState({ isCancelMembershipConfirmed: true });        
    };
    _handleClose = () => {
        this.setState({ isCancelMembershipConfirmed: false });
      };

    changePlan = (data) => {
        console.log(data);
        if(data=='free_plan'){
            this.toggledisableChangePlan();
        }        else        {            window.location.href = "/changeplan";
        }
    };

  changePayment = (data) => {
    console.log(data);
    if (data != "free_plan") {
      this.toggleChangePlanPayment();
    }
  };

  redirectSupport() {
    window.open(
      "https://teemieapp.com/contact/",
      "_blank" // <- This is what makes it open in a new window.
    );
  }

    inactive = (data) => {
        this.props.companyActions.inactive((err, data) => {
            if(data){
                this.props.mainActions.checkOutNoUrl((err, data)=>{
                    window.location.reload();
                });
            }
            else if(err){
                Config.popup.show('INFO', Config.alertMess(err))
            }
        });
    };

    toggleChangePlan = () =>{
        this.setState({
            updatePlan: !this.state.updatePlan
        })
    };

    toggledisableChangePlan = () =>{
        this.setState({
            disableupdatePlan: !this.state.disableupdatePlan
        })
    };
  toggleChangePlanPayment = () => {
    this.setState({
      updatePlanPayment: !this.state.updatePlanPayment,
    });
  };
  checkcardValid=(cardData)=>{
    const currentDate = new Date();
    
    let dateParts = cardData.cardExpiry.split("/");
    let dateString = dateParts[0]+"/01/"+dateParts[1]; // Reformat the date 
  const expiryDate = new Date(dateString);
  // Compare the expiry year and month with the current year and month
  if (
    expiryDate.getFullYear() > currentDate.getFullYear() ||
    (expiryDate.getFullYear() === currentDate.getFullYear() && expiryDate.getMonth() >= currentDate.getMonth())
  ) {
    return true; // Expiry date is valid
  } else {
    return false; // Expiry date is not valid
  }
  };
  updatePlanPayment=(e)=>{
    console.log("***********updatePlanPayment");
    console.log(e);

    if (e.isSubmit || e.state.isLoading) {
      return;
    }
    //let check = this.refs['CForm'].formValidate();
    //Removed the Card Form Check Local and moved the validation to Live
    let check = true;
    //let dt = this.refs['CForm'].formValue();

    e.setState({
      isLoading: true,
    });
    if (!check) {
      e.setState({
        errPayment:
          "Fill out all fields or input correct details to the fields and try again",
      });
      setTimeout(() => {
        e.setState({ errPayment: "", isLoading: false });
        e.isSubmit = false;
      }, 3000);
    } else {
      let dataPay = e._getDataPayment();
      dataPay.stripeToken=e.state.stripeToken;
      console.log("updatePayment-------------------", dataPay);
      
        this.props.companyActions.updatepayment(dataPay, (err, data) => {
          if (data) {
            localStorage.setItem("LOGIN", JSON.stringify(data));
            e.setState({
              isLoading: false,
            });
            e.setState({
              isSubmit: false,
            });
            e.setState({
              successPayment: "Payment Method updated Successfully",
            });

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            e.setState({
              isLoading: false,
            });
            e.setState({
              isSubmit: false,
            });
            e.setState({
              errPayment: Config.alertMess(err),
            });

            setTimeout(() => {
              e.setState({ errPayment: "", isLoading: false, isSubmit: false });
            }, 3000);
          }
        });
      
    }
  }
    upgrade = (newPlan) => {
        if(this.isSubmit) return;
        this.isSubmit=true;
        let {infoUser} = this.state;
        this.props.packageActions.upgradePackage({packageId: newPlan.id},(err, data)=>{
            if(data){
                let checkLogin = {...infoUser};
                checkLogin.company = data.company;
                checkLogin.plan = newPlan;
                localStorage.setItem('LOGIN', JSON.stringify(checkLogin));
                this.isSubmit=false;
                window.location.reload();
            }
            else{
                this.setState({errProfile: Config.alertMess(err)});
            }
        });
    };

    toggleName= () => {
        this.setState({
            popupName: !this.state.popupName
        })
    };

    toggleCompanyName= () => {
        this.setState({
            popupCompanyName: !this.state.popupCompanyName
        })
    };

    toggleEmail= () => {
        this.setState({
            popupEmail: !this.state.popupEmail
        })
    };

    togglePassword= () => {
        this.setState({
            popupPassword: !this.state.popupPassword
        })
    };

    offPopup = () => {
        this.setState({
            popupName: false,
            popupCompanyName: false,
            popupEmail: false,
            popupPassword: false
        })
    };

    handleImageError = () => {

    }

    _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };

    render() {
        let {infoUser} = this.state;
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        let userInfo = infoUser && infoUser.user ? infoUser.user : null;
        let companyInfo = infoUser && infoUser.company ? infoUser.company : null;
        let stripeInfo = checkLogin && checkLogin.cardDetails ? checkLogin.cardDetails : null; 
        let cardLastFour='';
        if(stripeInfo && stripeInfo.last4!=undefined){
            cardLastFour=stripeInfo.last4; 
        }

        let userRole = checkLogin && checkLogin.userRole ? checkLogin.userRole : null;
        let planInfo = []
        let dataPlan = this.state.listPackage
        if(dataPlan && dataPlan.length){
            dataPlan.map((plan)=>{
                if(plan.type === companyInfo?.subcriptionPlan){
                    planInfo.push(plan)
                }
            })
        }
        console.log('plan details',companyInfo);
        const imgSrcProfile = (this.state.imageProfile && this.state.imageProfile.src) 
                            ? this.state.imageProfile.src 
                            : (
                                userInfo && userInfo.avatar 
                                ? userInfo.avatar 
                                : Config.avaDef
                            );

        const imgSrcLogo = (this.state.imageLogo && this.state.imageLogo.src) 
                            ? this.state.imageLogo.src 
                            : (
                                companyInfo && companyInfo?.logo 
                                ? companyInfo.logo 
                                : Config.avaDef
                            );

                            let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
        return (
            <div className="my-team my-team-detail create-task my-account row col-sm-12 full_h_1 scroll">
                <Header type={1} login={login} onLogout={this._onLogout} isSearch={false}/>
                <div className="member-view display_col body_container">
                    <div className="title-large">My Account</div>
                    <div className="display_row col-sm-12 col-md-12 align-between flex_wrap account-details"
                         >
                        {/*<CForm data={this.dataForm1} ref="CForm"*/}
                               {/*styleForm="col-md-6 col-sm-6 form-create-task"*/}
                        {/*/>*/}
                      
                      <div className='row'>
                        <div className="col-md-7 col-sm-7 pl-0">
                                <div className="row1 relative">
                                    <div className="col-md-8 col-sm-8 user_details">
                                        <div className="form-group display_row">
                                            <span className="form-label">Name:</span>
                                            <span className="form-control"> {userInfo ? userInfo.fullName : ""}</span>
                                        </div>
                                        <div className="form-group display_row">
                                            <span className="form-label">Company Name:</span>
                                            <span className="form-control"> {companyInfo ? companyInfo?.name : ""}</span>
                                        </div>
                                        <div className="form-group display_row">
                                            <span className="form-label">Email:</span>
                                            <span className="form-control"> {userInfo ? userInfo.email : ""}</span>
                                        </div>
                                        <div className="form-group display_row">
                                            <span className="form-label">Password:</span>
                                            <span className="form-control" style={{lineHeight: '48px'}}>***********</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 editor">
                                        <div className="view-edit" >
                                            <span onClick={this.toggleName}>Edit Name</span>
                                            <span onClick={this.toggleCompanyName}>Edit Company</span>
                                            <span onClick={this.toggleEmail}>Edit Email</span>
                                            <span onClick={this.togglePassword}>Edit Password</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="display_row justify-evenly col-md-5 col-sm-5 profile">
                                <div className="col-md-6 col-sm-6 view-upload profile_photo">
                                    {
                                        imgSrcProfile && <ImageLazy
                                            src={`${imgSrcProfile}`}
                                    retry={4}
                                    defaultImage={Config.avaDef}
                                    reactLoadingStyle = {{position:'absolute',fill:'#31CCBC', width: 25, height: 25, left:'calc(50% - 15px)', top:'calc(50% - 30px)'}}
                                        className="avatar-image"/>}
                                    {this.state.imageProfile && !this.state.imageProfile.src &&
                                        <ReactLoading style={{position:'absolute',fill:'#31CCBC', width: 25, height: 25, left:'calc(50% - 15px)', top:'calc(50% - 30px)'}}
                                                    type={"spinningBubbles"}/>
                                    }

                                    <div className="btn-choose-file">
                                        Change profile photo
                                        <input type="file" accept="image/*"
                                            ref="vlPhoto"
                                            onClick={()=>{this.refs.vlPhoto.value=""}}
                                            onChange={(e)=>{this.vlType = 1; this.onUploadImage(e); return false;}}/>

                                        {/*{this.state.errProfile && (*/}
                                        {/*    <div className="register_error" style={{width:'100%', bottom:-40}}>*/}
                                        {/*        {this.state.errProfile}*/}
                                        {/*    </div>*/}
                                        {/*)}*/}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 view-upload Change_logo">
                                    {imgSrcLogo && <ImageLazy
                                        src={imgSrcLogo}
                                        defaultImage={Config.avaDef}
                                        retry={4}
                                        reactLoadingStyle = {{position:'absolute',fill:'#31CCBC', width: 25, height: 25, left:'calc(50% - 15px)', top:'calc(50% - 30px)'}}
                                        className="avatar-image"/>}
                                    {this.state.imageLogo && !this.state.imageLogo.src &&
                                        <ReactLoading style={{position:'absolute',fill:'#31CCBC', width: 25, height: 25, left:'calc(50% - 15px)', top:'calc(50% - 30px)'}}
                                                        type={"spinningBubbles"}/>
                                    }
                                    <div className="btn-choose-file">
                                        Change logo
                                        <input type="file" accept="image/*"
                                            ref="vlLogo"
                                            onClick={()=>{this.refs.vlLogo.value=""}}
                                            onChange={(e)=>{this.vlType = 2; this.onUploadImage(e); return false;}}/>

                                        {this.state.errLogo && (
                                            <div className="register_error" style={{width:'100%', bottom:-40}}>
                                                {this.state.errLogo}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                      </div>
                      
                        {/*<button type="button"*/}
                                {/*onClick={this.saveProfile}*/}
                                {/*className="btn-linear shadow btn-save-account">*/}
                            {/*{this.state.errSave &&*/}
                            {/*<div className="register_error"*/}
                                 {/*style={{*/}
                                     {/*width: '100vh',*/}
                                     {/*bottom: 25,*/}
                                     {/*height: 30,*/}
                                     {/*right: 0,*/}
                                     {/*justifyContent: 'flex-end',*/}
                                     {/*overflow: 'inherit',*/}
                                     {/*whiteSpace:'pre'*/}
                                 {/*}}>*/}
                                {/*{this.state.errSave}*/}
                            {/*</div>*/}
                            {/*}*/}
                            {/*SAVE*/}
                        {/*</button>*/}
                    </div>
                    {userRole.slug=='owner' && 
                    <div className='subscription'>
                    <div className="title-large">My Subscription</div>
                    <div className="display_col col-md-6 col-sm-6 align-between flex_wrap">
                        {/* <div className="txt-subscription">
                        
                            Plan detail: <span className='sub-value'>{companyInfo ? companyInfo?.companySize : "0"} team members</span>
                            <span  className="change-method"
                            onClick={(e) => this.changePlan(companyInfo.subcriptionPlan)}
                            >Change Plan</span>
                        </div> */}
                        <div className="txt-subscription">
                         Payment Method <span className='sub-value'>**{cardLastFour}</span>
                         <span className="change-method"
                         onClick={(e) => this.changePayment(companyInfo.subcriptionPlan)}
                          >Change Payment Method </span>
                    </div>
                        <div className="txt-subscription">
                            Cancel Membership
                            <span className="change-method" onClick={this.inactiveCompany}>Cancel</span>
                        </div>
                    </div>
                    </div>}
                    {this.state.imgAva && <ImageCrop data={this.state.imgAva}
                                                     size="squares"
                                                     show={this.state.imgAva !== null}
                                                     titleCrop="SAVE"
                                                     onCrop={(e)=>{this._uploadAva(e);}}
                                                     onHide={() => {this.setState({imgAva: null})}}/>}
                    <ChangePlan show={this.state.updatePlan}
                                dataPlan={this.state.listPackage}
                                companyInfo={companyInfo}
                                upgrade={this.upgrade}
                                handleClose={this.toggleChangePlan}/>

<DisableChangePlan show={this.state.disableupdatePlan}
                                handleClose={this.toggledisableChangePlan}
                                redirectSupport={this.redirectSupport}/>
          <ChangePlanPayment
            show={this.state.updatePlanPayment}
            handleClose={this.toggleChangePlanPayment}
            userinfo={this.state.infoUser}
            changePlanPayment={this.updatePlanPayment}
          />
                    <ChangeName show={this.state.popupName}
                                upgrade={this.saveProfile}
                                errProfile={this.state.errProfile}
                                handleClose={this.toggleName}
                    />
                    <ChangeCompanyName show={this.state.popupCompanyName}
                                upgrade={this.saveProfile}
                                       errProfile={this.state.errProfile}
                                handleClose={this.toggleCompanyName}
                    />
                    <ChangeEmail show={this.state.popupEmail}
                                upgrade={this.saveProfile}
                                 errProfile={this.state.errProfile}
                                handleClose={this.toggleEmail}
                    />
                    <ChangePassword show={this.state.popupPassword}
                                upgrade={this.saveProfile}
                                    errProfile={this.state.errProfile}
                                handleClose={this.togglePassword}
                    />
                </div>
                <div>
                <Modal
                show={this.state.isCancelMembershipConfirmed}
                onHide={this.closeModal}                
                className="remove-user"            
                >
            
            <Modal.Body>

            <Image
              className="logo"
              src={require("../../assets/images/icon-member.png")}
            />
              <div className="head">Confirm</div>
              <div className="content" style={{ margin: "40px 57px 30px " }}>Are you sure you wish to to cancel your account? This can not be undone and will result in all access being removed for all team members immediately? You will no longer have access to any team data such as rosters, timesheets etc.</div>
              <div className="display_row modal-footers" style={{ padding: "10px 0" , display: "flex" , justifyContent: "space-evenly" }}>
                
                <Button
                  className="login_btn_login btn-linear-orange shadow_popup"
                  style={{ maxHeight: 43, margin: 0 , borderRadius:"25px"}}
                  onClick={this._handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  className="login_btn_login btn-linear shadow_popup"
                  style={{ maxHeight: 43, margin: "0" , borderRadius:"25px" }}
                  onClick={() => this._ConfirmRemove(this.state.isCancelMembershipConfirmed)}
                >
                  CONFIRM
                </Button>
              </div>
            </Modal.Body>
          </Modal>
                </div>
            </div>
        )
    }

}

class ChangeEmail extends Component {

    constructor(props){
        super(props);
        this.state={
            errSave: null
        }
    }

    _handleClose = () => {
        if(this.props.handleClose) this.props.handleClose();
        this.setState({errSave:null})
    };

    _upgrade = () => {
        let check1 = this.refs['CForm'].formValidate();
        let dt1 = this.refs['CForm'].formValue();

        if(!check1) {
            this.setState({errSave: '* Please correct the email and try again.'});
            return;
        }
        if(this.props.upgrade){
            this.setState({errSave: null});
            this.props.upgrade(null, null, dt1.email.value, null, null);
        }
    };

    render(){
        let {show,errProfile} = this.props;
        let {errSave} = this.state;
        return(
            <Modal show={show} onHide={this._handleClose}
                   id="popup-change-plan"
                   dialogClassName="popup-change-profile"
                   style={{width: 965, height: 470, maxWidth:'100%', margin:'auto'}}>
              
                <Modal.Body id='changing_logo'>
                    <Image
                        className="logo"
                        src={require("../../assets/images/icon-member.png")}
                        style={{width: 70, height: 64 , marginTop:"30px"}}                       
                    /> 
                    <div className="title">Changing My Email</div>
                    <div className="title1 edit-email">Please enter new email below</div>
                    <CForm data={[{
                        type: 'TEXT',
                        key: "email",
                        id: "email",
                        placeholder:"Email",
                        feedback: false,
                        validator: 'isEmail',
                    }]} ref="CForm"
                        styleForm=""
                    />
                    {errProfile || errSave &&
                    <div className="register_error" style={{width:'100%', bottom: 50}}>
                        {errProfile || errSave}
                    </div>
                    }
                    <button type="button"
                            onClick={this._upgrade}
                            className="btn-linear shadow_popup btn btn-default btn-confirm"
                            style={{borderRadius:"25px" , margin:"0px 0px 50px 0px" , letterSpacing:"0.5px"}}
                            >
                        CONFIRM
                    </button>
                </Modal.Body>
            </Modal>
        )
    }
}

class ChangeName extends Component {

    constructor(props){
        super(props);
        this.state={
            errSave: null
        }
    }

    _handleClose = () => {
        if(this.props.handleClose) this.props.handleClose();
        this.setState({errSave:null})
    };

    _upgrade = () => {
        let check1 = this.refs['CForm'].formValidate();
        let dt1 = this.refs['CForm'].formValue();
        
        if(!check1) {
            this.setState({errSave: '* Please correct the name and try again.'});
            return;
        }
        if(this.props.upgrade){
            this.setState({errSave: null});
            this.props.upgrade(dt1.name.value, null, null, null, null);
        }
    };

    render(){
        let {show,errProfile} = this.props;
        let {errSave} = this.state;
        return(
            <Modal show={show} onHide={this._handleClose}
                   id="popup-change-plan"
                   dialogClassName="popup-change-profile"
                   style={{width: 965, height: 470 ,maxWidth:'100%', margin:'auto'}}>
                
                <Modal.Body  id='changing_logo'>
                    <Image
                        className="logo"
                       
                        src={require("../../assets/images/icon-member.png")}
                        style={{width: 70, height: 64 , marginTop:"30px"}}                       
                    /> 
                    <div className="title">Changing My Name</div>
                    <div className="title1 edit-name">Please enter new name below</div>
                    <CForm data={[{
                        type: 'TEXT',
                        key: "name",
                        id: "name",
                        placeholder:"Name",
                        feedback: false,
                        validator: 'isRequired',
                    }]} ref="CForm"
                        styleForm=""
                    />
                    {errProfile || errSave &&
                    <div className="register_error" style={{width:'100%', bottom: 50}}>
                        {errProfile || errSave}
                    </div>
                    }
                    <button type="button"
                        onClick={this._upgrade}
                            className="btn-linear shadow_popup btn btn-default btn-confirm" 
                            style={{borderRadius:"25px" , margin:"0px 0px 50px 0px" , letterSpacing:"0.5px"}}
                            >
                        CONFIRM
                    </button>
                </Modal.Body>
            </Modal>
        )
    }
}

class ChangeCompanyName extends Component {

    constructor(props){
        super(props);
        this.state={
            errSave: null
        }
    }

    _handleClose = () => {
        if(this.props.handleClose) this.props.handleClose();
        this.setState({errSave:null})
    };

    _upgrade = () => {
        let check1 = this.refs['CForm'].formValidate();
        let dt1 = this.refs['CForm'].formValue();

        if(!check1) {
            this.setState({errSave: '* Please correct the company name and try again.'});
            return;
        }
        if(this.props.upgrade){
            this.setState({errSave: null});
            this.props.upgrade(null, dt1.companyName.value, null, null, null);
        }
    };

    render(){
        let {show,errProfile} = this.props;
        let {errSave} = this.state;

        return(
            <Modal show={show} onHide={this._handleClose}
                   id="popup-change-plan"
                   dialogClassName="popup-change-profile"
                   style={{width: 965, height: 470 ,maxWidth:'100%', margin:'auto'}}>
                
                <Modal.Body id='changing_logo'>
                    <Image
                        className="logo"
                        src={require("../../assets/images/icon-member.png")}
                        style={{width: 70, height: 64 , marginTop:"30px"}}                       
                    />                    
                    <div className="title">Changing Company Name</div>
                    <div className="title1 edit-company-name">Please enter new company name below</div>
                    <CForm data={[{
                        type: 'TEXT',
                        key: "companyName",
                        id: "companyName",
                        placeholder:"Company Name",
                        feedback: false,
                        validator: 'isRequired',
                    }]} ref="CForm"
                        styleForm=""
                    />
                    {errProfile || errSave &&
                    <div className="register_error" style={{width:'100%', bottom: 50}}>
                        {errProfile || errSave}
                    </div>
                    }
                    <button type="button"
                        onClick={this._upgrade}
                            className="btn-linear shadow_popup btn btn-default btn-confirm"
                            style={{borderRadius:"25px" , margin:"0px 0px 50px 0px" , letterSpacing:"0.5px"}}
                            >
                        CONFIRM
                    </button>
                </Modal.Body>
            </Modal>
        )
    }
}

class ChangePassword extends Component {

    constructor(props){
        super(props);
        this.state={
            errSave: null
        }
    }

    _handleClose = () => {
        if(this.props.handleClose) this.props.handleClose();
         this.setState({errSave:null})
    };

    _upgrade = () => {
        let check1 = this.refs['CForm'].formValidate();
        let dt1 = this.refs['CForm'].formValue();

        if(!check1 && dt1.editPassword.value && dt1.editPasswordConfirm.value && dt1.editPassword.value == dt1.editPasswordConfirm.value) {
            this.setState({errSave: Config.alertMess({code:'PASSWORDFORMAT'})});
            return;
        }
        if(dt1.editPassword.value == undefined &&  dt1.editPasswordConfirm.value == undefined) {
            this.setState({errSave: Config.alertMess({code:'PASSWORDREQUIRED'})});
            return;
        }
        if(dt1.editPassword.value !== dt1.editPasswordConfirm.value){
            this.setState({errSave: Config.alertMess({code:'PASSWORDCONFIRM'})});
            return;
        }
        if(this.props.upgrade){
            this.setState({errSave: null});
            this.props.upgrade(null, null, null, dt1.editPassword.value, null);
        }
    };

    render(){
        let {show,errProfile} = this.props;
        let {errSave} = this.state;

        return(
            <Modal show={show} onHide={this._handleClose}
                   id="popup-change-plan"
                   dialogClassName="popup-change-profile"
                   style={{width: 965, height: 520 ,maxWidth:'100%', margin:'auto'}}>
              
                <Modal.Body id='changing_logo'>
                    <Image
                        className="logo"
                        src={require("../../assets/images/icon-member.png")}
                        style={{width: 70, height: 64 , marginTop:"30px"}}                       
                    /> 
                    <div className="title">Changing My Password</div>
                    <div className="title1 edit-password">Please enter new password below</div>
                    <CForm data={[
                        {
                            type: 'PASSWORD',
                            key: "editPassword",
                            id: "editPassword",
                            placeholder:"New Password",
                            feedback: false,
                            passIcon: true,
                            validator: 'isPassword',
                        },{
                            type: 'PASSWORD',
                            key: "editPasswordConfirm",
                            id: "editPasswordConfirm",
                            placeholder:"Confirm Password",
                            feedback: false,
                            passIcon: true,
                            validator: 'isPassword',
                        }
                    ]} ref="CForm"
                        styleForm=""
                    />
                    {/*{errProfile || errSave &&*/}
                    <div className="register_error" style={{width:'100%', bottom: 50}}>
                       {errProfile || errSave}
                    </div>
                    {/*}*/}
                    <button type="button"
                            onClick={this._upgrade}
                            className="btn-linear shadow_popup btn btn-default btn-confirm"
                            style={{borderRadius:"25px" , margin:"0px 0px 50px 0px" , letterSpacing:"0.5px" , height:"70px"}}
                            >
                        CONFIRM
                    </button>
                </Modal.Body>
            </Modal>
        )
    }
}
class DisableChangePlan extends Component {
    _handleClose = () => {
        if(this.props.handleClose) this.props.handleClose();
    };
    _redirectSupport = () => {
        this.props.redirectSupport()
    }

    onChange = (data,downgrade) => {
      if(!data || downgrade) return;
      if(this.props.upgrade) this.props.upgrade(data);
    };

    render(){
        let {dataPlan,show,companyInfo,errProfile} = this.props;
        console.log("companyInfo",companyInfo)
       
        return(
            <Modal show={show ? true : false}
                   onHide={this._handleClose}
                   id="popup-change-plan"
                   className={"root-popup-change-plan"}
                   style={{width: 966, maxWidth:'100%', margin:'auto'}}>
                <Image src={require('../../assets/images/btn-close-popup.png')}
                       className="icon-close"
                       onClick={this._handleClose}/>
                <Modal.Body id='changing_logo'>
                <Image className="logo" src={require('../../assets/images/register/icon-member.png')} />
                    <div className="title">CHANGING MY PLAN</div>
                    <div className="title1">To expand your team size for free plan users, please contact us by clicking the contact us option below.</div>
                    <div className="button_display_row" style={{ padding: '10px 0' }}>
                        <Button className="login_btn_login btn-linear-orange shadow"
                            style={{ maxHeight: 50, margin: '0 10px' , borderRadius:"25px"}}
                            onClick={this._redirectSupport}
                            >
                            CONFIRM
                        </Button>
                        <Button className="login_btn_login btn-linear shadow"
                            style={{ maxHeight: 50, margin: 0 , borderRadius:"25px"}}
                            onClick={this._handleClose}>
                            CANCEL
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
    );
  }
}
class ChangePlanPayment extends Component {
  _handleClose = () => {
    this.setState({errPayment: ""});
    this.setState({isSubmit: false});
    this.setState({isLoading: false});
    if (this.props.handleClose) this.props.handleClose();
  };

  constructor(props) {
    super(props);
    this.state = {
        cardinfo: null,
        isLoading:false,
        message: '',
        errPayment:'',
        successPayment:'',
        stripeToken:'',
        tokenstatus:false
    };
    this.isSubmit = false;
}
  

onConfirm = () => {
        this.props.changePlanPayment(this);
    
};

_getDataPayment = () => {
    let dataPay = localStorage.getItem('LOGIN');
    dataPay = dataPay && Config.isJSON(dataPay) ? JSON.parse(dataPay) : null;
    if(!dataPay ||(dataPay && (!dataPay.company || !dataPay.user))){
        window.location.href = "/home";
    }
    else{
        console.log('Render Details',dataPay);
        return dataPay;
    }
};

cardPaymentProcess = (status,info) => {
    console.log(status);
    if(!status){
        this.setState({message: info});
        setTimeout(()=>{
            this.setState({message: '',isLoading:false});
            this.isSubmit = false;
        },3000);
    }else{
        this.setState({stripeToken:info});
        this.setState({tokenstatus:true});
        this.onConfirm();
    }
};


  render() {
    let { dataPlan, show, userinfo, errProfile } = this.props;

    return (
      <Modal
        show={show ? true : false}
        onHide={this._handleClose}
        id="popup-change-plan"
        className={"root-popup-change-plan"}
        style={{ width: 966, maxWidth: "100%", margin: "auto" }}
      >
       
        <Modal.Body>
          <Image
            className="logo-pay"
            src={require("../../assets/images/icon-member.png")}
          />
          <div className="title1">
            Change your payment method easily by just
            <br /> inputting your details below
          </div>
          <div className="register-payment ">
            <div className="image-container" style={{ marginBottom: 10 }}>
              <Image
                src={require("../../assets/images/register/icon-visa.png")}
                className="image-card small"
              />
              <Image
                src={require("../../assets/images/register/icon-master-card.png")}
                className="image-card small"
              />
              <Image
                src={require("../../assets/images/register/icon-maestro.png")}
                className="image-card small"
              />
            </div>
            {/* Removed the Card Form to integrate the stripe card element */}

<div className="changePayment">
        <Elements stripe={stripePromise}>
        <ElementsConsumer>
        {({ elements, stripe }) => (
          <CardPaymentFormClass elements={elements} stripe={stripe} cardPaymentStatus={this.cardPaymentProcess}
          _handleClose={this._handleClose}/>
        )}
      </ElementsConsumer>
      </Elements>
      </div>
          </div>
          <div className="button_display_row payment-btn" style={{ padding: "10px 0" }}>


          {this.state.errPayment  &&
                    <div className="register_error" style={{width:'100%', bottom: 50}}>
                        {this.state.errPayment}
                    </div>
                    }
            
            {this.state.successPayment  &&
                    <div className="label_success" style={{width:'100%', bottom: 50}}>
                        {this.state.successPayment}
                    </div>
                    }
                    {this.state.tokenstatus &&
                    <div>
                    <Button
              className="login_btn_login btn-linear-orange shadow_popup"
              onClick={this._handleClose}
              style={{borderRadius:"25px"}}
            >
              CANCEL
            </Button>
            <Button className="login_btn_login btn-linear shadow_popup"
            style={{borderRadius:"25px"}}
            >
                        CONFIRM
                        {this.state.isLoading &&
                        <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                            <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                        </div>
                        }
                    </Button>
                    </div>
  }

          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

class ChangePlan extends Component {
    _handleClose = () => {
        if(this.props.handleClose) this.props.handleClose();
    };

    onChange = (data,downgrade) => {
      if(!data || downgrade) return;
      if(this.props.upgrade) this.props.upgrade(data);
    };

    render(){
        let {dataPlan,show,companyInfo,errProfile} = this.props;
        console.log("companyInfo",companyInfo)
        let isCurrent = dataPlan ? dataPlan.filter(i=>i.type === companyInfo?.subcriptionPlan) : [];
        let isFreePlan = companyInfo?.subcriptionPlan === 'free_plan'?true:false
        return(
            <Modal show={show ? true : false}
                   onHide={this._handleClose}
                   id="popup-change-plan"
                   className={"root-popup-change-plan"}
                   style={{width: 966, maxWidth:'100%', margin:'auto'}}>
                <Image src={require('../../assets/images/btn-close-popup.png')}
                       className="icon-close"
                       onClick={this._handleClose}/>
                <Modal.Body>
                    <div className="title">CHANGING MY PLAN</div>
                    <div className="title1">To choose a smaller option please ensure that you delete the correct members of staff as this cannot be undone.</div>
                    <div className="col-sm-12 display_row">
                        {dataPlan && dataPlan.map((key, idx)=>{
                            let current = (isCurrent && isCurrent.length > 0);
                            let downgrade = (companyInfo?.companySize === 'UNLIMITED' || (isCurrent && isCurrent.length > 0 && isCurrent[0].packageUser >= key.packageUser));
                            return(
                                <div className="shadow plan-view"key={idx+'item-team'}>
                                    <div className="txt-condensed-extra-bold" style={{marginTop:-8, fontSize: 25}}>
                                        {key.title}
                                    </div>
                                    <Image src={key.image} className="icon-member"/>
                                    <div className="txt-price">
                                        €{key.type === 'free_plan'?"0":companyInfo?.purchasePrice}pm
                                    </div>
                                    <p className="txt-person">
                                        Up to {companyInfo?.companySize} per team
                                    </p>
                                    {current &&
                                        <div className="login_btn_login current">Current Plan</div>
                                    }
                                    {!current &&
                                        <Button className="login_btn_login btn-linear shadow"
                                                style={{opacity: downgrade ? 0.2 : 1}}
                                                onClick={() => this.onChange(key,downgrade)}>
                                            UPGRADE PLAN
                                        </Button>
                                    }
                                </div>
                            )
                        })}
                        {errProfile &&
                            <div className="register_error" style={{width:'100%', bottom: -80}}>
                                {errProfile}
                            </div>
                        }
                        {!dataPlan&& <div className="display_row align-center justify-center">
                            <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30}/>
                        </div>}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}


class CardPaymentFormClass extends Component {

    constructor(props){
        super(props);
        this.state={
            message: '',
            cardSubmitted: false,
            isLoading:false,
            cardholderName: '', // Initialize cardholderName in the component's state
        };
        this.isSubmit = false;
    }
    _handleClose=async()=>{
        this.props._handleClose();
    };
    
    handleCardToken = async (e) => {

        if(this.isSubmit || this.state.isLoading){
            return;
        }
        let stripe=this.props.stripe;
        let elements=this.props.elements;

      console.log('handle submit');
      e.preventDefault();
      console.log(this.props.stripe);
      console.log(this.props.elements);

      console.log("stripesss===========", stripe);
      if (!stripe) {
        return;
      }
      this.setState({
        isLoading:true
    });
    
      this.isSubmit = true;
      console.log('check');
      console.log(elements.getElement(CardElement));
      const result = await stripe.createToken(elements.getElement(CardElement), {
        name: this.state.cardholderName,
      });
  
      if (result.error) {
        console.error(result.error.message);
        this.setState({isLoading: false});
                    this.isSubmit = false;
        this.props.cardPaymentStatus(false,result.error.message);
      } else {
        this.setState({isLoading: false});
        this.isSubmit = false;
        // Handle successful token creation, e.g., send to your server for payment processing
        console.log(result.token);
        this.setState({cardSubmitted:true});
        this.props.cardPaymentStatus(true,result.token);
      } 
    };
    handleNameChange = (event) => {
        this.setState({ cardholderName: event.target.value });
      };
  
    render() {
      return (
       <div className='change-payment-method'>
          <div className='card-element-container'>
            <div className='form-group'>
            <input
            type="text"
            id="cardholder-name"
            className='form-control'
            placeholder='Name On Card'
            value={this.state.cardholderName}
            onChange={this.handleNameChange}
          />
          </div>
            <CardElement
              id="card-element"
              className='card-element'
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    color: '#32325d',
            lineHeight: '24px',
            fontSize: '16px',
                  },
                },
              }}
            />
            </div>
            {!this.state.cardSubmitted &&
<div className='button_display_row payment-btn'>
<Button
              className="login_btn_login btn-linear-orange shadow_popup"
              onClick={this._handleClose}
              style={{borderRadius:"25px"}}
            >
              CANCEL
            </Button>
          
          <Button className="login_btn_login btn-linear shadow_popup"   style={{borderRadius:"25px"}} onClick={(e) => this.handleCardToken(e)}>
                        CONFIRM
                        {this.state.isLoading &&
                        <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                            <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                        </div>
                        }
                    </Button>
                    </div>
    }
        </div>
      );
    }
  }

export default connect(state => ({
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList,
        teamId: state.team.teamId
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch),
        packageActions: bindActionCreators(packageActions, dispatch),
    })
)( MyAccount);
