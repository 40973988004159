import {types} from "./package_actions";

const initialState = {
    listPackage: null,
};

//Nhan action type tu Put va luu data vao store
export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.LIST_PACKAGE + '_SUCCESS':
            return {
                ...state,
                listPackage: action.payload
            };

        default:
            return state;
    }
}