import React, { PureComponent } from 'react';
import classNames from 'classnames';
import ReactLoading from "react-loading";

const IMAGE_FADE_IN_CLASS = `lazy-image--fade-in`;

export default class LazyImage extends PureComponent {
    state = {
        imageLoadFinishedClass: '',
        countRetry: 0,
        isLoading: true,
        src: null,
    };

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.src !== this.props.src) {
            this.setState({
                imageLoadFinishedClass: '',
                countRetry: 0,
                isLoading: true,
                src: null,
            })
        }
        return false;
    }

    _handleLoaded = () => {
        this.setState({ imageLoadFinishedClass: IMAGE_FADE_IN_CLASS, countRetry: 0, isLoading: false });
    };

    _handleError = e => {
        const retry = this.props.retry || 0;
        let count = this.state.countRetry;
        
        if ( count >= retry ) {
            this.setState({ countRetry: count + 1, src: this.props.defaultImage });
        } else {
            setTimeout(() => this.setState({ countRetry: count + 1, src: `${this.props.src}?${Date.now()}` }), 2000);
        }
    };

    render() {
        const {
            hrefclassName,
            className,
            style,
            src,
            defaultImage,
            alt,
            onClick,
            reactLoadingStyle
        } = this.props;

        const containerClass = classNames('lazy-image__container', {
            [hrefclassName]: !!hrefclassName,
            'lazy-image__container--loading': !this.state.imageLoadFinishedClass
        });

        const lazyClass = classNames('lazy-image', {
            [className]: !!className,
            [this.state.imageLoadFinishedClass]: true
        });

        let imgSrc = this.state.src ? this.state.src : src;
        
        return (
            <div className={containerClass}>
                <img
                    className={lazyClass}
                    style={style}
                    src={imgSrc || defaultImage}
                    alt={alt || null}
                    onLoad={this._handleLoaded}
                    onError={this._handleError}
                    onClick={onClick}
                />
                {this.state.isLoading && reactLoadingStyle &&
                    <ReactLoading style={reactLoadingStyle}
                                type={"spinningBubbles"}/>
                }
            </div>
        );
    }
}