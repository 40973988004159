// action types
export const types = {
    REGISTER: 'REGISTER',
    REGISTERLINK: 'REGISTERLINK',
    REGISTERPAYMENT: 'REGISTERPAYMENT',
    EDIT_COMPANY: 'EDIT_COMPANY',
    INACTIVE_COMPANY: 'INACTIVE_COMPANY',
    LOGIN_COMPANY: 'LOGIN_COMPANY',
    FORGOT_PASS: 'FORGOT_PASS',
    CHANGE_PASS: 'CHANGE_PASS',
    GET_COMPANY_BY_ID:'GET_COMPANY_BY_ID',
    CHANGE_PAYMENT:'CHANGE_PAYMENT'
};

export function register(params,cb) {
    return{
        type: types.REGISTER,
        params,
        cb
    }
}
export function registerLink(params,cb) {
    return{
        type: types.REGISTERLINK,
        params,
        cb
    }
}
export function get_company_by_id(params,cb) {
    return{
        type: types.GET_COMPANY_BY_ID,
        params,
        cb
    }
}

export function registerPayment(params,cb) {
    return{
        type: types.REGISTERPAYMENT,
        params,
        cb
    }
}

// export function checkOut(cb) {
//     return{
//         type: types.CHECK_OUT,
//         cb
//     }
// }

export function editCompany(params,cb) {
    return{
        type: types.EDIT_COMPANY,
        params,
        cb
    }
}

export function login(params,cb) {
    return{
        type: types.LOGIN_COMPANY,
        params,
        cb
    }
}

export function forgotPass(params,cb) {
    return{
        type: types.FORGOT_PASS,
        params,
        cb
    }
}

export function changePass(params,cb) {
    return{
        type: types.CHANGE_PASS,
        params,
        cb
    }
}

export function inactive(cb) {
    return{
        type: types.INACTIVE_COMPANY,
        cb
    }
}

export function updatepayment(params,cb) {
    return{
        type: types.CHANGE_PAYMENT,
        params,
        cb
    }
}
