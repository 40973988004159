import {combineReducers} from 'redux';
import Main from '../redux/main/main_reducers';
import User from '../redux/user/user_reducers';
import Company from '../redux/company/company_reducers';
import Event from '../redux/event/event_reducers';
import Team from '../redux/team/team_reducers';
import Member from '../redux/member/member_reducers';
import Shift from '../redux/shift/shift_reducers';
import Roster from '../redux/roster/roster_reducers';
import Task from '../redux/task/task_reducers';
import Message from '../redux/message/message_reducers';
import Package from '../redux/package/package_reducers';
import Welcome from '../redux/welcome/welcome_reducers';

export default combineReducers({
    main: Main,
    user: User,
    company: Company,
    team: Team,
    member: Member,
    event: Event,
    roster: Roster,
    shift: Shift,
    task: Task,
    message: Message,
    package: Package,
    welcome: Welcome
});