import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./team_actions";
import Api from '../../services/api';
// import error from '../../../components/CError.js';

export function* joinTeam(data) {
    try {
        yield put({type: types.TEAM_PENDING});
        //yield delay(300);
        let register = yield Api.put('/user/join', data.params);
        if (register && register.data) {
            data.cb && data.cb(null, register.data);
            return;
        }
        else {
            if (data.cb) data.cb(register, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchJoinTeam() {
    while (true) {
        const watcher = yield takeLatest(types.JOIN_TEAM, joinTeam);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################
export function* getTeamList(data) {
    try {
        yield put({type: types.TEAM_PENDING});
        //yield delay(300);
        let team = yield Api.get('/team/me');
        if(team && team.data){
            yield put({type: types.TEAM_LIST + '_SUCCESS', payload: team.data});
            data.cb && data.cb(null, team.data);
        }
        else{
            yield put({type: types.TEAM_LIST + '_SUCCESS', payload: []});
            data.cb && data.cb(team, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetListTeam() {
    while (true){
        const watcher = yield takeLatest(types.TEAM_LIST,getTeamList);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
        // yield cancel(pending)
    }
}
export function* createTeam(data) {
    try {
        //yield delay(300);
        let team = yield Api.put('/team/add', data.params);
        if(team && team.data){
            yield put({type: types.CREATE_TEAM + '_SUCCESS', payload: team.data});
            data.cb && data.cb(null, team.data);
            return;
        }
        else{
            if(data.cb) data.cb(team, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchCreateTeam() {
    while (true){
        const watcher = yield takeLatest(types.CREATE_TEAM,createTeam);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
export function* getMemberTeamList(data) {
    try {
        yield put({type: types.TEAM_PENDING});
        //yield delay(300);
        let team = yield Api.put('/team/members', data.params);
        if(team && team.data){
            yield put({type: types.TEAM_MEMBER_LIST + '_SUCCESS', payload: team.data});
            data.cb && data.cb(null, team.data);
            return;
        }
        else{
            yield put({type: types.TEAM_MEMBER_LIST + '_SUCCESS', payload: []});
            data.cb && data.cb(team, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetMemberTeamList() {
    while (true){
        const watcher = yield takeLatest(types.TEAM_MEMBER_LIST,getMemberTeamList);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* searchMemberTeamList(data) {
    try {
        yield put({type: types.TEAM_PENDING});
        //yield delay(300);
        let team = yield Api.put('/member/search', data.params);
        if(team && team.data){
            yield put({type: types.TEAM_MEMBER_SEARCH + '_SUCCESS', payload: team.data});
            data.cb && data.cb(null, team.data);
            return;
        }
        else{
            yield put({type: types.TEAM_MEMBER_SEARCH + '_SUCCESS', payload: []});
            data.cb && data.cb(team, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchSearchMemberTeamList() {
    while (true){
        const watcher = yield takeLatest(types.TEAM_MEMBER_SEARCH,searchMemberTeamList);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* inviteMember(data) {
    try {
        // yield put({type: types.TEAM_PENDING});
        //yield delay(300);
        let invite = yield Api.put('/member/invite', data.params);
        if(invite && invite.data){
            data.cb && data.cb(null, invite.data);
            return;
        }
        else{
            data.cb && data.cb(invite, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchInviteMember() {
    while (true){
        const watcher = yield takeLatest(types.INVITE_MEMBER,inviteMember);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* removeMember(data) {
    try {
        yield put({type: types.TEAM_PENDING});
        //yield delay(300);
        let remove = yield Api.put('/member/del', data.params);
        if(remove && remove.data){
            data.cb && data.cb(null, remove.data);
            return;
        }
        else{
            data.cb && data.cb(remove, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchRemoveMember() {
    while (true){
        const watcher = yield takeLatest(types.REMOVE_MEMBER,removeMember);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* removeTeam(data) {
    try {
        yield put({type: types.TEAM_PENDING});
        //yield delay(300);
        let remove = yield Api.put('/team/del', data.params);
        if(remove && remove.data){
            data.cb && data.cb(null, remove.data);
            return;
        }
        else{
            data.cb && data.cb(remove, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchRemoveTeam() {
    while (true){
        const watcher = yield takeLatest(types.REMOVE_TEAM,removeTeam);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* editTeam(data) {
    try {
        // //yield delay(300);
        yield put({type: types.TEAM_PENDING});
        let edit = yield Api.put('/team/edit', data.params);
        if(edit && edit.data){
            data.cb && data.cb(null, edit.data);
            return;
        }
        else{
            data.cb && data.cb(edit, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchEditTeam() {
    while (true){
        const watcher = yield takeLatest(types.EDIT_TEAM,editTeam);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* saveTeam(data) {
    try {
        yield put({type: types.TEAM_PENDING});
        //yield delay(300);
        yield put({type: types.SAVE_TEAM + '_SUCCESS', payload: data.params});
        data.cb && data.cb();
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchSaveTeam() {
    while (true){
        const watcher = yield takeLatest(types.SAVE_TEAM,saveTeam);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* toggleClock(data) {
    try {
        // yield put({type: types.TEAM_PENDING});
        //yield delay(300);
        let toggle = yield Api.put('/team/toggle-clock-in', data.params);
        if(toggle && toggle.data){
            data.cb && data.cb(null, toggle.data);
            return;
        }
        else{
            data.cb && data.cb(toggle, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchToggleClock() {
    while (true){
        const watcher = yield takeLatest(types.TOGGLE_CLOCK,toggleClock);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
export function* getTeam(data) {
    try {
        // yield put({type: types.TEAM_PENDING});
        //yield delay(300);
        let team = yield Api.put('/team/get', data.params);
        if(team && team.data){
            data.cb && data.cb(null, team.data);
            return;
        }
        else{
            data.cb && data.cb(team, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetTeam() {
    while (true){
        const watcher = yield takeLatest(types.GET_TEAM,getTeam);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
export function* editClockOptions(data) {
    try {
        // //yield delay(300);
        let team = yield Api.put('/team/clock-options', data.params);
        if(team && team.data){
            data.cb && data.cb(null, team.data);
            return;
        }
        else{
            data.cb && data.cb(team, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchEditClockOptions() {
    while (true){
        const watcher = yield takeLatest(types.EDIT_CLOCK_OPTIONS,editClockOptions);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
export function* getClockOptions(data) {
    try {
        // //yield delay(300);
        let team = yield Api.get(`/team/clock-options?id=${data.params}`);
        if(team && team.data){
            data.cb && data.cb(null, team.data);
            return;
        }
        else{
            data.cb && data.cb(team, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetClockOptions() {
    while (true){
        const watcher = yield takeLatest(types.GET_CLOCK_OPTIONS, getClockOptions);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################