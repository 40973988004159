/**
 * @copyright 2017 @ Tech Seed Labs
 * @author quang.lht
 * @create 2019/06/17 17:15
 * @update 2019/06/17 17:15
 * @file src/components/forget-password/forgetPassword.js
 */

import React, { Component } from 'react';
import { browserHistory } from "react-router";
import { Button } from 'react-bootstrap';
import * as userActions from '../../redux/user/user_actions';
import CForm from '../libs/cform/cform.js';
import './forget-password.css';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class ForgetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: ''
        }

        this.dataForm = [
            {
                type: 'TEXT',
                key: 'email', // key if type = TEXT
                id: 'email',
                feedback: false,
                placeholder: 'Email',
                className: '',
                def_value: '',
                validator: 'isRequired|isEmail'
            }
        ];
    }

    _sendResetMail = () => {
        let cForm = this.refs['CForm'].formValidate();
        let dt = this.refs['CForm'].formValue();

        if (cForm) {
            let params = {
                email: dt.email.value
            };
            let that = this;
            this.props.userActions.forgotPass(params, (err, data) => {
                if (data && data[0]) {
                    browserHistory.push("/reset-password");
                } else {
                    this.setState({ errorMessage: err.message });
                    this._timer = setTimeout(() => {
                        this.setState({
                            errorMessage: ''
                        });
                    }, 5000);
                    return null;
                }
            });
        } else {
            this.setState({ errorMessage: '* Email is not input or wrong format!' });
            this._timer = setTimeout(() => {
                this.setState({
                    errorMessage: ''
                });
            }, 5000);
            return null;
        }
    };

    render() {
        return (
            <div className="full_w full_h" style={{height:'100vh'}}>
                <div className="login">
                    <p>
                        Forgot your password? No worries, just enter your email below and a special code is on the way to fix this!
                    </p>
                    <CForm ref='CForm' className="textName" data={this.dataForm} />
                    {this.state.errorMessage !== '' && (
                        <div className="error">
                            {this.state.errorMessage}
                        </div>
                    )}

                    <Button className="login_btn_login btn-linear shadow" onClick={this._sendResetMail}>
                        Send reset email
                    </Button>
                </div>
            </div>
        );
    }

}


export default connect(state => ({
}),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch)
    })
)(ForgetPassword);