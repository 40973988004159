import { types } from "./user_actions";

const initialState = {
    login: null,
    userId: null
};

//Nhan action type tu Put va luu data vao store
export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.LOGIN_USER:
            return {
                ...state,
                login: action.payload
            };
        case types.CHECK_OUT + '_SUCCESS':
            return {
                ...state,
                login: null
            };
        case types.FORGOT_PASS_SUCCESS:
            return {
                ...state,
                userId: action.value
            };

        default:
            return state;
    }
}