/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/components/libs/cform/cform.js
 */

import React, { Component } from 'react';
import { FormControl, FormGroup, DropdownButton, MenuItem, Image } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import './cform.css';

import 'react-day-picker/lib/style.css';
import DateTimePicker from '../date-time-picker/date-time-picker';
import TimeField from 'react-simple-timefield';
import Datetime from 'react-datetime'
import 'rc-time-picker/assets/index.css';
import './date-time.css';

import moment from 'moment';

import validator from 'validator';
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

var validatorExt = {


    isName(value) {
        if (value.length === 0) return false;
        let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,20}$/

        // var regex = /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/g;
        return !regex.test(value);
    },

    isPassword(value) {
        if (value.length <= 1) return false;
        let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,20}$/
        console.log("regex.test(value)", regex.test(value))
        return regex.test(value);
    },

    isPasswordOrNull(value) {
        if (!value || value.length === 0) return true;
        let regex = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/im;
        return regex.test(value);
    },

    isRequired(value) {
        return (value.length !== 0);
    },

    isNull(value) {
        return true;
    },

    isNumberCard(value) {
        return (value.length === 19);
    },

    isExpire(value) {
        return (value.length === 5);
    },

    isCvv(value) {
        return (value.length === 3 || value.length === 4);
    },

    isEmailAndMobile(value) {
        if (value.length === 0) return false;
        var args = [];
        args.push(value);
        var _validatorEmail = validator["isEmail"];
        var _isValidEmail = _validatorEmail.apply(null, args);

        args.push('vi-VN');
        var _validatorMobile = validator["isMobilePhone"];
        var _isValidMobile = _validatorMobile.apply(null, args);
        return _isValidEmail || _isValidMobile;
    },

    isEqual(value, comparison) {
        return (value === comparison);
    },

    isPercent(value) {
        let args = [];
        args.push(value);
        args.push({ min: 0, max: 100 });

        let isInt = validator["isInt"];

        return isInt.apply(null, args);
    }
};

class CForm extends Component {

    constructor(props) {
        super(props);

        let { data } = this.props;
        let state = {
            showPass: false,
            isTaskView: props.isTaskView ? props.isTaskView : false,
            timeStart: '',
            timeEnd: '',
            note: ''
        };

        data.forEach((i) => {
            state[i.id] = {
                validate: null,
                isNull: (i.validator === 'isNull'),
                value: (i.def_value !== '') ? i.def_value : ""
            };

            if (i.type === 'MEDIA') {
                state[i.id].value = i.data;
            }
        });

        this.state = state;
        state['timeError'] = false
        state['value'] = moment(new Date()).format('HH:mm')
        if (state['timeStart'] && state['timeStart'].value && state['timeEnd'] && state['timeEnd'].value) {
            state['timeStart'].value = moment(new Date()).format("HH:mm")
            state['timeEnd'].value = moment(new Date()).add(2, 'hour').format("HH:mm")
        }
        console.log(state);
    }

    _validate(str, rules) {
        if (rules === '')
            return null;

        var rule = rules.split('|');
        var args = [];
        args.push(str);
        for (var i in rule) {
            var _r = rule[i];

            if (_r === 'isMobilePhone') {
                args.push('vi-VN');
            }

            var _checkEqual = _r.split('=');
            if (_checkEqual.length > 1) {
                var _name = _checkEqual[1];
                args.push(this.state[_name].value);
                _r = _checkEqual[0];
            }

            var _validator = validatorExt[_r] || validator[_r];
            var _isValid = _validator.apply(null, args);
            if (!_isValid)
                return 'error';
        }
        return 'success';
    }

    _onChangeText(text, key) {
        let state = {};
        if (key.id === 'name' && text.length > 30) {
            return false
        }
        let validate = this._validate(text.trim(), key.validator);
        state[key.id] = {
            validate: validate,
            value: text.trim()
        };
        this.setState(state);
    }

    _onValueChange(id, key) {
        let state = {};
        state[key.id] = {
            validate: 'success',
            value: id
        };
        this.setState(state);
    }

    _onDateChange(date, key) {
        let state = {};
        state[key.id] = {
            validate: (date !== "") ? 'success' : 'error',
            value: date
        };
        this.setState(state);
    }

    _onSave(date, key) {
        let state = {};
        state[key.id] = {
            validate: (date !== "") ? 'success' : 'error',
            value: date
        };
        if ((moment(date) < moment(new Date()))) {
            this.setState({ timeError: true })
            this.props.checkTimeError(this.state.timeError)
        }
        else {
            this.setState({ timeError: false })
            this.props.checkTimeError(this.state.timeError)
            this.setState(state);
        }
    }

    _onTimeChange(date, key) {
        let state = {};
        state[key.id] = {
            validate: (date !== "") ? 'success' : 'error',
            value: date.target.value
        };
        this.setState({ value: date.target.value })
        this.setState(state);
    }

    _onCloseMedia(index, key) {
        let data = this.state[key.id].value;
        data.splice(index, 1);
        let state = {};
        state[key.id] = {
            validate: 'success',
            value: data
        };
        this.setState(state);
    }

    _onUpload(path, key) {
        let data = this.state[key.id].value;
        data.push(path);
        let state = {};
        state[key.id] = {
            validate: 'success',
            value: data
        };
        this.setState(state);
    }

    formValidate() {
        for (let i in this.state) {
            let state = this.state[i];
            let validate = state && state.validate;
            if (validate === 'error' || (validate === null && !state.value && !state.isNull)) {
                state.validate = 'error';
                this.setState(state);
                return false;
            }
        }
        return true;
    }

    formValue() {
        return this.state;
    }

    render() {
        let { data, styleForm, children, isTimeError, isTaskView } = this.props;
        let backgroundStyle = isTaskView ? { padding: 3, "background": "#F4F6F6" } : { padding: 3 };
        if (styleForm === "form-create-shift") {
            backgroundStyle.width = 190
        }
        return (
            <form className={styleForm}>
                {data.map((key, idx) => {

                    let feedback = ("undefined" === typeof (key.feedback)) ? true : key.feedback;
                    let label = key.label || false;
                    let styleLabel = key.styleLabel || "form-label";
                    let ignoreFormGroup = ("undefined" === typeof (key.ignoreFormGroup)) ? false : key.ignoreFormGroup;
                    const defaultValue = key.def_value !== undefined ? key.def_value : '';

                    return (
                        <FormGroup key={idx}
                            className={key.styleRow}
                            validationState={this.state[key.id].validate}
                            style={ignoreFormGroup ? { display: "inline-block", width: "42px", margin: "0 5px" } : {}}>
                            <div className="display_row full_w">
                                {label && (
                                    <div className={styleLabel}>
                                        {label}
                                    </div>
                                )}
                                <div className="display_row form-input align-center" style={backgroundStyle}>
                                    {key.type === 'NUMBER' && (
                                        <TextBoxForm
                                            data={key}
                                            type={"number"}
                                            onChangeText={(text) => this._onChangeText(text, key)} />
                                    )}
                                    {key.type === 'MASK' && (
                                        <MaskForm
                                            data={key}
                                            onChangeText={(text) => this._onChangeText(text, key)} />
                                    )}

                                    {key.type === 'TEXT' && (
                                        <TextBoxForm
                                            data={key}
                                            type={"text"}
                                            value={defaultValue}                                
                                            onChangeText={(text) => this._onChangeText(text, key)} />
                                    )}

                                    {key.type === 'PASSWORD' && (
                                        <TextBoxForm
                                            data={key}
                                            type={this.state.showPass ? "text" : 'password'}
                                            onChangeText={(text) => this._onChangeText(text, key)} />
                                    )}

                                    {key.passIcon && (
                                        <Image
                                            src={require('../../../assets/images/icon-eye.png')}
                                            className="icon-eye"
                                            style={{ opacity: this.state.showPass ? 0.6 : 0.3, cursor: 'pointer' }}
                                            onClick={() => { this.setState({ showPass: !this.state.showPass }) }} />
                                    )}

                                    {key.type === 'TEXT_AREA' && (
                                        <TextAreaForm
                                            data={key}
                                            onChangeText={(text) => this._onChangeText(text, key)} />
                                    )}

                                    {key.type === 'DROP_DOWN' && (
                                        <DropDownForm
                                            data={key}
                                            onValueChange={(id) => this._onValueChange(id, key)} />
                                    )}

                                    {key.type === 'DATE' && (
                                        <DateForm
                                            data={key}
                                            onDateChange={(dt) => this._onDateChange(dt, key)} />
                                    )}

                                    {key.type === 'DATETIME' && (
                                        <>
                                            <DateTimeForm
                                                data={key}
                                                onDateChange={(dt) => this._onSave(dt, key)} />
                                            {this.state.timeError &&
                                                <p className='dateError'>Please select valid time.</p>
                                            }
                                        </>
                                    )}

                                    {key.type === 'TIME' && key.id === 'timeStart' && (
                                        //    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Stack spacing={3}>
                                            {/* <TimePicker
                                        /> */}
                                            <TextField
                                                id="time"
                                                type="time"
                                                defaultValue={this.state.timeStart && this.state.timeStart.value != null ? this.state.timeStart.value : moment(new Date()).format("hh:mm")}
                                                onChange={(dt) => this._onTimeChange(dt, key)}
                                                // defaultValue="07:30"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    step: 300, // 5 min
                                                    className: key.class
                                                }}
                                                sx={{ width: 109 }}
                                            />
                                        </Stack>
                                        //  </LocalizationProvider>
                                    )}
                                    {key.type === 'TIME' && key.id === 'timeEnd' && (
                                        <Stack spacing={3}>
                                            {/* <TimePicker
                                        /> */}
                                            <TextField
                                                id="time"
                                                type="time"
                                                defaultValue={this.state.timeEnd && this.state.timeEnd.value != null ? this.state.timeEnd.value : moment(new Date()).add(2, 'hour')}
                                                onChange={(dt) => this._onTimeChange(dt, key)}
                                                // defaultValue="07:30"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    step: 300, // 5 min
                                                    className: key.class
                                                }}
                                                sx={{ width: 109 }}
                                            />
                                        </Stack>
                                    )}
                                    {key.type === 'TIME' && key.id != 'timeEnd' && key.id != 'timeStart' && (
                                        <Stack spacing={3}>
                                            {/* <TimePicker
                                    /> */}
                                            <TextField
                                                id="time"
                                                type="time"
                                                defaultValue={this.state.value && this.state.value != null ? this.state.value : moment(new Date()).format("hh:mm")}
                                                onChange={(dt) => this._onTimeChange(dt, key)}
                                                // defaultValue="07:30"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    step: 300, // 5 min
                                                }}
                                                sx={{ width: 150 }}
                                            />
                                        </Stack>
                                    )}

                                    {key.type === 'MEDIA' && (
                                        <MediaForm
                                            onClose={(idx) => this._onCloseMedia(idx, key)}
                                            onUpload={(path) => this._onUpload(path, key)}
                                            data={key} />
                                    )}

                                    {feedback && (
                                        <FormControl.Feedback />
                                    )}
                                </div>
                            </div>
                        </FormGroup>
                    )
                })}
            </form>
        )
    }
}

class TextAreaForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.data.def_value || '',
        }
    }

    _onChangeText(e) {
        this.setState({
            value: e.target.value
        });
        if (this.props.onChangeText) {
            this.props.onChangeText(e.target.value);
        }
    }
    render() {
        let { data } = this.props;
        let height = data.height || 100;
        return (
            <FormControl
                type={data.key}
                className={data.className}
                componentClass="textarea"
                value={this.state.value}
                placeholder={data.placeholder}
                style={{ height: height + 'px' }}
                maxLength={data.maxLength}
                onChange={(e) => this._onChangeText(e)}
            />
        )
    }
}

class TextBoxForm extends Component {

    constructor(props) {
        super(props);
        // this.state = {
        //     value: this.props.data.def_value
        // }
        this.input = {
            value: this.props.data.def_value || ''
        };
    }

    _onChangeText(e) {
        // this.setState({value:e.target.value});
        if (this.props.onChangeText) {
            // this.props.onChangeText(e.target.value);
            this.props.onChangeText(this.input.value);
        }
    }

    render() {
        let { data, type } = this.props;

        type = type || "text";

        const value = data.disabled ? data.def_value : (this.input ? this.input.value : '');

        return (
            // <FormControl
            //     type={type}
            //     className={data.className}
            //     value={this.state.value}
            //     maxLength={data.maxLength}
            //     // defaultValue={null}
            //     placeholder={data.placeholder}
            //     disabled={data.disabled}
            //     onChange={(e)=>this._onChangeText(e)}
            // >
            // </FormControl>
            <input
                ref={el => this.input = el}
                type={type}
                className={`form-control${data.className ? ` ${data.className}` : ''} `}
                value={value}
                maxLength={data.maxLength}
                // defaultValue={null}
                placeholder={data.placeholder}
                disabled={data.disabled}
                onChange={(e) => this._onChangeText(e)}
                style={{ "box-shadow": "none", "border-bottom": "none" }}
            />
        )
    }
}
class MaskForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.data.def_value
        }
    }

    _onChangeText(e, that) {
        let value = e.target.value;
        if (that.props.onChangeText) {
            that.props.onChangeText(value);
        }
        that.setState({ value: value });
    }


    render() {
        let { data } = this.props;
        let that = this;

        return (
            <InputMask className="form-control"
                value={this.state.value ? this.state.value : ""}
                // defaultValue={data.def_value}
                maskChar={null}
                mask={data.mask}
                placeholder={data.placeholder}
                onChange={(e) => this._onChangeText(e, that)} />
        )
    }
}

class DropDownForm extends Component {

    constructor(props) {
        super(props);
        let { data, def_value } = this.props.data;
        let name = '';
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === def_value) {
                name = data[i].name;
                break;
            }
        }
        this.state = {
            value: name
        };
    }

    _onValueChange(dt) {
        this.setState({ value: dt.name });
        if (this.props.onValueChange) {
            this.props.onValueChange(dt.id)
        }
    }

    render() {
        let { data } = this.props;
        let title = (this.state.value === '') ? data.placeholder : this.state.value;
        return (
            <DropdownButton onSelect={(dt) => this._onValueChange(dt)}
                title={title}
                className={"form-control " + data.className}
                id={data.id}>
                {data.data.map((v, i) => {
                    let disable = data.dataDisabled.filter(i => i.id === v.id);
                    return (
                        <MenuItem key={i} eventKey={v} disabled={disable.length > 0}>
                            {v.name}
                        </MenuItem>
                    )
                })}
            </DropdownButton>
        )
    }
}

class DateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.data.def_value,
            type: 'text'
        }
    }

    _onDateChange(e) {
        if (this.props.onDateChange) {
            this.props.onDateChange(e)
        }
    }

    render() {
        let { data } = this.props;

        return (
            <DateTimePicker styleDef={{ width: ' 100% ' }}
                ref={'datetime'}
                alignPopover={data.alignPopover ? data.alignPopover : 'left'}
                placeholder={data.placeholder}
                onChange={(e) => this._onDateChange(e)}
                // readOnly={true}
                disabledDays={[
                    {
                        from: new Date(moment().startOf('month').toString()),
                        before: new Date(moment())
                    },
                ]}
                className={data.bsClass}
                defValue={data.def_value ? new Date(data.def_value) : new Date()} />
        )
    }
}
class TimeForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isTimeErrorState: false
        }
    }

    onChange(value) {
        if (value != '00:00') {
            this.setState({
                isTimeErrorState: this.props.isTimeError
            })
        } else {
            this.setState({
                isTimeErrorState: !this.props.isTimeError
            })
        }
        const newTime = value.replace(/-/g, ':');
        const time = newTime.substr(0, 5);

        if (this.props.changeTime) {
            this.props.changeTime(time);
        }
    }

    render() {
        const format = 'HH:mm';
        let { data, isTimeError } = this.props;
        let { isTimeErrorState } = this.state;
        // console.log(this.props.isTimeError);
        return (
            <TimeField
                // className={data.class}
                className={data.class + ' ' + (isTimeErrorState ? 'none-has-error' : '') + (isTimeError ? ' has-error' : '')}
                value={data.def_value ? data.def_value : '00:00'}
                onChange={(e) => this.onChange(e)}
                colon={":"}
                input={<input type="text" />}
                showSeconds={false}
            />
        )
    }
}
class DateTimeForm extends Component {

    _onSave = (value) => {
        if (this.props && this.props.onDateChange)
            this.props.onDateChange(moment(value).valueOf() ? moment(value).valueOf() : "");
    };

    render() {
        let { data } = this.props;
        console.log("type ---> time")
        const yesterday = moment().subtract(1, 'day');
        const disablePastDt = current => {
            return current.isAfter(yesterday);
        };
        return (
            <Datetime
                onChange={this._onSave}
                ref={"Datetime"}
                isValidDate={disablePastDt}
                timeFormat={"HH:mm"}
                defaultValue={moment()}
            />
            //<DateTimePicker1
            //    alignPopover={data.alignPopover ? data.alignPopover : 'left'}
            //    onSave={this._onSave}
            ///>
        )
    }
}

class MediaForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            src: null
        }
    }

    _onClose(index) {
        if (this.props.onClose) {
            this.props.onClose(index);
        }
    }

    _fileUpload(e) {
        let file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = () => {
            this.setState({ src: reader.result });
        };
        reader.readAsDataURL(file);
        this.setState({ loading: true });
        let { data } = this.props;
        if (data.funcUpload) {
            data.funcUpload(file, (data) => {
                this.setState({ loading: false, src: null });
                if (this.props.onUpload) {
                    this.props.onUpload(data.path);
                }
            });
        }
        else {
            alert('Not funcUpload');
        }
    }

    render() {
        let { data } = this.props;
        return (
            <div>
                <div className="form-row">
                    {data.data.map((key, idx) => {
                        return (
                            <div key={idx} className="image-form">
                                <a href={(data.host) ? data.host + '/' + key : key}
                                    target="_blank">
                                    {data.key === 'IMAGE' && (
                                        <img alt=""
                                            src={(data.host) ? data.host + '/' + key : key}
                                            className={"media-image " + data.className} />
                                    )}
                                    {data.key === 'VIDEO' && (
                                        <video src={(data.host) ? data.host + '/' + key : key}
                                            className={"media-video " + data.className} />
                                    )}
                                </a>
                                <div className="close-media" style={{ cursor: 'pointer' }} onClick={() => this._onClose(idx)}>
                                    <i className="fa fa-times-circle-o" />
                                </div>
                            </div>
                        )

                    })}
                </div>
                {!(data.key === 'VIDEO' && data.data.length > 0) && (
                    <div className="media_upload">
                        <span className="media_upload_txt">
                            {data.key === 'VIDEO' ? 'Thêm video' : 'Thêm ảnh'}
                        </span>
                        {!this.state.loading && (
                            <input type="file" className="media_upload_input"
                                accept={(data.key === 'IMAGE') ? "image/jpeg, image/png" : "video/mp4"}
                                onChange={(e) => this._fileUpload(e)} />
                        )}
                    </div>
                )}

                {this.state.loading && (
                    <div className="media-loader">
                        {data.key === 'IMAGE' && (
                            <img alt="" src={this.state.src} className="img-loader" />
                        )}
                        {data.key === 'VIDEO' && (
                            <video src={this.state.src}
                                className="video-loader" />
                        )}
                        <div className="spin-loader"></div>
                    </div>
                )}
            </div>

        )
    }
}


export default CForm;