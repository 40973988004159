import {delay} from 'redux-saga';
import {takeLatest, take, cancel, put} from 'redux-saga/effects';
import {types} from "./welcome_actions";
import Api from '../../services/api';
// import error from '../../../components/CError.js';

//##############################
export function* welcomeList(data) {
    try {
        yield delay(300);
        // Config.getToken = true;
        let searchData = yield Api.get('/wellcomes');
        if (searchData && searchData.data) {
            yield put({type: types.SEARCH_WELCOME + '_SUCCESS', payload: searchData.data});
            data.cb && data.cb(null, searchData.data);
            // return;
        }
        else {
            if (data.cb) data.cb(searchData, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchWelcomeList() {
    while (true) {
        const watcher = yield takeLatest(types.SEARCH_WELCOME, welcomeList);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################


//##############################
export function* addWelcome(data) {
    try {
        //yield delay(300);
        // Config.getToken = true;
        let addData = yield Api.post('/wellcome', data.params);
        if (addData && addData.data) {
            data.cb && data.cb(null, addData.data);
            // return;
        }
        else {
            if (data.cb) data.cb(addData, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchAddWelcome() {
    while (true) {
        const watcher = yield takeLatest(types.ADD_WELCOME, addWelcome);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################


//##############################
export function* editWelcome(data) {
    try {
        yield delay(300);
        // Config.getToken = true;
        let editData = yield Api.put('/wellcome/edit', data.params);
        if (editData && editData.data) {
            data.cb && data.cb(null, editData.data);
            // return;
        }
        else {
            if (data.cb) data.cb(editData, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchEditWelcome() {
    while (true) {
        const watcher = yield takeLatest(types.EDIT_WELCOME, editWelcome);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################


//##############################
export function* removeWelcome(data) {
    try {
        yield delay(300);
        // Config.getToken = true;
        let removeData = yield Api.delete('/wellcome', data.params);
        if (removeData && removeData.data) {
            data.cb && data.cb(null, removeData.data);
            // return;
        }
        else {
            if (data.cb) data.cb(removeData, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchRemoveWelcome() {
    while (true) {
        const watcher = yield takeLatest(types.REMOVE_WELCOME, removeWelcome);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################