import {types} from "./member_actions";

const initialState = {
    memberInfo: null,
    team_pending:false
};

//Nhan action type tu Put va luu data vao store
export default function (state = initialState, action = {}) {
    switch (action.type){
        
        case types.TEAM_PENDING : 
         return {
            ...state,
            team_pending:true
         }

        case types.MEMBER_INFO+'_SUCCESS':
            return{
                ...state,
                memberInfo: action.payload,
                team_pending:false
            };

        default:
            return state;
    }
}