import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./member_actions";
import Api from '../../services/api';
// import error from '../../../components/CError.js';

export function* getMemberInfo(data) {
    try {
        yield put({type: types.TEAM_PENDING});
        yield delay(300);
        let member = yield Api.put('/member/get', data.params);
        if(member && member.data){
            yield put({type: types.MEMBER_INFO + '_SUCCESS', payload: member.data});
            data.cb && data.cb(null, member.data);
            return;
        }
        else{
            yield put({type: types.MEMBER_INFO + '_SUCCESS', payload: null});
            data.cb && data.cb(member, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetMemberInfo() {
    while (true){
        const watcher = yield takeLatest(types.MEMBER_INFO,getMemberInfo);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
export function* editMember(data) {
    try {
        yield put({type: types.TEAM_PENDING});
        yield delay(300);
        let member = yield Api.put('/member/edit', data.params);
        if(member && member.data){
            yield put({type: types.MEMBER_EDIT + '_SUCCESS', payload: member.data});
            data.cb && data.cb(null, member.data);
            return;
        }
        else{
            data.cb && data.cb(member, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchEditMember() {
    while (true){
        const watcher = yield takeLatest(types.MEMBER_EDIT,editMember);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
