// action types
export const types = {
    LIST_PACKAGE: 'LIST_PACKAGE',
    LIST_PACKAGE_SUCCESS: 'LIST_PACKAGE_SUCCESS',
    UPGRADE_PACKAGE: 'UPGRADE_PACKAGE',
    UPGRADE_PACKAGE_SUCCESS: 'UPGRADE_PACKAGE_SUCCESS',
};


export function getListPackage(cb) {
    return {
        type: types.LIST_PACKAGE,
        cb
    }
}

export function upgradePackage(params,cb) {
    return {
        type: types.UPGRADE_PACKAGE,
        cb,
        params
    }
}
