// import {delay} from 'redux-saga';
import {takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./announcement_actions";
import Api from '../../services/api';
// import error from '../../../components/CError.js';

//##############################
export function* searchAnnouncement(data) {
    try {
        //yield delay(300);
        // Config.getToken = true;
        let searchData = yield Api.put('/announcement/search', data.params);
        if (searchData && searchData.data) {
            data.cb && data.cb(null, searchData.data);
            // return;
        }
        else {
            if (data.cb) data.cb(searchData, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchSearchAnnouncement() {
    while (true) {
        const watcher = yield takeLatest(types.SEARCH_ANNOUNCEMENT, searchAnnouncement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################


//##############################
export function* addAnnouncement(data) {
    try {
        //yield delay(300);
        // Config.getToken = true;
        let addData = yield Api.put('/announcement/add', data.params);
        if (addData && addData.data) {
            data.cb && data.cb(null, addData.data);
            // return;
        }
        else {
            if (data.cb) data.cb(addData, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchAddAnnouncement() {
    while (true) {
        const watcher = yield takeLatest(types.ADD_ANNOUNCEMENT, addAnnouncement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################


//##############################
export function* likeAnnouncement(data) {
    try {
        //yield delay(300);
        // Config.getToken = true;
        let likeData = yield Api.put('/announcement/like', data.params);
        if (likeData && likeData.data) {
            data.cb && data.cb(null, likeData.data);
            // return;
        }
        else {
            if (data.cb) data.cb(likeData, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchLikeAnnouncement() {
    while (true) {
        const watcher = yield takeLatest(types.LIKE_ANNOUNCEMENT, likeAnnouncement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################


//##############################
export function* disLikeAnnouncement(data) {
    try {
        //yield delay(300);
        // Config.getToken = true;
        let likeData1 = yield Api.put('/announcement/dislike', data.params);
        if (likeData1 && likeData1.data) {
            data.cb && data.cb(null, likeData1.data);
            // return;
        }
        else {
            if (data.cb) data.cb(likeData1, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchDisLikeAnnouncement() {
    while (true) {
        const watcher = yield takeLatest(types.DISLIKE_ANNOUNCEMENT, disLikeAnnouncement);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################