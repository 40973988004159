
import React,{Component} from 'react';
import {Button, Image} from 'react-bootstrap';
import '../register/register.css'
import Header from "../header/header";
import {bindActionCreators} from "redux";
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import { connect } from 'react-redux';

class OnBoardingPage extends Component{

    onNext = () => {
        window.location.href = '/';
    };

    render(){
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        if(!checkLogin) {
            window.location.href = '/login';
        }
        return(
            <div className="full_w full_h onboarding-3">
                <Header type={0} login={null}/>
                <div className="register row" style={{marginTop:100}}>
                    <div className="col-sm-12" style={{padding:'0 60px'}}>
                        <div className="txt-condensed-extra-bold"
                             style={{marginBottom:61}}>
                            STEP TWO - BUILD YOUR TEAM
                        </div>
                        <Image src={require('../../assets/images/on-boarding/item-2.png')} className="image-boarding-two"/>
                        <p className="note-register text-center"
                           style={{margin:'47px 0 0', fontSize: 22}}>
                            The first thing that you should do in Teemie is begin by building your team/s. If you have multiple locations then you can easily split teams into separate groups. Start off with your team name and enter in your individual team members. They will receive an email instructing them to download the mobile app.
                        </p>
                        <Button className="next-btn btn-linear shadow"
                                style={{width: 240, height: 56,marginTop:61}}
                                onClick={this.onNext}>
                            NEXT
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(state => ({
        checkLogin : state.main.checkLogin
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
    })
)(OnBoardingPage);
// export default OnBoardingPage;