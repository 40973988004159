/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/components/popup-info/popup-info.js
 */

import React, {Component} from 'react';
import './popup.css';
import {Image} from "react-bootstrap";

class PopupInfo extends Component{
    _cbCancel=()=>{
        if(this.props.cbCancel){
            this.props.cbCancel();
        }
    };
    render(){
        let {data} = this.props;
        return(
            <div className="popup">
                {data}
                <Image className="btn-close" style={{cursor:'pointer'}}
                       onClick={this._cbCancel}
                       src={require('../../../assets/images/btn-close-popup.png')}/>
            </div>
        )
    }
}


export default PopupInfo;