/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/components/header/search-bar.js
 */

import { Button, Image, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as messageActions from "../../redux/message/message_actions";
import * as mainActions from "../../redux/main/main_actions";
import './header.css';
import EllipsisText from "react-ellipsis-text";
import Api from '../../services/api.js';

import * as teamActions from "../../redux/team/team_actions";
import { browserHistory } from 'react-router';

class Header extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            adminName: "No Name",
            popupLogOut: false,
            notifData: [],
            loadingNotifs: false,
            currentNotifPage: 0,
            allowScroll: true,
            lastOffsetLoaded: 0,
            changeTeam: false,
            idTeam: null,
            memberList: null,
            inviteMember:false,
            teamList:null,
            dropdownclose:false,
        };
        this.refreshInterval = null;
    };


    onLogout = () => {
        this.refs.OverlayTrigger.hide();
        this.setState({
            popupLogOut: !this.state.popupLogOut
        })
    };

    logOut = () => {
        if (this.props.onLogout) {
            this.props.onLogout();
        }
    };

    goMess = () => {
        this.props.messageActions.newMessage(null);
        window.location.href = this.props.messageNew.notification.click_action;
    };

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 5;


        if (bottom && this.state.lastOffsetLoaded < e.target.scrollTop) {
            console.log('notif bottom reached');
            this.getNotificationData(this.state['currentNotifPage'] * 10);
            this.state.currentNotifPage = this.state['currentNotifPage'] + 1;

            this.state.lastOffsetLoaded = e.target.scrollTop + 30;
        }
    }


    componentDidMount() {
        this.getNotificationData(this.state.currentNotifPage * 10);
        this.badgeInterval = setInterval(() => this.props.mainActions.getNumberBadge((err, data) => {
            console.log("getNumberbadge", err, data);
            if (data && data.badge) {
                this.props.mainActions.updateNumberBadge(data.badge, (err, data) => {
                    console.log("updateNumberBadge", err, data);
                });
            } else {
                console.log('Data get number badge is error')
            }
        }), 5000);

let {teamId} = this.props;
if(localStorage.getItem('teamsId')){
    let storedTeamsId = localStorage.getItem('teamsId');
    let teamsId = storedTeamsId ? JSON.parse(storedTeamsId) : teamId;
    let teamIds = teamsId.length > 0 ? teamsId[0] : null;

    console.log("Retrieved from localStorage:", teamsId);
    console.log("Using teamId:", teamId);

    this._init(teamIds ? teamIds : null);
}
else{
    this._init(teamId?teamId:null);
}
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("prevProps", prevProps);
        if(prevProps.toggleTeamSelection !== this.props.toggleTeamSelection){
            setTimeout(()=>{
            console.log("toggleTeamSelection check", this.props.toggleTeamSelection,this.state.changeTeam);
            if(!this.props.toggleTeamSelection && this.state.changeTeam){

                    console.log("toggleTeamSelection timeout", this.props.toggleTeamSelection,this.state.changeTeam);
                    this.changeTeam();
            }
                },1000);

        }
    }

   async _init(idTeam){
        let id = window.location.search.split('id=');
       
        id = (id && id.length>1) ? id[id.length-1] : null;
        id = id ? id: idTeam;
        await this.props.teamActions.getTeamList((err, data)=> {
            
            if(err){
                if(err.code === 'SYS016'){
                    Config.popup.show('INFO',[Config.alertMess(err)],null, ()=>{
                        localStorage.removeItem('LOGIN');
                        window.location.href = '/login';
                        return;
                    });
                    setTimeout(()=>{
                        window.location.href='/login';
                    },3000);
                }
                else{
                    this.setState({
                        teamList:[]
                    });
                }
            }
            if(data){
                let dataTenp = [];
                let checkLogin = localStorage.getItem('LOGIN');
                checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
                for(let i in data){
                    let isStaff = data[i].members.staffs.filter(i=>i.userID === checkLogin.user.id);
                    if(isStaff && isStaff.length <= 0)
                        dataTenp.push(data[i]);
                }

                let location = window.location.href;
                let selectedTid;
                if (window.location.href.indexOf('selectedTid') && location.indexOf('&uid')) {
                    selectedTid = window.location.search.split('selectedTid=');
                    selectedTid = selectedTid && selectedTid.length > 1 ? selectedTid[1] : null;
                }

                let idTemp = selectedTid ? selectedTid : ( id ? id : ((dataTenp && dataTenp.length > 0) ? dataTenp[0].id : null));
                this.setState({
                    idTeam : idTemp,
                    teamList:dataTenp
                },()=>{
                    this._getMemberTeamList();
                    this.props.teamActions.saveTeam(idTemp);
                    if(this.props.dataListTeam) this.props.dataListTeam(dataTenp, idTemp);
                });
            }
        });
    }

    _getMemberTeamList = () => {
        this.props.teamActions.getMemberTeamList({limit:1000, skip:0,team: this.state.idTeam},(err, data)=> {
            if(err){
                if(this.props.searchMember) this.props.searchMember([]);
            }
            else{
                if(this.props.searchMember) this.props.searchMember(data);
            }
        });
    };

    changeTeam = () => {
      this.setState({
          changeTeam: !this.state.changeTeam
      });
      if(!this.state.changeTeam){
        this.setState({
            dropdownclose:false, 
        })
    }
      setTimeout(()=>{
        this.props.isTeamOpened(this.state.changeTeam);
    },100);
    localStorage.removeItem('teamsId');
    };

    dropdownclose = () => {
        if(this.state.changeTeam){
            this.setState({
                dropdownclose:true,
                changeTeam:false
            })
        }
      };

    changeDropDownTeam = (data) => {
        this.setState({
            idTeam: data.id
        },()=>{
            this.props.teamActions.saveTeam(data.id);
            this._init(data.id);
            if(this.props.changeDropDownTeam) this.props.changeDropDownTeam(data);
        });
       
    };

    markAllSeenNotifsAsRead() {
        console.log("On exit called")

        /*if (this.state.notifData.length>0){
            for(let ind = 0;ind<this.state.notifData.length;ind++){
                this.markNotificationRead(this.state.notifData[ind]);
            }
        }*/
    }

    componentWillUnmount() {
        clearInterval(this.badgeInterval);
    }


    markNotificationRead(notifId) {
        console.log("Tryuing it")
        Api.put('/notification/edit', {
            id: notifId,
            read: 'Y'
        })
            .then(response => response)
            .then(data => {
                console.log("Tryuing it")
                console.log(data);
            })
    }


    getNotificationData = (skip = 0, refresh = false) => {
        Api.put('/notification/search', {
            sort: '[{"createdAt": "DESC"}]',
            skip: skip,
            limit: 10
        })
            .then(response => response)
            .then(data => {

                let newList = this.state.notifData.concat(data.data);

                this.setState({ notifData: newList });
                console.log("got notifications")
                console.log(this.state.notifData)
            })

    }

    refreshNotifs = (e) => {
        this.setState({ notifData: [] })
        this.state.currentNotifPage = 1;

        this.state.lastOffsetLoaded = 0;
        this.getNotificationData(0);

        this.props.mainActions.getNumberBadge((err, data) => {
            console.log("getNumberbadge", err, data);
            if (data && data.badge) {
                this.props.mainActions.updateNumberBadge(data.badge, (err, data) => {
                    console.log("updateNumberBadge", err, data);
                });
            } else {
                console.log('Data get number badge is error')
            }
        });
    }
    render() {
        let { type, login, numberBadge,isSearch } = this.props;
        console.log(numberBadge);
        let size = null;
        if (type === 0) {
            size = {
                logo: 40,
                header: 60
            };
        } else {
            size = {
                logo: 40,
                header: 100
            };
        }

        let dataMenu = [
            {
                name: 'Account',
                link: '/my-account',
                icon: require('../../assets/images/side-menu/setting-logo.png'),
                iconW: require('../../assets/images/side-menu/setting-logo-w.png')              
               
            },
            {
                name:'User Management',
                link:'/user-management',
                icon: require('../../assets/images/side-menu/icon-profile.png'),
                iconW: require('../../assets/images/side-menu/icon-profile-w.png')
            },
            {
                name: 'Help',
                link: 'https://teemieapp.com/contact/',
                icon: require('../../assets/images/side-menu/icon-help.png'),
                iconW: require('../../assets/images/side-menu/icon-help-w.png')
            },
            {
                name: 'T&C',
                link: 'https://teemieapp.com/terms-of-usage/',
                icon: require('../../assets/images/side-menu/icon-TC.png'),
                iconW: require('../../assets/images/side-menu/icon-TC-w.png')
            },
            {
                name: 'Log Out',
                icon: require('../../assets/images/side-menu/icon-logout.png'),
                iconW: require('../../assets/images/side-menu/icon-logout-w.png')
            }
        ];


        let badge = numberBadge && numberBadge !== '0' ? (parseInt(numberBadge, 10) > 99 ? '99+' : numberBadge) : null;
        console.log("login**************************************", login);

        let {teamList} = this.state;
        console.log("teamList", teamList);
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;        
        let myTeam = '';
        if(this.props.teamList && this.state.idTeam)
            myTeam = this.props.teamList.find(i=>i.id === this.state.idTeam);
        let logo = myTeam && myTeam.members && myTeam.members.admins && myTeam.members.admins.length > 0
            ? myTeam.members.admins[0].userAvatar
            : '';
        let logoCompany = logo ? logo : (checkLogin && checkLogin.company && checkLogin.company.logo) || null;
        return (
            <div className="header-container align-between"
                style={{ height: size.header }} onClick={this.dropdownclose}>
                
                {(!login || type === 0) && (
                    <div className="display_row align-right">
                        <img alt="" className="icon-social-header"
                            src={require('../../assets/images/header/icon-instagram.png')} />
                        <img alt="" className="icon-social-header"
                            src={require('../../assets/images/header/icon-facebook.png')} />
                        <img alt="" className="icon-social-header"
                            src={require('../../assets/images/header/icon-twitter.png')} />
                    </div>
                )}
                {login &&
                <div className='header-block'>
                    <div className="display_row align-center dropdown-list-block dropdownslist">
                        {isSearch &&
                    <div className="drop-down-list-team display_row no-hover menu-link"
                         onClick={this.changeTeam}>
                         
                        <div className="team-name team-name-title">{(myTeam && myTeam.name.length > 15) ? `${myTeam.name.substring(0, 15)}...` : myTeam.name}</div>
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" className='down_arrow team_select' height="14" viewBox="0 0 21 14" fill="none">
  <path d="M10.5978 8.37782L3.29389 1.15299C2.9672 0.830637 2.44465 0.833162 2.12213 1.15973L0.842019 2.45252C0.516159 2.78161 0.516992 3.31606 0.843686 3.64515L10.0086 12.8403C10.1711 13.0044 10.3845 13.0869 10.5978 13.0869C10.8112 13.0869 11.0245 13.0044 11.187 12.8403L20.352 3.64515C20.6795 3.31691 20.6803 2.78245 20.3536 2.45252L19.0735 1.15973C18.751 0.834003 18.2276 0.830636 17.9018 1.15299L10.5978 8.37782Z" fill="#31CCBC"/>
</svg>
                        {this.state.changeTeam && !this.state.dropdownclose && this.state.idTeam && teamList && teamList.length>0 && (
                            <div className="group-team-list-popup shadow-1">
                                <div onClick={this.changeTeam}
                                     className="bg-close-popup full_w full_h"
                                     style={{opacity:0, position:'fixed', top:0, left:0, background:'rgba(0,0,0,0.1)', cursor:'pointer'}}/>
                                {teamList.map((key, idx)=>{
                                    let classView = (key.id === this.state.idTeam);
                                    return(
                                        <div key={idx+'drop-down-item'}
                                             className={"drop-down-list-team display_row col-sm-6 "+(classView ? "background" : "")}
                                             style={{cursor:'pointer',padding:0}} onClick={()=>{this.changeDropDownTeam(key)}}>
                                            <div className="team-name" style={{paddingLeft:20}}> {key.name.length > 20 ? `${key.name.substring(0, 20)}...` : key.name}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
    }
                </div>
                    <div className="display_row align-center header_fixed">
                        <OverlayTrigger ref={"OverlayTrigger"} placement="bottom" rootClose trigger="click"
                            onClick={this.refreshNotifs}
                            overlay={
                                <Tooltip id="notif" bsClass="fade in tooltip bottom popup-menu-profile"
                                    onScroll={this.handleScroll}

                                >

                                    <div className="notif-menu-title">Notifications</div>
                                    <div className="notif-menu-desc">Keep up to date with your teams activities</div>
                                    {this.state.notifData.map((item, idx) => {
                                        return <NotificationLink data={item} key={"menu-item-" + idx}
                                        />
                                    })}
                                </Tooltip>}>
                            <a className="header-notification display_row align-center"
                                style={{ fontSize: 30, cursor: 'pointer' }}
                            //  onClick={this.getNotificationData}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
  <path d="M20.3807 25.3612C20.3807 28.1227 18.142 30.3612 15.3805 30.3612C12.6191 30.3612 10.3806 28.1227 10.3806 25.3612C10.3806 22.5998 12.6191 20.3611 15.3805 20.3611C18.142 20.3611 20.3807 22.5998 20.3807 25.3612Z" fill="#C4C4C4"/>
  <path d="M15.3805 5.36101C14.6907 5.36101 14.1306 4.80116 14.1306 4.11108V1.61101C14.1306 0.921158 14.6907 0.361084 15.3805 0.361084C16.0706 0.361084 16.6307 0.921158 16.6307 1.61101V4.11108C16.6307 4.80116 16.0706 5.36101 15.3805 5.36101Z" fill="#C4C4C4"/>
  <path d="M27.1043 21.501C25.2144 19.9034 24.1307 17.5697 24.1307 15.096V11.611C24.1307 6.78595 20.2045 2.86108 15.3805 2.86108C10.5569 2.86108 6.63062 6.78595 6.63062 11.611V15.096C6.63062 17.5697 5.54686 19.9048 3.64554 21.5122C3.15939 21.9272 2.88062 22.5335 2.88062 23.1734C2.88062 24.3799 3.86183 25.3611 5.06804 25.3611H25.693C26.8995 25.3611 27.8807 24.3799 27.8807 23.1734C27.8807 22.5335 27.6019 21.9272 27.1043 21.501Z" fill="#CFCFCF"/>
</svg>
                                {badge && <span className="number-badge">{badge}</span>}

                            </a>
                        </OverlayTrigger>
                        <OverlayTrigger ref={"OverlayTrigger"} placement="bottom" rootClose trigger="click" overlay={
                            <Tooltip id="edit" bsClass="fade in tooltip bottom popup-menu-profile">
                                <div className="display_row" style={{ position: 'relative', margin: '0 10px 40px 10px' }}>
                                    <Image className="header-avatar"
                                           src={`${(login && login.user && login.user.avatar) ? login.user.avatar : require('../../assets/images/img-def.jpeg')}`}
                                    />
                                    {/*{badge &&  <NotificationBadge />}*/}
                                    <div className="header-name">
                                        <EllipsisText
                                            text={login.user && login.user.fullName ? login.user.fullName : 'No Name'}
                                            length={28}
                                        />
                                    </div>
                                </div>
                                {dataMenu.map((key, idx) => {
                                    return <Link data={key} key={"menu-item" + idx} onLogout={this.onLogout} />
                                })}
                            </Tooltip>}>
                            <a className="header-logout display_row align-center" style={{ fontSize: 30, cursor: 'pointer' }}>
                                <Image className="header-avatar"
                                       src={`${(login && login.user && login.user.avatar) ? login.user.avatar : require('../../assets/images/img-def.jpeg')}`}
                                />
                                   

                                   <svg xmlns="http://www.w3.org/2000/svg" width="21" className='down_arrow' height="14" viewBox="0 0 21 14" fill="none">
  <path d="M10.5978 8.37782L3.29389 1.15299C2.9672 0.830637 2.44465 0.833162 2.12213 1.15973L0.842019 2.45252C0.516159 2.78161 0.516992 3.31606 0.843686 3.64515L10.0086 12.8403C10.1711 13.0044 10.3845 13.0869 10.5978 13.0869C10.8112 13.0869 11.0245 13.0044 11.187 12.8403L20.352 3.64515C20.6795 3.31691 20.6803 2.78245 20.3536 2.45252L19.0735 1.15973C18.751 0.834003 18.2276 0.830636 17.9018 1.15299L10.5978 8.37782Z" fill="#31CCBC"/>
</svg>
                            </a>
                        </OverlayTrigger>

                    </div>
                    </div>
                }
                <ConfirmLogOut show={this.state.popupLogOut} handleClose={this.onLogout} logOut={this.logOut} />
            </div>
        )
    }





}

class ConfirmLogOut extends Component {

    _handleClose = () => {
        if (this.props.handleClose) this.props.handleClose();
    };

    _logOut = () => {
        if (this.props.logOut) this.props.logOut();
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this._handleClose} id="popup-confirm-roster" className="fix-modal">
                <Modal.Body>
                   
                    <Image className="logo" src={require('../../assets/images/register/icon-member.png')} style={{ height:"63px"}}/>
                    <div className="content" style={{marginTop:"20px"}}>
                        Are you sure you wish to log out from Teemie?
                    </div>
                    <div className="display_row" style={{ padding: '10px 0' , margin: '40px 0px 28px 119px'  }}>
                        <Button className="login_btn_login btn-linear-orange shadow_popup"
                            style={{ maxHeight: 50,  margin: "0px 43px 0px 0px" , borderRadius:"25px"}}
                            onClick={this._handleClose}>
                            CANCEL
                        </Button>
                        <Button className="login_btn_login btn-linear shadow_popup"
                            style={{ maxHeight: 50, margin: '0 10px' , borderRadius:"25px" }}
                            onClick={this._logOut}>
                            CONFIRM
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

class NotificationLink extends Component {
    constructor(props) {
        super(props);
        this.notifData = {};
        this.state = {}


    }

    markNotificationRead(notifId) {
        console.log("Tryuing it")
        Api.put('/notification/edit', {
            id: notifId,
            read: 'Y'
        })
            .then(response => response)
            .then(data => {
                console.log("Tryuing it")
                console.log(data);
            })
    }

    getElapsedTime(dateString) {
        let diff = Date.now() - Date.parse(dateString);


        let diffDays = Math.trunc(diff / (1000 * 60 * 60 * 24))
        let diffHours = Math.trunc(diff / (1000 * 60 * 60))
        let diffMinutes = Math.trunc(diff / (1000 * 60))
        if (diffDays > 1) {
            return diffDays + " days ago";
        } else if (diffDays === 1) {
            return diffDays + " day ago";
        } else if (diffHours > 1) {
            return diffHours + " hours ago";
        } else if (diffHours === 1) {
            return diffHours + " hour ago";
        } else if (diffMinutes > 1) {
            return diffMinutes + " minutes ago";
        } else if (diffMinutes <= 1) {
            return "Less than 1 minute ago";
        } else {
            return "In the past"
        }

    }

    handleNotifClick = (e, data) => {      
        e.preventDefault();
        console.log("--------------------data",data)
        let action = data.action ? data.action : null;
    
        if(action != null) {
            switch(data.payload.type) {
                case 'EVENT':
                    action = action.replace(RegExp('calendar?.*'), `calendar?eventId=${data.extra.id}&tid=${data.extra.teamId}`);
                    break;
                case 'LIKE_ANNOUNCEMENT':
                    action = '/announcements';
                    break;
                case 'ROSTER': case 'SEND_NOTI':
                    action = action.replace('roster?tid=', `roster?user=${data.sender.id}&tid=`);
                    break;
                case 'TASK':
                    action = `task-detail?taskId=${data.extra.taskId}`;
                    break;
                case 'NEW_WELCOME':
                    action = '/welcome-manager';
                    break;
                case 'NEW_HOLIDAY':
                    action = '/roster';
                    if (action) {
                        const rosterData = {
                            holiday: data.payload.type,
                            rosters: data.extra,
                            notiID:data.id
                        };
                
                        localStorage.setItem('ROSTERPAGESTATE', JSON.stringify(rosterData));
                    }
                    break;                            
            }
        }

        console.log(`The link ${action} was clicked.`);

        this.markNotificationRead(data.id);
      
        let teamsId=[]
        if(data.extra.teamId){
            teamsId.push(data.extra.teamId)
        }
        localStorage.setItem('teamsId', JSON.stringify(teamsId));
     
        if(data.payload.type === 'CHAT') {
            browserHistory.push(action);
        } 
        else if(data.payload.type === 'NEW_HOLIDAY_REQUEST_APPROVED'){
            browserHistory.push(data.action);
        }
        else if(data.payload.type === 'NEW_HOLIDAY_REQUEST_REJECTED'){
            browserHistory.push(data.action);
        }
        else {
            window.location.href = action;
        }  
    }


    render() {
        let { data } = this.props;

        if(data) {

            let notif_types = {
                "MESSAGE": require('../../assets/images/notifications/message_icon.png'),
                "CHAT": require('../../assets/images/notifications/message_icon.png'),
                "TASK": require('../../assets/images/notifications/calendar_icon.png'),
                "ROSTER": require('../../assets/images/notifications/Roster_icon.png'),
                "EVENT": require('../../assets/images/notifications/event_icon.png'),
                "ANNOUNCEMENT": require('../../assets/images/notifications/announcement_icon.png'),
                "LIKE_ANNOUNCEMENT": require('../../assets/images/notifications/announcement_icon.png'),
                "UPGRADE": require('../../assets/images/notifications/people_icon.png'),
                "NEW_WELCOME":require('../../assets/images/notifications/people_icon.png'),
            }


            let notifTypeIcon = notif_types[data.payload.type]
            if (notifTypeIcon === null || notifTypeIcon === undefined) {
                notifTypeIcon = require('../../assets/images/notifications/people_icon.png')
            }

            return (

                <a className={data.read === 'N' ? 'notif-unread' : 'notif-read' + " link notification-link"}

                    href="#" onClick={(e) => this.handleNotifClick(e, data)}
                    target={data.target ? data.target : null}

                >
                   <Image ref={"icon"} className="icon-link"  src={data.sender && data.sender.avatar ? data.sender.avatar : "no-image-placeholder.png"} />
                    <Image ref={"type-icon"} className="notif-type-icon" src={notifTypeIcon} />
                    <div className="notif-message">
                        <div>{data.title}</div>
                        <div className="notif-elapsed-time">{this.getElapsedTime(data.createdAt)}</div>

                    </div>
                </a>
            )} else {
                return (<div/>)
            }
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

}

class Link extends Component {
    constructor(props) {
        super(props);
        this.state = {
            icon: false
        }
    }

    logOut = (data) => {
        if (data.name !== 'Log Out') return;
        if (this.props.onLogout) this.props.onLogout();
    };

    render() {
        let { data, numberBadge } = this.props;
        return (
            <a className="link"
                onMouseOver={() => {
                    this.setState({ icon: true })
                }}
                href={data.link ? data.link : null}
                target={data.target ? data.target : null}
                onClick={() => this.logOut(data)}
                onMouseOut={() => {
                    this.setState({ icon: false })
                }}>
                <Image ref={"icon"} className="icon-link" src={this.state.icon ? data.iconW : data.icon} />
                {data.name === 'Notifications' && numberBadge && <span className="number-badge">{numberBadge}</span>}
                {data.name}
            </a>
        )
    }
}




// export default Header;



export default connect(state => ({
    messageNew: state.message.messageNew,
    numberBadge: state.main.numberBadge,
    checkLogin : state.main.checkLogin,
    teamList: state.team.teamList,
    memberList: state.team.memberList,
    teamId: state.team.teamId
}),
    (dispatch) => ({
        messageActions: bindActionCreators(messageActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
    })
)(Header);


