// action types
export const types = {
    TASK_LIST: 'TASK_LIST',
    TASK_LIST_SUCCESS: 'TASK_LIST_SUCCESS',
    TASK_ADD: 'TASK_ADD',
    TASK_ADD_SUCCESS: 'TASK_ADD_SUCCESS',
    TASK_REMOVE: 'TASK_REMOVE',
    TASK_REMOVE_SUCCESS: 'TASK_REMOVE_SUCCESS',
    TASK_DETAIL: 'TASK_DETAIL',
};

export function getListTask(params,cb) {
    return{
        type: types.TASK_LIST,
        params,
        cb
    }
}

export function addTask(params,cb) {
    return{
        type: types.TASK_ADD,
        params,
        cb
    }
}

export function removeTask(params,cb) {
    return{
        type: types.TASK_REMOVE,
        params,
        cb
    }
}

export function detailTask(params,cb) {
    return{
        type: types.TASK_DETAIL,
        params,
        cb
    }
}
