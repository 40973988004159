import {types} from "./roster_actions";

const initialState = {
    rosterList: null,
};

//Nhan action type tu Put va luu data vao store
export default function (state = initialState, action = {}) {
    switch (action.type){
        case types.ROSTER_LIST+'_SUCCESS':
            return{
                ...state,
                rosterList: action.payload
            };

        default:
            return state;
    }
}