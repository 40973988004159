// action types
export const types = {
    SEARCH_WELCOME: 'SEARCH_WELCOME',
    SEARCH_WELCOME_SUCCESS: 'SEARCH_WELCOME_SUCCESS',
    ADD_WELCOME: 'ADD_WELCOME',
    ADD_WELCOME_SUCCESS: 'ADD_WELCOME_SUCCESS',
    REMOVE_WELCOME: 'REMOVE_WELCOME',
    REMOVE_WELCOME_SUCCESS: 'REMOVE_WELCOME_SUCCESS',
    EDIT_WELCOME: 'EDIT_WELCOME',
    EDIT_WELCOME_SUCCESS: 'EDIT_WELCOME_SUCCESS',
};


export function welcomeList(cb) {
    return {
        type: types.SEARCH_WELCOME,
        cb
    }
}

export function addWelcome(params, cb) {
    return {
        type: types.ADD_WELCOME,
        params,
        cb
    }
}

export function removeWelcome(params, cb) {
    return {
        type: types.REMOVE_WELCOME,
        params,
        cb
    }
}

export function editWelcome(params, cb) {
    return {
        type: types.EDIT_WELCOME,
        params,
        cb
    }
}

