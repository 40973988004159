import {types} from "./team_actions";

const initialState = {
    memberList: null,
    teamList: null,
    teamId: null,
    team_pending:false
};

//Nhan action type tu Put va luu data vao store
export default function (state = initialState, action = {}) {
    console.log(state,action)
    switch (action.type){
        case types.TEAM_PENDING:{
            return{
                ...state,
                team_pending:true
            }
        }
        case types.TEAM_LIST+'_SUCCESS':
            return{
                ...state,
                teamList: action.payload,
                team_pending:false
            };
        case types.TEAM_MEMBER_LIST+'_SUCCESS':
            return{
                ...state,
                memberList: action.payload,
                team_pending:false
            };
        case types.TEAM_MEMBER_SEARCH+'_SUCCESS':
            return{
                ...state,
                memberList: action.payload,
                team_pending:false
            };
        case types.SAVE_TEAM+'_SUCCESS':
            return{
                ...state,
                teamId: action.payload,
                team_pending:false
            };
        default:
            return state;
    }
}