export const types = {    
  
    TEAM_MEMBER_LIST_MANAGEMENT: 'TEAM_MEMBER_LIST_MANAGEMENT',
    ADD_MEMBER_COMPANY_MANAGEMENT:
    'ADD_MEMBER_COMPANY_MANAGEMENT' , 
    DELETE_MEMBER_COMPANY_MANAGEMENT:'DELETE_MEMBER_COMPANY_MANAGEMENT'  
 
};

export function getMemberTeamListManagement(params,cb) {
    return{
        type: types.TEAM_MEMBER_LIST_MANAGEMENT,
        params,
        cb
    }
}

export function addMemberListMangement(params,cb) {
  return{
      type: types.ADD_MEMBER_COMPANY_MANAGEMENT,
      params,
      cb
  }
}
export function removeMemberManagement(params,cb) {
    return{
        type: types.DELETE_MEMBER_COMPANY_MANAGEMENT,
        params,
        cb
    }
  }



