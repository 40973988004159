import { delay } from 'redux-saga';
import { takeLatest, take, cancel, put } from 'redux-saga/effects';
import Api from '../../services/api';
import Config from '../../config';
import registerLink from '../../components/register/register-link';
import {types} from '../../redux/user/user_actions'
//##############################
export function* registerPayment(data) {
    try {
        yield delay(300);
        let payment = yield Api.put('/payment/add', data.params);
        if (payment && payment.data) {
            // yield localStorage.setItem('LOGIN', JSON.stringify(register.data));
            data.cb && data.cb(null, payment.data);
            // return;
        }
        else {
            if (data.cb) data.cb(payment, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}
export function* watchRegisterPayment() {
    while (true) {
        const watcher = yield takeLatest(types.REGISTERPAYMENT, registerPayment);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
export function* loginUser1(data) {
    try{
        yield delay(300);
        let login = yield Api.put('/admin/login', data.params);
        if(login && login.data){

            Config.tokenLocal = {teamsize : login.data.teamsize};
            yield localStorage.setItem('LOGIN_TEEMIE', JSON.stringify(login.data));
            yield localStorage.setItem('TOKEN_TEEMIE', JSON.stringify(Config.tokenLocal));
            data.cb && data.cb(null, login.data);
        }
        else{
            if(data.cb) data.cb(login, null);
        }
    }
    catch (e){
        data.cb && data.cb({code:1000 ,message:'Please attempt to log in again after some time'}, null);
    }
}
export function* watchLoginUser1() {
    while (true) {
        const watcher = yield takeLatest(types.LOGIN_USER1, loginUser1);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
export function* RegisterLink(data) {
    try {
        yield delay(300);
        let payment = yield Api.put('/payment/add', data.params);
        if (payment && payment.data) {
            // yield localStorage.setItem('LOGIN', JSON.stringify(register.data));
            data.cb && data.cb(null, payment.data);
            // return;
        }
        else {
            if (data.cb) data.cb(payment, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}
export function* watchRegisterLink() {
    while (true) {
        const watcher = yield takeLatest(types.REGISTERLINK, registerLink);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

export function* upload(data) {

    try {
        yield delay(300);
        let body = null;

        if (data.params.data)
            body = new Buffer(data.params.data, 'base64');
        else
            body = data.params;

        let form = new FormData();
        form.append('files', body, body.name);

        let upload = yield Api.upload('/file/upload', form);
        //  let upload = yield Api.put('/file/upload', data.params);

        if (upload && upload.data) {
            data.cb && data.cb(null, upload.data);
        }
        else {
            data.cb && data.cb(upload, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchCheckUpload() {
    while (true) {
        const watcher = yield takeLatest(types.CHECK_UPLOAD, upload);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################

export function* download(data) {

    try {
        yield delay(300);

        let params = {};
        params.fdList = data.params;
        let file = yield Api.put('/file/download', params);

        if (file && file.data) {
            data.cb && data.cb(null, file.data);
        }
        else {
            data.cb && data.cb(file, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchCheckDownload() {
    while (true) {
        const watcher = yield takeLatest(types.CHECK_DOWNLOAD, download);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

export function* uploadMulti(data) {

    try {
        // yield delay(300);
        let form = new FormData();

        data.params.map(key => {
            let body = null;

            if (key.blob.data)
                body = new Buffer(key.blob.data, 'base64');
            else
                body = key.blob;

            form.append('files', body, key.name);
        });

        let upload = yield Api.upload('/file/upload', form);

        if (upload && upload.data) {
            data.cb && data.cb(null, upload.data);
        }
        else {
            data.cb && data.cb(upload, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchCheckUploadMuti() {
    while (true) {
        const watcher = yield takeLatest(types.CHECK_UPLOAD_MULTI, uploadMulti);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################
export function* checkEmail(data) {
    try {
        yield delay(300);
        let payment = yield Api.put('/user/email', data.params);
        if (payment && payment.data) {
            data.cb && data.cb(null, payment.data);
            return;
        }
        else {
            if (data.cb) data.cb(payment, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchCheckEmail() {
    while (true) {
        const watcher = yield takeLatest(types.CHECK_EMAIL, checkEmail);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################
export function* checKRtoken(data) {
    try {
        yield delay(300);
        let payment = yield Api.put('/user/rtoken', data.params);
        if (payment && payment.data) {
            data.cb && data.cb(null, payment.data);
            return;
        }
        else {
            if (data.cb) data.cb(payment, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}
export function* watchCheckRtoken() {
    while (true) {
        const watcher = yield takeLatest(types.CHECK_RTOKEN, checKRtoken);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
export function* checKRegtoken(data) {
    try {
        yield delay(300);
        let login = yield Api.put('/admin/checkregtoken', data.params);

        if (login && login.data) {
            data.cb && data.cb(null, login.data);
            return;
        }
        else {
            if (data.cb) data.cb(login, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}
export function* watchCheckRegtoken() {
    while (true) {
        const watcher = yield takeLatest(types.CHECK_REGTOKEN, checKRegtoken);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* register(data) {
    try {
        yield delay(300);
        // Config.getToken = true;
        let register = yield Api.put('/user/register', data.params);
        if (register && register.data) {
            // yield localStorage.setItem('LOGIN', JSON.stringify(register.data));
            data.cb && data.cb(null, register.data);
            // return;
        }
        else {
            if (data.cb) data.cb(register, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchRegister() {
    while (true) {
        const watcher = yield takeLatest(types.REGISTER, register);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################
export function* getUser(data) {
    try {
        yield delay(300);
        // Config.getToken = true;
        let register = yield Api.put('/user/get', data.params);
        if (register && register.data) {
            data.cb && data.cb(null, register.data);
            // return;
        }
        else {
            if (data.cb) data.cb(register, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchGetUser() {
    while (true) {
        const watcher = yield takeLatest(types.GET_USER, getUser);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################


//##############################
// export function* searchUser(data) {
//     try {
//         yield delay(300);
//         // Config.getToken = true;
//         let searchUser = yield Api.put('/user/search', data.params);
//         if (searchUser && searchUser.data) {
//             data.cb && data.cb(null, searchUser.data);
//             // return;
//         }
//         else {
//             if (data.cb) data.cb(searchUser, null);
//         }
//     }
//     catch (e) {
//         data.cb && data.cb(null, null);
//     }
// }
//
// export function* watchSearchUser() {
//     while (true) {
//         const watcher = yield takeLatest(types.SEARCH_USER, searchUser);
//         yield take(['LOGOUT', 'NETWORK']);
//         yield cancel(watcher);
//     }
// }

//##############################


//##############################
export function* verifyUser(data) {
    try {
        yield delay(300);
        // Config.getToken = true;
        let verifyUser = yield Api.put('/user/verify', data.params);
        if (verifyUser && verifyUser.data) {
            data.cb && data.cb(null, verifyUser.data);
            // return;
        }
        else {
            if (data.cb) data.cb(verifyUser, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchVerifyUser() {
    while (true) {
        const watcher = yield takeLatest(types.USER_VERIFY, verifyUser);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}



//##############################
export function* editUser(data) {
    try {
        yield delay(300);
        let edit = yield Api.put('/user/edit', data.params);
        if (edit && edit.data) {
            data.cb && data.cb(null, edit.data);
            return;
        }
        else {
            if (data.cb) data.cb(edit, null);
        }
    }
    catch (e) {
        data.cb && data.cb({ code: 1000, message: 'Do not edit successful' }, null);
    }
}

export function* watchEditUser() {
    while (true) {
        const watcher = yield takeLatest(types.EDIT_USER, editUser);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
//##############################

//##############################
export function* loginUser(data) {
    try {
        yield delay(300);
        let login = yield Api.put('/user/login', data.params);
        if (login && login.data) {
            Config.tokenLocal = { token: login.token };
            yield localStorage.setItem('LOGIN', JSON.stringify(login.data));
            yield localStorage.setItem('TOKEN', JSON.stringify(Config.tokenLocal));
            data.cb && data.cb(null, login.data);
        }
        else {
            if (data.cb) data.cb(login, null);
        }
    }
    catch (e) {
        data.cb && data.cb({ code: 1000, message: 'Login error' }, null);
    }
}

export function* watchLoginUser() {
    while (true) {
        const watcher = yield takeLatest(types.LOGIN_USER, loginUser);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* forgotPass(data) {
    try {
        yield delay(300);
        let forgotPass = yield Api.put('/user/forgot', data.params);
        if (forgotPass && forgotPass.data) {
            const id = (forgotPass.data[0] && forgotPass.data[0].id) ? forgotPass.data[0].id : null;
            yield put({ type: types.FORGOT_PASS_SUCCESS, value: id });
            data.cb && data.cb(null, forgotPass.data);
            return;
        }
        else {
            if (data.cb) data.cb(forgotPass, null);
        }
    }
    catch (e) {
        data.cb && data.cb({ code: 1000, message: 'Forgot password is error' }, null);
    }
}

export function* watchForgotPass() {
    while (true) {
        const watcher = yield takeLatest(types.FORGOT_PASS, forgotPass);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//##############################
export function* changePass(data) {
    try {
        yield delay(300);
        let changePass = yield Api.put('/user/reset', data.params);
        if (changePass && changePass.data) {
            data.cb && data.cb(null, changePass.data);
            return;
        }
        else {
            if (data.cb) data.cb(changePass, null);
        }
    }
    catch (e) {
        data.cb && data.cb({ code: 1000, message: 'Change password is error' }, null);
    }
}

export function* watchChangePass() {
    while (true) {
        const watcher = yield takeLatest(types.CHANGE_PASS_USER, changePass);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
//CHECKOUT PAYMENT LINK
//##############################
export function* retrivePaymentLink(data) {
    try {
        yield delay(300);
        console.log('retrivePaymentLink request',data.params);
        let changePass = yield Api.put('/user/checkout/payment/link', data.params);
        if (changePass && changePass.data) {
            data.cb && data.cb(null, changePass.data);
            return;
        }
        else {
            if (data.cb) data.cb(changePass, null);
        }
    }
    catch (e) {
        data.cb && data.cb({ code: 1000, message: 'Retrive Payment Link is error' }, null);
    }
}

export function* watchReteivePayment() {
    while (true) {
        const watcher = yield takeLatest(types.CHECKOUT_PAYMENT_LINK, retrivePaymentLink);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* updatePaymentLink(data) {
    try {
        yield delay(300);
        let changePass = yield Api.put('/checkout/payment/update', data.params);
        if (changePass && changePass.data) {
            data.cb && data.cb(null, changePass.data);
            return;
        }
        else {
            if (data.cb) data.cb(changePass, null);
        }
    }
    catch (e) {
        data.cb && data.cb({ code: 1000, message: 'Retrive Payment Link is error' }, null);
    }
}

export function* watchUpdatePayment() {
    while (true) {
        const watcher = yield takeLatest(types.CHECKOUT_PAYMENT_UPDATE, updatePaymentLink);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
//##############################
