import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./task_actions";
import Api from '../../services/api';

export function* getListTask(data) {
    try {
        yield delay(300);
        yield put({type: types.TASK_PENDING});
        let task = yield Api.put('/task/search', data.params);
        if(task && task.data){
            yield put({type: types.TASK_LIST + '_SUCCESS', payload: task.data});
            data.cb && data.cb(null, task.data);
        }
        else{
            yield put({type: types.TASK_LIST + '_SUCCESS', payload: []});
            data.cb && data.cb(task, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetListTask() {
    while (true){
        const watcher = yield takeLatest(types.TASK_LIST,getListTask);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* addTask(data) {
    try {
        yield delay(300);
        yield put({type: types.TASK_PENDING});
        let task = yield Api.put('/task/add', data.params);
        if(task && task.data){
            yield put({type: types.TASK_ADD + '_SUCCESS', payload: task.data});
            data.cb && data.cb(null, task.data);
        }
        else{
            yield put({type: types.TASK_ADD + '_SUCCESS', payload: []});
            data.cb && data.cb(task, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchAddTask() {
    while (true){
        const watcher = yield takeLatest(types.TASK_ADD,addTask);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* removeTask(data) {
    try {
        yield delay(300);
        yield put({type: types.TASK_PENDING});
        let task = yield Api.put('/task/del', data.params);
        if(task && task.data){
            yield put({type: types.TASK_REMOVE + '_SUCCESS', payload: task.data});
            data.cb && data.cb(null, task.data);
        }
        else{
            data.cb && data.cb(task, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchRemoveTask() {
    while (true){
        const watcher = yield takeLatest(types.TASK_REMOVE,removeTask);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getDetailTask(data) {
    try {
        yield delay(300);
        yield put({type: types.TASK_PENDING});
        let task = yield Api.put('/task/get', data.params);
        if(task && task.data){
            data.cb && data.cb(null, task.data);
        }
        else{
            data.cb && data.cb(task, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetDetailTask() {
    while (true){
        const watcher = yield takeLatest(types.TASK_DETAIL,getDetailTask);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

