/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/index.js
 */

const cacheBuster = new Date().getTime(); // Replace with your cache buster

import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes/routes.js?v=${cacheBuster}';
import './style/index.css?v=${cacheBuster}';
import '../node_modules/font-awesome/css/font-awesome.min.css?v=${cacheBuster}';


function render(Component) {
    ReactDOM.render(
        (
            <Component/>
        ),
        document.getElementById('adminRoot')
    );
}

console.log('this is v2')
render(Routes);

if (module.hot) {
    module.hot.accept('./routes/routes.js', () => {
        const NextApp = require('./routes/routes.js').default;
        render(NextApp);
    });
}


let checkLogin = localStorage.getItem('REFRESHMENT');
let forms ={
  Name:'Teemie'
};
if(!checkLogin){
    localStorage.setItem('REFRESHMENT',JSON.stringify(forms));
    setTimeout(function(){
     window.location.reload();    
    },500) 
}else{
    localStorage.removeItem('REFRESHMENT');
}

import { loadStripe } from '@stripe/stripe-js';

export const stripePromise = loadStripe('pk_live_9LTgRYbGgF8EQQzhvUZ7hM0f');

// Set user information when the user logs in or when you have access to the user's details
let checkLoginUser = localStorage.getItem('LOGIN');
checkLogin = checkLoginUser ? JSON.parse(checkLoginUser) : null;
let user = checkLogin ? checkLogin.user.id : null;
let userName = checkLogin ? checkLogin.user.fullName : null;
let userEmail = checkLogin ? checkLogin.user.email : null
