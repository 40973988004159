import React,{Component} from 'react';
import { Button} from 'react-bootstrap';
import '../register/register.css'
import Header from "../header/header";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/user/user_actions";

class OnBoardingEmailPage extends Component{

    constructor(props) {
        super(props);
        this.paymentStatus=false;
       
      }

    onNext = () => {
        window.location.href = '/login';
    };
    componentDidMount(){
        console.log('Test details');
        
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let href = window.location.href;
        if (urlParams.has('session_id')) {
            console.log(urlParams.get('session_id'));
            let params={session_id:urlParams.get('session_id'),_id:urlParams.get('_id')};
            this.props.userActions.updatePaymentLink(params, (err, data) => {
                console.log(data);
            });
        }
        localStorage.removeItem('FORMDATA');


    }

    render(){
        return(
            <div className="full_w full_h">
                <Header type={0} login={null}/>
                <div className="register row" style={{marginTop:100}}>
                    <div className="col-sm-12" style={{padding:'0 60px'}}>
                        <div className="txt-condensed-extra-bold"
                             style={{marginBottom:60}}>
                            THANK YOU FOR REGISTRATION
                        </div>
                        <p className="note-register text-center"
                           style={{margin:'20px 0 0', fontSize: 23}}>
                            We have sent you an email verification link to activate your account. Please click on this link to begin building your team
                        </p>
                       
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (state) => ({
        listPackage: state.package.listPackage,
      }),
    (dispatch) => ({
      userActions: bindActionCreators(userActions, dispatch),
    })
  )(OnBoardingEmailPage);
  