// action types
export const types = {
    DEVICE_ADD: 'DEVICE_ADD',
    DEVICE_ADD_SUCCESS: 'DEVICE_ADD_SUCCESS',
};

export function addDevice(params,cb) {
    return{
        type: types.DEVICE_ADD,
        params,
        cb
    }
}
