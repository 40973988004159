export const mainTypes = {
    NETWORK: 'NETWORK',
    LOGOUT: 'LOGOUT',
    LOADING: 'LOADING',
    SETTING: 'SETTING',
    UPLOAD_FILE: 'UPLOAD_FILE',
    TAB_BAR: 'TAB_BAR',
    TAB_BAR_SUCCESS: 'TAB_BAR_SUCCESS',
    CHECK_LOGIN: 'CHECK_LOGIN',
    CHECK_OUT: 'CHECK_OUT',
    CHECK_OUT_SUCCESS: 'CHECK_OUT_SUCCESS',
    GET_BADGE_NUMBER: 'GET_BADGE_NUMBER',
    GET_BADGE_NUMBER_SUCCESS: 'GET_BADGE_NUMBER_SUCCESS',
    UPDATE_BADGE_NUMBER: 'UPDATE_BADGE_NUMBER',
    UPDATE_BADGE_NUMBER_SUCCESS: 'UPDATE_BADGE_NUMBER_SUCCESS'
};


export const types = {
    LOADING: 'LOADING' // action type register
};

export function checkLogin(cb) {
    return{
        type: mainTypes.CHECK_LOGIN,
        cb
    }
}


export function checkOut(cb) {
    return{
        type: mainTypes.CHECK_OUT,
        cb
    }
}

export function checkOutNoUrl(cb) {
    return{
        type: mainTypes.CHECK_OUT,
        cb
    }
}

export function loading(cb) {
    return{
        type: mainTypes.LOADING,
        cb
    }
}

export function uploadFile(params,cb) {
    return{
        type: mainTypes.UPLOAD_FILE,
        params,
        cb
    }
}

export function netWorkError() {
    return{
        type: mainTypes.NETWORK
    }
}

export function getNumberBadge(cb) {
    return{
        type: mainTypes.GET_BADGE_NUMBER,
        cb
    }
}

export function updateNumberBadge(params, cb) {
    return{
        type: mainTypes.UPDATE_BADGE_NUMBER,
        params,
        cb,
    }
}