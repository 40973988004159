
import React,{Component} from 'react';
// import * as userActions from "../../redux/user/user_actions";
// import * as companyActions from "../../redux/company/company_actions";
// import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
// import * as memberActions from "../../redux/member/member_actions";
import Calendar from "../staff-calendar/staff-calendar";
import '../register/register.css'
import './staff-hours-detail.css'
import {browserHistory} from 'react-router';
import {Image} from 'react-bootstrap';
import Config from "../../config";
import { connect } from 'react-redux';
import SearchBar from "../my-team-detail/search-bar";
import {bindActionCreators} from 'redux';
class StaffHoursDetail extends Component{

    constructor(props){
        super(props);
        this.state = {
            idTeam: null,
            Data : {
                id : 1,
                avatar      : require('../../assets/images/avatar1.png'),
                staffName   : 'Kate Williams',
                slug        : 'Lead Niamh Ryan',
                street      : 'Brown Sugar, James Street...',
                year        : '2018',
                working     : 'Working today text',
            }
        }
    }

    _getMemberTeamList = () => {
        this.props.teamActions.getMemberTeamList({team: this.state.idTeam},(err, data)=> {
            if(err){
                Config.popup.show('INFO',err.message);
            }
            else{
                // that._goScreenTeamDetai();
            }
        });
    };

    _goToTarget(slug){
        browserHistory.push(slug);
    }

    render(){

        let {teamList} = this.props;
        let myTeam = [];
        return(
            <div className="full_w full_h">
                <div className="register calendar-view row my-team-detail my-team staff-hours">
                    <div style={{position:'relative', minHeight:'100%'}} className="full_w full_h">
                        <div className="col-sm-12 display_col padding-0" style={{position:'relative'}}>
                            <div className="search-bar col-sm-12 shadow-1 display_row align-center">
                                <SearchBar isSearch={true}
                                           teamList={teamList}
                                           myTeam={myTeam}
                                           dataListTeam={this.setDataListTeam}
                                           changeDropDownTeam={this.changeDropDownTeam}/>

                                <div className="search-view" style={{flexWrap:'wrap', justifyContent:'flex-end'}}>
                                    <div className="display_row align-center btn-new-event" onClick={()=>this._goToTarget('/shift')}>
                                        <Image src={require('../../assets/images/roster/icon-create-shift.png')}
                                               className="icon-plus"/>
                                        Create Shift
                                    </div>
                                    <div className="display_row align-center btn-new-event topmenu active"
                                         onClick={this.toggleCreateEvent}>
                                        <Image src={require('../../assets/images/roster/icon-hours.png')}
                                               className="icon-plus"/>
                                        View Staff Hours
                                    </div>
                                    <div className="display_row align-center btn-new-event"
                                         onClick={this.toggleCreateEvent}>
                                        <Image src={require('../../assets/images/icon-plus.png')}
                                               className="icon-plus"/>
                                        Create New Roster
                                    </div>
                                    <div className="display_row align-center btn-new-event"
                                         onClick={this.toggleCreateEvent}>
                                        <Image src={require('../../assets/images/icon-plus.png')}
                                               className="icon-plus"/>
                                        Export to PDF
                                    </div>
                                </div>
                            </div>

                            <div className="staff-detail shift-form display_col" style={{height:'calc(100% - 122px)'}}>
                                <div className="col-sm-12 month-view display_row align-around" style={{border:'none', alignItems:'flex-start', flexWrap:'wrap'}}>
                                    <div className="register staff-info my-team row">
                                        <div className="display_row align-center back-to-roster" onClick={()=>this._goToTarget('/roster')}><img src={require('../../assets/images/roster/icon-back-def.png')} alt={""}/>Back to Roster</div>
                                        <div className="txt-condensed-extra-bold title" style={{fontSize:24, lineHeight:'30px', width:'100%', textAlign:'center'}}>STAFF HOURS</div>
                                        <div className="display_row">
                                            <Image className="shadow staff-avatar" src={this.state.Data.avatar ? this.state.Data.avatar : Config.avaDef}/>
                                            <div className="display_col justify-center">
                                                <span className="txt-name">{this.state.Data.staffName ? this.state.Data.staffName : 'Nguyen Duc Nghia'}</span>
                                                <span className="txt-slug txt-alt">{this.state.Data.slug ? this.state.Data.slug : 'Lead Baristaaaaa'}</span>
                                                <span className="streets txt-alt">{this.state.Data.street ? this.state.Data.street : 'Demo Streets'}</span>
                                                <span className="txt-working txt-alt">{this.state.Data.year ? this.state.Data.year : '2020'}</span>
                                                <span className="txt-working txt-alt">{this.state.Data.working ? this.state.Data.working : 'Not working today'}</span>
                                            </div>
                                        </div>
                                        <Calendar/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(state => ({
        // checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList,
        memberInfo: state.member.memberInfo
    }),
    (dispatch) => ({
        // userActions: bindActionCreators(userActions, dispatch),
        // companyActions: bindActionCreators(companyActions, dispatch),
        // mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        // memberActions: bindActionCreators(memberActions, dispatch),
    })
)(StaffHoursDetail);