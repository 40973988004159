/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/container/app.js
 */

import React, { Component } from 'react';

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';
import allReducers from '../../src/utils/allReducers';
import rootSaga from '../../src/utils/rootSaga';
import {logger} from 'redux-logger';
import Index from './index';

const sagaMiddleware = createSagaMiddleware();

let store;
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// store = createStore(allReducers, composeEnhancers (applyMiddleware(...[sagaMiddleware])));

if (process.env.REACT_APP_LOGGER === 'YES') {
    store = createStore(allReducers, applyMiddleware(logger, ...[sagaMiddleware]));
} else {
    store = createStore(allReducers, applyMiddleware(...[sagaMiddleware]));
}

class App extends Component {
    render() {
        const {children} = this.props;
        return (
            <Provider store={store}>
                <Index>
                    {children}
                </Index>
            </Provider>
        );
    }
}
sagaMiddleware.run(rootSaga);

export default App;
