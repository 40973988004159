/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/components/login/login.js
 */

import React,{Component} from 'react';
import { Button, Image} from 'react-bootstrap';
import * as userActions from '../../redux/user/user_actions';
import CForm from '../libs/cform/cform.js';
import './register.css'

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import Header from "../header/header";
import Config from "../../config";
import ReactLoading from "react-loading";

import { CardElement,Elements,ElementsConsumer } from '@stripe/react-stripe-js';
import { stripePromise } from '../payment/stripe';
import '../payment/payment.scss';

class RegisterPage extends Component{

    constructor(props){
        super(props);

        this.state={
            message: '',
            cardChoose: 1,
            isLoading:false,
            stripeToken:''
        };

        this.isSubmit = false;
        this.dataForm = [
            {
                type: 'TEXT',
                key: 'nameCard',
                id: 'nameCard',
                feedback: false,
                placeholder: 'Name On Card',
                className: '',
                def_value: '',
                // def_value: 'Hoang Thi Thu Trang',
                validator: 'isName'
            },
            /* {
                type: 'MASK',
                mask:'9999 9999 9999 9999',
                key: 'cardNumber',
                id: 'cardNumber',
                feedback: false,
                placeholder: 'Card Number',
                className: '',
                def_value: '',
                // def_value: '4242424242424242',
                validator: 'isNumberCard'
            },
            {
                type: 'MASK',
                key: 'expiry',
                mask:'99/99',
                id: 'expiry',
                feedback: false,
                placeholder: 'Expiry',
                className: '',
                def_value: '',
                // def_value: '12/34',
                validator: 'isExpire'
            },
            {
                type: 'MASK',
                mask: '9999',
                key: 'cvv',
                id: 'cvv',
                feedback: false,
                placeholder: 'CVV',
                className: '',
                def_value: '',
                // def_value: '123',
                validator: 'isCvv'
            } */
        ];
    }

    onConfirm = () => {
        
        if(this.isSubmit || this.state.isLoading){
            return;
        }
        //let check = this.refs['CForm'].formValidate();
        //Removed the Card Form Check Local and moved the validation to Live
        let check=true;
        //let dt = this.refs['CForm'].formValue();

        this.setState({
            isLoading:true
        });
        this.isSubmit = true;
        if(!check) {
            this.setState({message: 'Fill out all fields or input correct details to the fields and try again'});
            setTimeout(()=>{
                this.setState({message: '',isLoading:false});
                this.isSubmit = false;
            },3000);
        }
        else{
            let dataPay = this._getDataPayment();
            dataPay.stripeToken=this.state.stripeToken;
            this.props.userActions.registerPayment(dataPay, (err, data)=>{
                if(data){
                    this.setState({isLoading: false});
                    this.isSubmit = false;
                    localStorage.removeItem('RESPAYMENT');
                    window.location.href = "/on-boarding-email";
                }
                else if(err){
                    this.setState({message: Config.alertMess(err),isLoading:false});
                    this.isSubmit = false;
                    // setTimeout(()=>{
                    //     this.setState({message: '', isLoading:false});
                    //     this.isSubmit = false;
                    // },3000);
                }
            });
        }
    };

    _getDataPayment = () => {
        let dataPay = localStorage.getItem('RESPAYMENT');
        dataPay = dataPay && Config.isJSON(dataPay) ? JSON.parse(dataPay) : null;
        if(!dataPay ||(dataPay && (!dataPay.dataTeam || !dataPay.name || !dataPay.company || !dataPay.password))){
            window.location.href = "/home";
        }
        else{
            console.log('Render Details',dataPay);
            return dataPay;
        }
    };

    cardPaymentProcess = (status,info) => {
        console.log(status);
        if(!status){
            this.setState({message: info});
            setTimeout(()=>{
                this.setState({message: '',isLoading:false});
                this.isSubmit = false;
            },3000);
        }else{
            this.setState({stripeToken:info});
            this.onConfirm();
        }
    };

    render(){
        let dataPay = this._getDataPayment();
        return(
            <div className="full_w full_h">
                <Header type={0} login={null}/>
                <div className="register register-payment row">
                <div className="col-sm-6" style={{padding:'0 70px 0 50px'}}>
                    <div className="display_row justify-center" style={{alignItems: 'flex-start', marginBottom: 75}}>
                        <Image src={require('../../assets/images/register/icon-create-card.png')} className={"image-card" + (this.state.cardChoose === 1 ? " border-color" : "")} onClick={()=>{this.setState({cardChoose:1})}}/>
                        <Image src={require('../../assets/images/register/icon-paypal.png')} className={"image-card" + (this.state.cardChoose === 2 ? " border-color" : "")} onClick={()=>{this.setState({cardChoose:1})}}/>
                    </div>
                    <div className="display_row align-right" style={{float:'right', marginBottom:15}}>
                        <Image src={require('../../assets/images/register/icon-visa.png')} className="image-card small"/>
                        <Image src={require('../../assets/images/register/icon-master-card.png')} className="image-card small"/>
                        <Image src={require('../../assets/images/register/icon-maestro.png')} className="image-card small"/>
                    </div>

                    <div className="App">
        <Elements stripe={stripePromise}>
        <ElementsConsumer>
        {({ elements, stripe }) => (
          <CardPaymentFormClass elements={elements} stripe={stripe} cardPaymentStatus={this.cardPaymentProcess}/>
        )}
      </ElementsConsumer>
      </Elements>
      {this.state.message!=='' && (
                        <div className="register_error" style={{bottom: -32,width: 'calc(100% - 0px)'}}>
                            {this.state.message}
                        </div>
                    )}
      </div>

                    
                    <div className="display_row text-center align-between" style={{width:'100%', justifyContent:'center', margin:'auto',position: 'absolute',bottom: '-95px',left: '50%',transform: 'translate(-50%, -50%)'}}>
                        <a href="https://teemieapp.com/terms-of-usage/" target="_blank"><span className="note-register" style={{fontSize:13, marginBottom:0}}>Terms of Service</span></a>
                        <a href="https://teemieapp.com/about/" target="_blank"><span className="note-register" style={{fontSize:13, marginBottom:0}}>Privacy Policy</span></a>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="txt-condensed-extra-bold" style={{marginTop:-8}}>
                        {dataPay.dataTeam.title ? dataPay.dataTeam.title.toUpperCase() : "N/A"}
                    </div>
                    <Image src={dataPay.dataTeam.image} className="icon-member"/>
                    <div className="txt-price">
                    €{dataPay.dataTeam.price ? dataPay.dataTeam.price : '0'} {dataPay.dataTeam.type === 'Custom_plan_yearly' ? 'P/A' : 'P/M'}
                    </div>
                    <p className="txt-person">
                        Up to {dataPay.dataTeam.packageUser ? dataPay.dataTeam.packageUser : "N/A"} per team
                    </p>
                    <p className="note-register" style={{margin:0, textAlign:'center'}}>
                    You have chosen the {dataPay.dataTeam.title ? dataPay.dataTeam.title : ""} plan and your {dataPay.dataTeam.type === 'Custom_plan_yearly'?'yearly':'monthly'} subscription will be €{dataPay.dataTeam.price ? dataPay.dataTeam.price : '0'} {dataPay.dataTeam.type === 'Custom_plan_yearly' ? 'P/A' : 'P/M'}. Your subcription will automatically renew and you can cancel at any time via My Account settings.
                    </p>
                    {/* <Button className="login_btn_login btn-linear shadow" onClick={()=>this.onConfirm()}>
                        CONFIRM
                        {this.state.isLoading &&
                        <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                            <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                        </div>
                        }
                    </Button> */}
                </div>
            </div>
            </div>
        )
    }
}

class CardPaymentFormClass extends Component {

    constructor(props){
        super(props);
        this.state={
            message: '',
            cardChoose: 1,
            isLoading:false,
            cardholderName: '', // Initialize cardholderName in the component's state
        };
        this.isSubmit = false;
    }
    
    handleCardToken = async (e) => {

        if(this.isSubmit || this.state.isLoading){
            return;
        }
        let stripe=this.props.stripe;
        let elements=this.props.elements;

      console.log('handle submit');
      e.preventDefault();
      console.log(this.props.stripe);
      console.log(this.props.elements);

      console.log("stripesss===========", stripe);
      if (!stripe) {
        return;
      }
      this.setState({
        isLoading:true
    });
    
      this.isSubmit = true;
      console.log('check');
      console.log(elements.getElement(CardElement));
      const result = await stripe.createToken(elements.getElement(CardElement), {
        name: this.state.cardholderName,
      });
  
      if (result.error) {
        console.log('Error****************');
        console.error(result.error.message);
        this.setState({isLoading: false});
                    this.isSubmit = false;
        this.props.cardPaymentStatus(false,result.error.message);
      } else {
        this.setState({isLoading: false});
        this.isSubmit = false;
        // Handle successful token creation, e.g., send to your server for payment processing
        console.log(result.token);
        
        this.props.cardPaymentStatus(true,result.token);
      } 
    };
    handleNameChange = (event) => {
        this.setState({ cardholderName: event.target.value });
      };
  
    render() {
      return (
       
          <div className='card-element-container'>
            <div className='form-group'>
            <input
            type="text"
            id="cardholder-name"
            className='form-control'
            placeholder='Name On Card'
            value={this.state.cardholderName}
            onChange={this.handleNameChange}
          />
          </div>
            <CardElement
              id="card-element"
              className='card-element'
              options={{
                style: {
                  base: {
                    color: '#32325d',
            lineHeight: '24px',
            fontSize: '16px',
                  },
                },
              }}
            />
          
          <Button className="login_btn_login btn-linear shadow" onClick={(e) => this.handleCardToken(e)}>
                        CONFIRM
                        {this.state.isLoading &&
                        <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                            <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                        </div>
                        }
                    </Button>
        </div>
      );
    }
  }

export default connect(state => ({
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch)
    })
)(RegisterPage);