/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/components/header/search-bar.js
 */

import { Button, Image, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as messageActions from "../../redux/message/message_actions";
import * as mainActions from "../../redux/main/main_actions";
import EllipsisText from "react-ellipsis-text";
import Api from '../../services/api.js';
import MenuLeft from '../menu-left/menu-left.js';

class LeftSidebar extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            adminName: "No Name",
            popupLogOut: false,
            notifData: [],
            loadingNotifs: false,
            currentNotifPage: 0,
            allowScroll: true,
            lastOffsetLoaded: 0,
        };
        this.refreshInterval = null;
    };


    onLogout = () => {
        this.refs.OverlayTrigger.hide();
        this.setState({
            popupLogOut: !this.state.popupLogOut
        })
    };

    logOut = () => {
        if (this.props.onLogout) {
            this.props.onLogout();
        }
    };

    goMess = () => {
        this.props.messageActions.newMessage(null);
        window.location.href = this.props.messageNew.notification.click_action;
    };

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 5;


        if (bottom && this.state.lastOffsetLoaded < e.target.scrollTop) {
            console.log('notif bottom reached');
            this.getNotificationData(this.state['currentNotifPage'] * 10);
            this.state.currentNotifPage = this.state['currentNotifPage'] + 1;

            this.state.lastOffsetLoaded = e.target.scrollTop + 30;
        }
    }


    componentDidMount() {
        this.getNotificationData(this.state.currentNotifPage * 10);
        this.badgeInterval = setInterval(() => this.props.mainActions.getNumberBadge((err, data) => {
            console.log("getNumberbadge", err, data);
            if (data && data.badge) {
                this.props.mainActions.updateNumberBadge(data.badge, (err, data) => {
                    console.log("updateNumberBadge", err, data);
                });
            } else {
                console.log('Data get number badge is error')
            }
        }), 5000);

    }

    markAllSeenNotifsAsRead() {
        console.log("On exit called")

        /*if (this.state.notifData.length>0){
            for(let ind = 0;ind<this.state.notifData.length;ind++){
                this.markNotificationRead(this.state.notifData[ind]);
            }
        }*/
    }

    componentWillUnmount() {
        clearInterval(this.badgeInterval);
    }


    markNotificationRead(notifId) {
        console.log("Tryuing it")
        Api.put('/notification/edit', {
            id: notifId,
            read: 'Y'
        })
            .then(response => response)
            .then(data => {
                console.log("Tryuing it")
                console.log(data);
            })
    }

    onMenu = (data) => {

        if (data.route === "/logout") {
            this.props.loginActions.logout();
        }

        this.setState({route: data.route});
    };

    getNotificationData = (skip = 0, refresh = false) => {
        Api.put('/notification/search', {
            sort: '[{"createdAt": "DESC"}]',
            skip: skip,
            limit: 10
        })
            .then(response => response)
            .then(data => {

                let newList = this.state.notifData.concat(data.data);

                this.setState({ notifData: newList });
                console.log("got notifications")
                console.log(this.state.notifData)
            })

    }

    refreshNotifs = (e) => {
        this.setState({ notifData: [] })
        this.state.currentNotifPage = 1;

        this.state.lastOffsetLoaded = 0;
        this.getNotificationData(0);

        this.props.mainActions.getNumberBadge((err, data) => {
            console.log("getNumberbadge", err, data);
            if (data && data.badge) {
                this.props.mainActions.updateNumberBadge(data.badge, (err, data) => {
                    console.log("updateNumberBadge", err, data);
                });
            } else {
                console.log('Data get number badge is error')
            }
        });
    }
    render() {
        let { type, login, numberBadge } = this.props;
        console.log(numberBadge);
        let size = null;
        if (type === 0) {
            size = {
                logo: 40,
                header: 60
            };
        } else {
            size = {
                logo: 40,
                header: 60
            };
        }

        let dataMenu = [
            {
                name: 'Account',
                link: '/my-account',
                icon: require('../../assets/images/side-menu/setting-logo.png'),
                iconW: require('../../assets/images/side-menu/setting-logo-w.png')              
               
            },
            {
                name:'User Management',
                link:'/user-management',
                icon: require('../../assets/images/side-menu/icon-profile.png'),
                iconW: require('../../assets/images/side-menu/icon-profile-w.png')
            },
            {
                name: 'Help',
                link: 'https://teemieapp.com/contact/',
                icon: require('../../assets/images/side-menu/icon-help.png'),
                iconW: require('../../assets/images/side-menu/icon-help-w.png')
            },
            {
                name: 'T&C',
                link: 'https://teemieapp.com/terms-of-usage/',
                icon: require('../../assets/images/side-menu/icon-TC.png'),
                iconW: require('../../assets/images/side-menu/icon-TC-w.png')
            },
            {
                name: 'Log Out',
                icon: require('../../assets/images/side-menu/icon-logout.png'),
                iconW: require('../../assets/images/side-menu/icon-logout-w.png')
            }
        ];

        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;        
        let myTeam = null;
        if(this.props.teamList && this.state.idTeam)
            myTeam = this.props.teamList.find(i=>i.id === this.state.idTeam);
        let logo = myTeam && myTeam.members && myTeam.members.admins && myTeam.members.admins.length > 0
            ? myTeam.members.admins[0].userAvatar
            : null;
        let logoCompany = logo ? logo : (checkLogin && checkLogin.company && checkLogin.company.logo) || null;
        console.log("logoCompany", logoCompany);


        let badge = numberBadge && numberBadge !== '0' ? (parseInt(numberBadge, 10) > 99 ? '99+' : numberBadge) : null;
        return (
<div class="left-sidebar">
            <div class="sidebar-header w-100 d-flex align-items-center">
                    <div class="logo-section w-100 d-flex justify-content-center gap-3">
                        <span class="logo">
                        <Image className="header-logo" style={{ width: size.logo }}
                        src={require('../../assets/images/header/logo-header.png')} />
                        </span>
                        <span class="logo ">
                        <Image className="header-logo team-logo" style={{ width: size.logo }}
                        src={logoCompany} />
                        </span>
                    </div>
                </div>


                <div class="sidebar-main">
                    <MenuLeft onMenu={this.onMenu}/> 
                   
    
                </div>

            </div>
        )
    }
}






// export default LeftSidebar;



export default connect(state => ({
    messageNew: state.message.messageNew,
    numberBadge: state.main.numberBadge,

}),
    (dispatch) => ({
        messageActions: bindActionCreators(messageActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
    })
)(LeftSidebar);


