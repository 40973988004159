import {types} from "./event_actions";

const initialState = {
    eventList: null,
};

//Nhan action type tu Put va luu data vao store
export default function (state = initialState, action = {}) {
    switch (action.type){
        case types.EVENT_LIST+'_SUCCESS':
            return{
                ...state,
                eventList: action.payload
            };

        default:
            return state;
    }
}