import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./management_actions";
import Api from '../../services/api';


export function* addMemberListMangement(data) {
    try {
        // yield put({type: types.ADD_MEMBER_COMPANY_MANAGEMENT});
        // yield delay(300);
        console.log(data);
        let newmember = yield Api.put('/member/addmember', data.params);
        if(newmember && newmember.data){
            yield put({type: types.ADD_MEMBER_COMPANY_MANAGEMENT + '_SUCCESS', payload: newmember.data});
            data.cb && data.cb(null, newmember.data);
            return;
        }
        else{           
            data.cb && data.cb(member, null); 
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}
export function* watchAddMemberListMangement() {
    while (true){
        const watcher = yield takeLatest(types.ADD_MEMBER_COMPANY_MANAGEMENT,addMemberListMangement);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* getMemberTeamListManagement(data) {
    try {
        yield put({type: types.TEAM_PENDING_MANAGEMENT});
        //yield delay(300);
        let team = yield Api.put('/member/company', data.params);
        if(team && team.data){
            yield put({type: types.TEAM_MEMBER_LIST_MANAGEMENT + '_SUCCESS', payload: team.data});
            data.cb && data.cb(null, team.data);
            console.log(data);           
            return;
        }
        else{
            yield put({type: types.TEAM_MEMBER_LIST_MANAGEMENT + '_SUCCESS', payload: []});
            data.cb && data.cb(team, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}
export function* watchGetMemberTeamListManagement() {
    while (true){
        const watcher = yield takeLatest(types.TEAM_MEMBER_LIST_MANAGEMENT,getMemberTeamListManagement);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}

export function* removeMemberManagement(data) {
    try {
        yield put({type: types.TEAM_PENDING});
        //yield delay(300);
        console.log(data);
        let remove = yield Api.put('/user/del', data.params);
        console.log(remove);
        if(remove && remove.data){
            console.log('yes');
            data.cb && data.cb(null, remove.data);
            return;
        }
        else{
            console.log('else');
            data.cb && data.cb(remove, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchRemoveMemberManagement() {
    while (true){
        const watcher = yield takeLatest(types.DELETE_MEMBER_COMPANY_MANAGEMENT,removeMemberManagement);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}



