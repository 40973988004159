
import React,{Component} from 'react';
import { Button, Image } from 'react-bootstrap';
import '../register/register.css'
import Header from "../header/header";

class OnBoardingPage extends Component{

    constructor(props) {
        super(props);
        this.state = {
            showRedirect: false,
        };
    }
    
    componentDidMount() {
        if (this.isMobileDevice()) {
            this.setState({ showRedirect: true });
        }
    }

    isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }   

    onNext = () => {
        window.location.href = '/on-boarding-two';
    };

    render(){
        return(
            <div>
            {this.state.showRedirect ? (
                <OnRedirect />
            ) : (
            <div className="full_w full_h onboarding-1">               
                <Header type={0} login={null}/>                
                <div className="register row" style={{marginTop:100}}>
                    <div className="col-sm-12" style={{padding:'0 60px'}}>
                        <div className="txt-condensed-extra-bold"
                             style={{marginBottom:35}}>
                            WELCOME TO TEEMIE
                        </div>
                        <p className="note-register text-center"
                           style={{margin:'20px 0 0', fontSize: 22}}>
                            Thank you for signing up, we hope that you enjoy this tool and it helps to improve your company as much as it has others :)
Now for a couple of quick pointers and you can begin building your team!
                        </p>
                        <Button className="login_btn_login next-btn btn-linear shadow"
                                style={{marginTop:108}}
                                onClick={this.onNext}>
                            NEXT
                        </Button>
                    </div>
                </div>
            </div>
            )}
        </div>
        )
    }
}

class OnRedirect extends Component {
    render() {
        return (
           
                 
                <div className="reg-row" style={{padding:'20px 20px'}} >
                            <div className='onboarding'>
                        <div className='onboarding-logo'>
                        <Image
                    className="logo"
                    src={require("../../assets/images/mobile-responsive-icon.png")}
                    />
                </div>
                   <div className="col-sm-12" style={{padding:'15px 10px 20px'}}>
                        <div className="txt-header"
                             style={{marginBottom:15}}>
                            Thank you and Welcome!
                        </div>
                        <p className="text-center"
                            style={{margin:'20px 0 0'}}>
                            Thank you for signing up to and trying Teemie. As part of your subscription you will have two ways to access the platform. 
                        </p>
                        <div className='mob-logo sm-row'>
                        <Image className="mob-lap col-6"
                            src={require("../../assets/images/imac-pro-space-grey.png")}
                            />
                        <Image className="mob-mobile col-6"
                            src={require("../../assets/images/iphone-13-pro.png")}
                            />
                        </div>
                        <div className='note-change'>   
                        <p className="text-center" style={{margin:'20px 0 0',}}>
                            Our web app which is for management only and gives you a Teemie dashboard on your laptop/desktop to create, manage and edit to the fullest.</p>
                        <p className="text-center" style={{margin:'20px 0 0',}}>
                            Our specialist iOS and Android apps which are for both management and staff. So you can manage on the go.</p>
                        <p className="text-center" style={{margin:'20px 0 0',}}>
                            When initially building your team, we recommend continuing on the web app, or downloading the mobile app.
                        </p>
                        </div> 
                   </div> 
               </div>       
            </div>
        );
    }
}
export default OnBoardingPage;