import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./shift_actions";
import Api from '../../services/api';

export function* getListShift(data) {
    try {
        yield delay(300);
        let shift = yield Api.put('/shift/search', data.params);
        if(shift && shift.data){
            yield put({type: types.SHIFT_LIST + '_SUCCESS', payload: shift.data});
            data.cb && data.cb(null, shift.data);
        }
        else{
            yield put({type: types.SHIFT_LIST + '_SUCCESS', payload: []});
            data.cb && data.cb(shift, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchGetListShift() {
    while (true){
        const watcher = yield takeLatest(types.SHIFT_LIST,getListShift);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* addShift(data) {
    try {
        yield delay(300);
        let shift = yield Api.put('/shift/add', data.params);
        if(shift && shift.data){
            yield put({type: types.SHIFT_ADD + '_SUCCESS', payload: shift.data});
            data.cb && data.cb(null, shift.data);
        }
        else{
            data.cb && data.cb(shift, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchAddShift() {
    while (true){
        const watcher = yield takeLatest(types.SHIFT_ADD,addShift);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* removeShift(data) {
    try {
        yield delay(300);
        let shift = yield Api.put('/shift/del', data.params);
        if(shift && shift.data){
            yield put({type: types.SHIFT_DELETE + '_SUCCESS', payload: shift.data});
            data.cb && data.cb(null, shift.data);
        }
        else{
            data.cb && data.cb(shift, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchRemoveShift() {
    while (true){
        const watcher = yield takeLatest(types.SHIFT_DELETE,removeShift);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* editShift(data) {
    try {
        yield delay(300);
        let shift = yield Api.put('/shift/edit', data.params);
        if(shift && shift.data){
            yield put({type: types.SHIFT_EDIT+ '_SUCCESS', payload: shift.data});
            data.cb && data.cb(null, shift.data);
        }
        else{
            data.cb && data.cb(shift, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchEditShift() {
    while (true){
        const watcher = yield takeLatest(types.SHIFT_EDIT,editShift);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
