
import React,{Component} from 'react';
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as rosterActions from "../../redux/roster/roster_actions";
import '../register/register.css'
import {Button, Image,Modal} from 'react-bootstrap';
import Config from "../../config";

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import CForm from "../libs/cform/cform";
import SearchBar from "./search-bar";
import {browserHistory} from 'react-router';
import ReactLoading from "react-loading";
import './my-team-detail.css'
import moment from "moment";

import Header from '../header/header';
import Loaderview from '../roster/loaderview';
class MyTeamDetail extends Component{

    constructor(props){
        super(props);
        this.state = {
            changeTeam: false,
            idTeam: null,
            memberList: null,
            inviteMember:false,
            keyWord: '',
            rosterList:[],
            fixedValue:100,
            toggleTeamSelection:true,
            isTeamOpened:false,
        };
        this.dataFilter = {
            limit: 1000,
            skip: 0,
        };
    }

    componentDidMount = () => {
        let state = this.props.location.state;
        let id = state && state.id  ? state.id : null;
        if(!id){
            browserHistory.push('/my-team');
        }
        window.addEventListener('scroll', this.handleScroll);
    };

    componentWillMount() {
        // window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scrollTop = window.pageYOffset;
        if(scrollTop > 0) {
            this.setState({
                fixedValue: 100
            });
        } else {
            this.setState({
                fixedValue: 70
            });
        }
    }

    getRoster = () => {
        let dataFilter = this.dataFilter;
        dataFilter.team = this.state.idTeam;
        dataFilter.timeFrom = moment().startOf('day').toISOString();
        dataFilter.timeTo = moment().endOf('year').toISOString();
        // return;
        this.props.rosterActions.getListRoster(dataFilter,(err, data)=> {
            if(err){
                // Config.popup.show('INFO',err.message);
                this.setState({
                    rosterList:[]
                });
            }
            else{
                this.setState({
                    rosterList:data
                });
            }
        });
    };

    searchMember = (data) => {
        this.setState({
            memberList: this.props.memberList
        });
    };

    _setListMember = (data) =>{
        this.setState({
            memberList: data
        })
    };

    _getMemberTeamList = () => {
        this.props.teamActions.getMemberTeamList({team: this.state.idTeam},(err, data)=> {
            if(err){
                Config.popup.show('INFO',err.message);
                this.setState({
                    memberList: []
                })
            }
            else{
                this.setState({
                    memberList: data
                })
                // that._goScreenTeamDetai();
            }
        });
    };

    changeTeam = () => {
      this.setState({
          changeTeam: !this.state.changeTeam
      })
    };

    changeDropDownTeam = (data, id) => {
        this.setState({
            idTeam: id
        },()=>{
            this.getRoster();
            this.setState({
                toggleTeamSelection:false
            });
        });
    };

    _togglePopupInviteMember = (flag, cb) => {
        this.setState({
            inviteMember: this.state.inviteMember ? false : (flag ? flag : true)
        },()=>{
            cb && cb();
        })
    };

    _inviteMember = (params, cb) => {
        if(this.state.inviteMember === 'ADMIN'){
            params.role = 'ADMIN';
        }
        params.team = this.state.idTeam;
        this.props.teamActions.inviteMember(params, (err, data)=> {
            if(data){
                this._getMemberTeamList();
            }
            cb && cb(err, data);
        });
    };

    _goRemove=(item)=>{
        this.setState({ isUpdateRemoveMemberConfirmed: false });
       this._removeMember(item);
    };
    removeMember = () => {
        this.setState({ isUpdateRemoveMemberConfirmed: true });
      };
  _ConfirmRemove = (item) => {
    this._removeMember(item);
  };
  _removeMember = (item) => {
    this.props.teamActions.removeMember({id:item.id}, (err, data)=> {
        if (err) {}
        else {
            this._getMemberTeamList();
        }
    })
};

    _searchMember = (keyword) => {
        this.setState({keyWord: keyword.target.value ? keyword.target.value : null});
        let condition = '';
        if(keyword)
            condition = JSON.stringify({"team": this.state.idTeam, "name":keyword.target.value?keyword.target.value:""})
        else
            condition = JSON.stringify({"team": this.state.idTeam});
        this.props.teamActions.searchMemberTeamList({limit:1000, skip:0,condition:condition},(err, data)=> {
            if(err){
                this._setListMember([]);
            }
            else{
                this._setListMember(data);
            }
        });
    };

    setDataListTeam = (data,id) => {
        this.setState({
            idTeam: this.state.idTeam ? this.state.idTeam : (id ? id : null)
        },()=>{
            // this._init();
            this.getRoster();
        });
    };

    _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };

    toggleTeamSelectionClick = () => {
        if(this.state.isTeamOpened){
            this.setState({
                toggleTeamSelection: false,
                isTeamOpened: false
            });
        }
        else{
            this.setState({
                toggleTeamSelection: true
            });
    }
}
    isTeamOpened = (value) => {
        this.setState({
            isTeamOpened: value
        })
    }

    render(){
        let {teamList,memberList} = this.props;
        let myTeam = null;
        if(teamList && this.state.idTeam){
            myTeam = teamList.find(i=>{return (i.id === this.state.idTeam)});
        }
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        let isAdmin = null;
        let isOwner = null;
        if(this.state.memberList){
            isAdmin = this.state.memberList.filter(i=>((i.role === 'ADMIN') && i.user && checkLogin.user && i.user.id === checkLogin.user.id));
            isOwner = this.state.memberList.filter(i=>((i.role === 'OWNER') && i.user && checkLogin.user && i.user.id === checkLogin.user.id));
        }

        let resultFilter = [];

        if(this.state.rosterList && this.state.rosterList.length > 0  && memberList){
            let arrTeam = [...this.state.rosterList];
            arrTeam.forEach(function (hash) {
                return function (a) {
                    if (a.staff && a.staff.id && !hash[a.staff.id]) {
                        hash[a.staff.id] = {staff:a.staff,activities: []};
                        resultFilter.push(hash[a.staff.id]);
                    }
                    if(a.staff && a.staff.id) hash[a.staff.id].activities.push(a);
                };
            }(Object.create(null)));

            if(resultFilter.length < memberList.length){
                for(let i in memberList){
                    let arr = resultFilter.filter(j=>j.staff && memberList[i].user && j.staff.id === memberList[i].user.id);
                    if(arr.length === 0){
                        resultFilter.push({staff:memberList[i].user,activities: []});
                    }
                }
            }

            for(let i in resultFilter){
                resultFilter[i].activities = resultFilter[i].activities.sort((a, b) => {return moment(a.time).valueOf() - moment(b.time).valueOf()})
            }
        }
        
        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
        let toggleTeamSelection = this.state.toggleTeamSelection;
        return(
            <div className="full_w full_h height-100vh my_team_details_container"  onClick={this.toggleTeamSelectionClick}>
                <div className="register1 my-team my-team-detail row"  style={{backgroundColor:'transparent'}}>
                    {!myTeam && <div style={{marginLeft:"-11px"}}> <Loaderview/> </div>}
                <Header isSearch={true}
                                teamList={teamList}
                                myTeam={myTeam}
                                toggleTeamSelection={toggleTeamSelection}
                                searchMember={this.searchMember}
                                dataListTeam={this.setDataListTeam}
                                changeDropDownTeam={this.changeDropDownTeam} type={1} login={login} onLogout={this._onLogout}
                                isTeamOpened={this.isTeamOpened}
                                />



                    
                    <div className="register member-view scroll h-100 body_container" style={{padding:'auto'}}>
                       
                        {memberList && this.state.memberList && (
                            <ListMember idTeam={this.state.idTeam}
                                        data={this.state.memberList}
                                        admin={true}
                                        checkLogin={checkLogin}
                                        keyWord={this.state.keyWord}
                                        inviteMember={this._togglePopupInviteMember}
                                        removeMember={this._goRemove}
                                        resultFilter={resultFilter}
                                        isAdmin={isAdmin && isAdmin.length > 0}
                                        isOwner={isOwner && isOwner.length > 0}
                            />
                        )}
                        {memberList && this.state.memberList && (
                            <ListMember idTeam={this.state.idTeam}
                                        data={this.state.memberList}
                                        admin={false}
                                        checkLogin={checkLogin}
                                        keyWord={this.state.keyWord}
                                        inviteMember={this._togglePopupInviteMember}
                                        removeMember={this._goRemove}
                                        resultFilter={resultFilter}
                                        isAdmin={isAdmin && isAdmin.length > 0}
                                        isOwner={isOwner && isOwner.length > 0}
                            />
                        )}
                            {this.state.inviteMember &&
                                <PopupInvite show={this.state.inviteMember!==false}
                                             inviteMember={this._inviteMember}
                                             handleClose={this._togglePopupInviteMember}/>
                            }
                    </div>
                </div>
            </div>
        )
    }
}
class PopupInvite extends Component{

    constructor(props){
        super(props);
        this.state = {
            show: null,
            isLoading: false
        };
        this.dataForm = [
            {
                type: 'TEXT',
                key: "name",
                id: "name",
                placeholder:"Name",
                def_value: '',
                // def_value:'TrangHoang',
                validator: 'isName',
                feedback: false,
            },
            {
                type: 'TEXT',
                key: "email",
                id: "email",
                placeholder:"Email",
                validator: 'isEmail',
                def_value: '',
                // def_value:'tranghoang1001@gmail.com',
                feedback: false,
            },
            {
                type: 'TEXT',
                key: "title",
                id: "title",
                placeholder:"Job Title",
                def_value: '',
                // def_value:'123456',
                validator: 'isRequired',
                feedback: false,
            }
        ]
    }

    componentDidMount() {
        this.setState({
            show: this.props.show
        })
    }

    _handleClose = () => {
        this.setState({show: false});
        this._timer = setTimeout(()=>{
            clearTimeout(this._timer);
            if(this.props.handleClose) this.props.handleClose();
        },300);
    };

    _inviteMember = () => {
        if(this.isSubmit || this.state.isLoading) return;
        let check = this.refs['CForm'].formValidate();
        let dt = this.refs['CForm'].formValue();
        this.setState({
            isLoading: true
        });
        let that = this;
        if(!check){
            this.setState({messageInviteMember: 'Fill out all fields or input correct details to the fields and try again'});
            setTimeout(()=>{
                this.setState({
                    messageInviteMember: '',
                    isLoading: false
                });
                this.isSubmit = false;
            },2000);
        }
        if(check) {
            let params = {
                name: dt.name.value,
                title: dt.title.value,
                email: dt.email.value,
            };

            if(this.props.inviteMember) this.props.inviteMember(params,(err, data)=>{
                if(data){
                    this._handleClose();
                }
                else{
                    that.isSubmit = true;
                    that.setState({
                        messageInviteMember: err.message
                    });
                    that._timer = setTimeout(()=>{
                        that.setState({
                            messageInviteMember: '',
                            isLoading: false
                        });
                    },2000);
                    that.isSubmit = false;
                    return;
                }
            });
        }
    };

    render(){
        return(
            <Modal show={this.state.show} onHide={this._handleClose} className="fix-modal">
                <Image src={require('../../assets/images/btn-close-popup.png')} className="icon-close" onClick={this._handleClose}/>
                <Modal.Body>
                    <div className="modal-title">Add Staff Member</div>
                    <div className="sub-title add-staff-sub-title">Enter the job title and email of the new team member here and they will recieve a link to register </div>
                    <CForm data={this.dataForm} ref="CForm"/>
                    {this.state.messageInviteMember!=='' && (
                        <div className="register_error" style={{bottom:56, height:32, textAlign:'center', justifyContent:'center', alignItems:'flex-end', width:'100%'}}>
                            {this.state.messageInviteMember}
                        </div>
                    )}
                    <Button className="menu-left-action btn-linear shadow_popup btn-linear-invite" style={{borderRadius:"25px"}} onClick={this._inviteMember}>
                        SEND
                        {this.state.isLoading &&
                        <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                            <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30}/>
                        </div>
                        }
                    </Button>
                </Modal.Body>
            </Modal>
        )
    }
}
class ListMember extends Component{

    constructor(props){
        super(props);
        this.state={
            flagEdit: false,
            flagEditAdmin: false,
            deletemembers:{}
        }
    }

    _goTeamDetail = (key) => {
        let {admin} = this.props;
        if((this.state.flagEdit === this.props.idTeam && !admin) || (this.state.flagEditAdmin===this.props.idTeam && admin)) return;
        if(key.status === 'INVITING'){
            Config.popup.show('INFO','This user has not verified.');
        }
        else{
            browserHistory.push({
                pathname: '/staff-info',
                state: {id:key.id}
            });
        }
    };

    _inviteMember = (flag) => {
        if(this.isSubmit) return;
        this.isSubmit = true;
        let that = this;
        if(this.props.inviteMember)
            this.props.inviteMember(flag, ()=>{that.isSubmit = false;});
    };

    removeasAdmin = (item) => {
        this.setState({
          updatePlanPayment: !this.props.memberActions.editMember({
            id: item.id,
            role: "STAFF",
          }),
        });
      };

    _editMember = (admin) => {
        if(admin){
            this.setState({
                flagEditAdmin:this.state.flagEditAdmin ? false : this.props.idTeam,
                flagEdit: false
            });
        }
        else{
            this.setState({
                flagEdit:this.state.flagEdit ? false : this.props.idTeam,
                flagEditAdmin: false
            });
        }
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0) )* 60);
        second = second <= 9 ? '0'+ parseInt(second,10) : parseInt(second,10);
        a = a[0] + ':' + second;
        return a;
    };
    _removeMember = (item) => {
        this.setState({deletemembers:item});
        this.setState({ isUpdateRemoveMemberConfirmed: true });
      };

    _ConfirmRemove = (item) => {
        this.setState({ isUpdateRemoveMemberConfirmed: false });
        console.log(item);
        this.props.removeMember(item);
      };
    
      _removeMembers = (item) => {
        this.props.teamActions.removeMember({ id: item.id }, (err, data) => {
          if (err) {
          } else {
            this._getMemberTeamList();

          }
        });
      };
    _handleClose = () => {
        setTimeout(()=>{
            this.setState({ isUpdateRemoveMemberConfirmed: false });
        },1000)
        
      };

      _titleLength = (userTitle)=>{
        let maxLength = 15
        if (userTitle.length > maxLength) {
            return userTitle.substring(0, maxLength) + "...";
        } else {
            return userTitle;
        }
    }

    _teamNameLength = (Teamname)=>{
        let maxLength = 15
        if (Teamname.length > maxLength) {
            return Teamname.substring(0, maxLength) + "...";
        } else {
            return Teamname;
        }
    }

    render(){
        let {data, admin, isAdmin,isOwner,keyWord, checkLogin,resultFilter} = this.props;
        let totalAdmin = data.filter(i=>i.role === "ADMIN");
        let totalMember = data.filter(i=>i.role === "STAFF");
        let formatDate = {
            sameDay: '[Today]',
            nextDay: 'DD MMM',
            nextWeek: 'DD MMM',
            lastDay: 'DD MMM',
            lastWeek: 'DD MMM',
            sameElse: 'DD MMM'
        };
        return(
            <div className="col-sm-12 padding-0" style={{marginTop: !admin ? 40 : 0}}>
                <div className="title">
                    {admin ? "Manager" : 'Team'}
                    {admin && isOwner && totalAdmin && !keyWord && (
                        <div className="display_row align-right button-view admin-changes">
                            {totalAdmin.length > 0 &&
                                <Button className="menu-left-action btn-linear-orange shadow_popup"
                                        onClick={() => this._editMember('admin')}
                                        style={{borderRadius:"25px"}}
                                        >
                                    {(this.state.flagEditAdmin === this.props.idTeam) ? "CANCEL" : "EDIT"}
                                </Button>
                            }
                            {totalAdmin.length <3 && 
                               <Button className="btn-linear menu-left-action shadow_popup" onClick={()=>this._inviteMember('ADMIN')} style={{borderRadius:"25px"}}>NEW MANAGER</Button>
                            }
                        </div>
                    )}
                    {!admin && (isAdmin || isOwner) && !keyWord && (
                        <div className="display_row align-right button-view team-changes">
                            {totalMember.length > 0 &&
                                <Button className="menu-left-action btn-linear-orange shadow_popup"
                                        onClick={() => this._editMember(null)} style={{borderRadius:"25px"}}>{(this.state.flagEdit === this.props.idTeam) ? "CANCEL" : "EDIT"}</Button>
                            }
                           <Button className="btn-linear menu-left-action shadow_popup" onClick={()=>this._inviteMember()} style={{borderRadius:"25px"}}>ADD NEW</Button>
                        </div>
                    )}
                </div>
                { resultFilter.length ===0 && this.props.onLoadingChange &&
                        <Loaderview/>}
                <div className="team-view display_row">
                    {data && data.length>0 && data.map((key, idx)=>{
                        if((admin && key.role === "STAFF") || (!admin && key.role !== "STAFF") ) return null;
                        let isRemove =  (checkLogin.user.id !== key.user.id);
                        let infoRoster = resultFilter.filter(i => i.staff && key.user.id === i.staff.id);
                        let userTitle=key.title
                        let Teamname=key.team.name
                        return(
                            <div key={idx} className="member-information animation-show col-sm-6 col-lg-6 my-team-card" style={{cursor:'pointer'}}>
                         
                                {((this.state.flagEdit === this.props.idTeam && !admin) || (this.state.flagEditAdmin===this.props.idTeam && admin)) && isRemove &&
                                    <Image src={require('../../assets/images/button_tasks_delete.png')}
                                           className="icon-close icon-remove my-team-close-icon"
                                           onClick={()=>this._removeMember(key)}
                                    />
                                }
                                <div className="shadow-1 info-in display_row" onClick={()=>this._goTeamDetail(key)}>
                                    {key.status === 'INVITING' && <span className="status-pending">PENDING</span>}
                                    <Image
                                        src={`${(key.user && key.user.avatar) ? key.user.avatar : Config.avaDef}`}
                                        //src={(key.user && key.user.avatar) ? key.user.avatar : Config.avaDef}
                                        className={"ava-member "+(admin?"red":'blue')}
                                    />
                                    <div className="display_col justify-center full_w text-view memberDetails" style={{paddingLeft:30, width:'calc(100% - 150px) !important'}}>
                                        <div className={"member-name info "+(admin?"red":'green_left')}>{key.name ? key.name : ((key.user && key.user.fullName) ? key.user.fullName : '')}</div>
                                        <div className="member-title info" style={{ marginLeft: "-4px" }}>
                                        {key.role !== "OWNER" ? (key && key.title ? this._titleLength(userTitle) : this._teamNameLength(Teamname)) : (key && key.team && key.team.name ? this._teamNameLength(Teamname) : this._titleLength(userTitle))}
                                        </div>
                                        <div className={"member-time info"}><i className="fa fa-clock-o"/>
                                            Next: {(infoRoster && infoRoster.length > 0 && infoRoster[0].activities && infoRoster[0].activities.length > 0 && infoRoster[0].activities[0].timeStart !== (undefined) && infoRoster[0].activities[0].timeEnd !== (undefined)) ? ((infoRoster[0].activities[0].timeStart === 0 && infoRoster[0].activities[0].timeEnd === 0 ? 'OFF': moment(infoRoster[0].activities[0].time).calendar(null, formatDate) + ' ' + this.changeTimeToSecond(infoRoster[0].activities[0].timeStart) + ' - ' + this.changeTimeToSecond(infoRoster[0].activities[0].timeEnd))): "OFF"}
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        )
                    })}

                    {/* Modal popup shown */}

                    <Modal
            show={this.state.isUpdateRemoveMemberConfirmed}
            onHide={this.closeModal}
            id="popup-change-plan"
            className={"root-popup-change-plan remove-team-member remove-team-member-pop-up"}
            style={{  maxWidth: "100%", margin: "auto" }}
          >
            
            <Modal.Body>
              <Image
                className="logo remove-member-logo"
                src={require("../../assets/images/register/icon-member.png")}
              />
              <div className="modal-title">Remove Member</div>
              <div className="title1 remove-admin">Do you want to remove this member from the team?</div>
              <div className="button_display_row" style={{ padding: "10px 0" }}>
                
                <Button
                  className="login_btn_login btn-linear-orange shadow_popup"
                  style={{ maxHeight: 50,  margin: "0px 43px 0px 0px" , borderRadius:"25px" }}
                  onClick={this._handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  className="login_btn_login btn-linear shadow_popup"
                  style={{ maxHeight: 50, margin: "0 5px" , borderRadius:"25px"}}
                  onClick={() => this._ConfirmRemove(this.state.deletemembers)}
                >
                  CONFIRM
                </Button>
              </div>
            </Modal.Body>
          </Modal>
                </div>
            </div>

        )
    }
}
export default connect(state => ({
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        rosterActions: bindActionCreators(rosterActions, dispatch),
    })
)(MyTeamDetail);
