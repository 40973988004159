
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Image } from "react-bootstrap";

import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as announcementActions from "../../redux/announcement/announcement_actions";

import './feed-page.css'
import '../register/register.css'
import CForm from "../libs/cform/cform";
import ReactLoading from "react-loading";
import Config from "../../config";
import moment from 'moment';

import Header from '../header/header';
import Loader from '../loader/loader';
import Loaderview from '../roster/loaderview';

class FeedPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flagState: true,
            imagePreviewUrl: '',
            imageLogo: {
                src: '',
            },
            messages: null,
            feedlist: [],
            likeStatus: null,
            click: 0,
            data: null,
            isLoadMore: true,
            disLoadMore: false
        };

        this.perPage = 10;
        this.numPage = 0;

        this.dataFilter = {
            limit: this.perPage,
            skip: this.numPage * this.perPage,
            sort: '[{"createdAt": "DESC"}]'
        }
    }

    init = (page) => {
        //API Search
        if ((this.state.isLoadMore && this.state.data) || (this.state.disLoadMore && page !== 0)) return;
        this.setState({
            isLoadMore: true
        });
        let dataTemp = this.state.data ? this.state.data.slice(0) : null;
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        if (dataTemp) this.numPage = this.numPage + 1;
        else this.numPage = 0;

        let params = this.dataFilter;
        params.skip = this.numPage * this.perPage;
        params.condition = JSON.stringify({ "company": checkLogin.company.id });

        if (page || page === 0) {
            params.skip = 0;
        }

        this.props.announcementActions.getSearchAnn(params, (err, datashow) => {
            if (err) {
                Config.alertMess(err);
                this.numPage = this.numPage > 0 ? this.numPage - 1 : 0;
                this.setState({
                    isLoadMore: false
                });
            }
            else if (datashow) {
                dataTemp = dataTemp && (!page && page !== 0) ? dataTemp.concat(datashow) : datashow;
                this.setState({
                    data: dataTemp,
                    isLoadMore: false,
                    disLoadMore: datashow.length < this.perPage
                });
            }
        })
    };

    componentDidMount() {
        this.init();
    }

    onUploadImage = (e) => {
        let reader = new FileReader();
        if (e.target.files.length === 0) return null;
        let file = e.target.files[0];

        if (file.size > 1048576) {
            alert('Maximum upload file size 1MB');
            return;
        }

        //let params = {};
        //params.name = file.name;

        reader.onloadend = () => {
            //params.file = reader.result;
            //this._uploadAva(params);
            this._uploadAva(reader.result);
        };

        reader.readAsDataURL(e.target.files[0]);
    };

    _uploadAva = (e) => {
        this.setState({ imageLogo: true });

        fetch(e)
            .then(res => res.blob())
            .then(blob => {
                this.props.userActions.upload(blob, (error, resp) => {
                    //             //this.props.userActions.upload(e, (error, resp) => {
                    //             // this.vlType = null;
                    if (error) {
                        //                 // this.setState({uploadLogoError: "upload-error"});
                        //
                        //                 // setTimeout(()=>{
                        //                     // this.setState({uploadLogoError: ""});
                        //                 // }, 5000);
                        return false;
                    }
                    //
                    //if(resp && resp[0] && resp[0].path) {
                    if (resp && resp.path) {
                        //                 //this.setState({imageLogo: {file: resp[0], src : resp[0].path}, updateLogo: false});
                        this.setState({ imageLogo: { file: resp.file, src: resp.path, content: e }, updateLogo: false });
                    }
                    //
                    this.setState({ imgCrop: null })
                });
            });

        this.setState({ imageLogo: { file: e, src: e, content: e }, updateLogo: false, imgCrop: null });
    };

    pushFeed = (data) => {
        let dataTemp = this.state.data.slice(0); //Clone array
        dataTemp.unshift(data);
        this.setState({
            data: dataTemp
        });
    };

    isFav = (id, idx) => {
        let dataTemp = this.state.data.slice(0);
        let likes = JSON.parse(dataTemp[idx].likes);
        likes = dataTemp[idx].likes ? JSON.parse(dataTemp[idx].likes) : [];
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        if (checkLogin && checkLogin.user && checkLogin.user.id) {
            likes.push(checkLogin.user.id);
            dataTemp[idx].likes = JSON.stringify(likes);
            this.setState({
                data: dataTemp
            });
        }
        let idAnn = id; //id Annoucement.
        this.props.announcementActions.likeAnn({ id: idAnn }, (err, data) => {
            if (err) {
                Config.alertMess(err);
            }
            else if (data) {
                this.init();
            }
        });
    };

    isFavDisLike = (id, idx) => {
        let dataTemp = this.state.data.slice(0);
        dataTemp[idx].likes = "[]";
        this.setState({
            data: dataTemp
        });
        let idAnn = id; //id Annoucement.
        this.props.announcementActions.disLikeAnn({ id: idAnn }, (err, data) => {
            if (err) {
                Config.alertMess(err);
            }
            else if (data) {
                this.init();
            }

        })

    };

    createFeed = (params, cb) => {
        if (params.attachment && !params.attachment.startsWith('data:')) {
            params.attachment = `data:image/jpeg;base64,${params.attachment}`;
        }
        //Call API ADD
        this.props.announcementActions.addAnn(params, (err, data) => {
            if (err) {
                Config.alertMess(err);
            } else if (data) {
                this.setState({
                    messages: 'Add Announcements success',
                    imageLogo: {
                        src: '',
                    },
                });
                cb && cb();
                this.init(0);
            }
        });
        //End Call API
    };

    _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };
    render() {
        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
        return (
            <div className="full_w full_h_1 feed-page height-100vh">
                {this.state.isLoadMore && <div><Loaderview/></div>}
                <Header isSearch={false} type={1} login={login} onLogout={this._onLogout}/>

                <div className="member-view feed-view full_h_1 display_row announcement-feeds body_container">
                    <div className="row w-100">
                        <ListFeedLeft feedlist={this.state.feedlist}
                            imageLogo={this.state.imageLogo}
                            onUpdateImage={this.onUploadImage}
                            data={this.state.data}
                            pushFeed={this.pushFeed}
                            isFav={this.isFav}
                            tickLike={this.tickLike}
                            loadMore={this.init}
                            likeStatus={this.state.likeStatus}
                            isFavDisLike={this.isFavDisLike}
                            ref="ListFeed"
                            userActions={this.props.userActions}
                        />

                        <ListFeedRight
                            feedlist={this.state.feedlist}
                            messages={this.state.messages}
                            createFeed={this.createFeed}
                            imageLogo={this.state.imageLogo}
                            onUpdateImage={this.onUploadImage}
                            data={this.state.data}
                            pushFeed={this.pushFeed}
                            isFav={this.isFav}

                        />
                    </div>
                </div>
            </div>
        )
    }
}

class ListFeedLeft extends Component {

    constructor(props) {
        super(props);

        this.state = {
            imgAva: null,
            imageProfile: null,
            imageLogo: null,
            updateProfile: false,
            updateLogo: false,
            message: null,
            attachmentFile: {}
        };
        this.dataFeed = [
            {
                type: 'TEXT',
                key: 'feedTitle',
                id: 'feedTitle',
                feedback: false,
                placeholder: 'Enter Announcement',
                className: 'shadow-1 input_name',
                def_value: '',
                // def_value: 'Trang Hoàng',
                validator: 'isName'
            },
            {
                type: 'TEXT_AREA',
                key: 'feedContent',
                id: 'feedContent',
                feedback: false,
                placeholder: 'Enter Announcement Text',
                className: 'shadow-1',
                def_value: '',
                validator: 'isRequired'
            }
        ];
        this.el = null;
    }

    isFav = (id, idx) => {
        if (this.props.isFav) this.props.isFav(id, idx);
    };
    isFavDisLike = (id, idx) => {
        if (this.props.isFavDisLike) this.props.isFavDisLike(id, idx);
    };
    //original code :

    // downloadFile = (fd) => {
    //     this.props.userActions.download(fd, (error, resp) => {
    //         if (error) {
    //             return false;
    //         }

    //         const buffer = resp.buffer.data; // e.g., <Buffer 89 50 4e ... >
    //         const b64 = new Buffer(buffer).toString('base64')
    //         //const mimeType = resp.type // e.g., image/png
    //         const mimeType = 'image/jpeg'

    //         let attachmentFiles = this.state.attachmentFiles ? this.state.attachmentFiles : {};
    //         attachmentFiles[fd] = `data:${mimeType};base64,${b64}`;

    //         this.setState({ attachmentFiles: attachmentFiles });

    //         //s});
    //     })
    // }

    // Chat GPT Code :
    downloadFile = (fd) => {
        this.props.userActions.download(fd, (error, resp) => {
            if (error) {
                console.log(error);
                return false;
            }

            try {
                const buffer = resp.buffer.data;
                const mimeType = 'image/jpeg';
                const b64 = Buffer.from(buffer).toString('base64');
                let attachmentFiles = this.state.attachmentFiles ? this.state.attachmentFiles : {};
                attachmentFiles[fd] = `data:${mimeType};base64,${b64}`;
                this.setState({ attachmentFiles: attachmentFiles });
            } catch (error) {
                console.log(error);
                return false;
            }
        })
    }
    handleScrollFeed = (event) => {
        let scrollTop = event.target.scrollTop;
        let scrollHeight = event.target.offsetHeight;
        let viewHeight = this.listView.clientHeight;
        if (scrollTop + scrollHeight >= viewHeight) {
            if (this.props.loadMore) this.props.loadMore();
        }

    };

    render() {
        let { data } = this.state.data || this.props;
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        return (
            <div className="col-md-6 col-sm-12">
                <div className="title" style={{marginTop:"25px"}}>
                    Announcements Feed
                </div>

                <div className="wrap-one full_h_1" onScroll={this.handleScrollFeed}>
                    {!data &&
                    <h1 className="announcement-information">Create your first announcement now</h1>
                    }
                    <div ref={e => this.listView = e}>
                        {data && data.map((key, idx) => {
                            let arrLikes = key.likes ? JSON.parse(key.likes) : [];
                            let check = arrLikes.find(x => x === checkLogin.user.id);
                            let txtTime = moment(key.createdAt).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? moment(key.createdAt).fromNow() : null;
                            if (txtTime) {
                                txtTime = txtTime.replace(' minutes', 'm');
                                txtTime = txtTime.replace(' hours', 'h');
                                txtTime = txtTime.replace(' seconds', 's');
                            }
                            if (txtTime === "a fews ago") {
                                txtTime = "a few secs ago"
                            }
                            return (
                                <div className="team-view display_row" key={"item-feed" + idx}>
                                    <div className="feed-information animation-show col-sm-12">
                                        <div className="wrap_box shadow-1">
                                            <div className="info-in display_row">
                                                <div className="img_left">
                                                    <img alt="" className="ava-member"
                                                        src={key.owner && key.owner.avatar ? key.owner.avatar : Config.avaDef} />
                                                    <div className="ann_user_name">
                                                        {key.owner ? key.owner.fullName : ''}
                                                    </div>
                                                </div>
                                                <div className="display_col justify-center full_w text-view right_content">
                                                    <div className="feed-name red">
                                                        <p>{key.title}</p>
                                                        <span className="date-times">{txtTime ? txtTime : moment(key.createdAt).format('MMM DD')}</span>
                                                    </div>
                                                    <div className="feed-para">
                                                        <p>{key.content}</p>
                                                    </div>
                                                </div>
                                                <span className="like">
                                                    <Image src={(key.likes && key.likes.length > 2 && check) ? require("../../assets/images/like.png") : require("../../assets/images/feed/dis-like.png")}
                                                        onClick={() => { (key.likes && key.likes.length > 2 && check) ? this.isFavDisLike(key.id, idx) : this.isFav(key.id, idx) }}
                                                        alt='' />
                                                </span>
                                            </div>
                                            {/* 
                                        Chat GPT Code
                                            {key.attachment && (
                                                <div className="content_image">
                                                    {
                                                    <img src={`data:${mime};base64,${data}`} alt="#" />
                                               }
                                                </div>
                                            )} */}
                                            {key.attachment && (
                                                <div className="content_image">
                                                    {/* <img src={"data:image/png;base64," + key.attachment.replace("data:image/png;base64,", '')} alt="#" /> */}
                                                    <img src={"data:image/png" + key.attachment.replace("data:image/png", '')} alt="#" />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                    {this.state.isLoadMore &&
                        <div className="display_row align-center justify-center" style={{ width: '100%' }}>
                            <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30} />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

class ListFeedRight extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imgAva: null,
            imageProfile: null,
            imageLogo: null,
            updateProfile: false,
            updateLogo: false,
            message: null,
            flagCreate: false
        };
        this.dataFeed = [
            {
                type: 'TEXT',
                key: 'feedTitle',
                id: 'feedTitle',
                feedback: false,
                placeholder: 'Enter Announcement',
                className: 'shadow-1 input_name',
                def_value: '',
                validator: 'isRequired',
                maxLength: 45
            },
            {
                type: 'TEXT_AREA',
                key: 'feedContent',
                id: 'feedContent',
                feedback: false,
                placeholder: 'Enter Announcement Text',
                className: 'shadow-1',
                def_value: '',
                validator: 'isRequired',
                maxLength: 240
            }
        ];
        this.dataFeedDef = [
            {
                type: 'TEXT',
                key: 'feedTitle',
                id: 'feedTitle',
                feedback: false,
                placeholder: 'Enter Announcement',
                className: 'shadow-1 input_name',
                def_value: '',
                validator: 'isName'
            },
            {
                type: 'TEXT_AREA',
                key: 'feedContent',
                id: 'feedContent',
                feedback: false,
                placeholder: 'Enter Announcement Text',
                className: 'shadow-1',
                def_value: '',
                validator: 'isRequired'
            }
        ];
    }

    onUploadImage = (e) => {
        let reader = new FileReader();
        if (e.target.files.length === 0) return null;
        let file = e.target.files[0];

        if (file.size > 1048576) {
            alert('Maximum upload file size 1MB');
            return;
        }

        if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
            alert('Invalid file type. Please upload a JPEG or PNG image.');
            return;
        }

        reader.onloadend = () => {
            this.setState({ imgAva: reader.result });
        };

        reader.readAsDataURL(e.target.files[0]);
    };
    // REFER CODE :

    // onUploadImage = (e) => {
    //     let reader = new FileReader();
    //     if (e.target.files.length === 0) return null;
    //     let file = e.target.files[0];

    //     // Only allow .png and .jpg files
    //     if (!file.type.match('image/png') && !file.type.match('image/jpeg')) {
    //         alert('Only .png and .jpg files are allowed.');
    //         return;
    //     }

    //     if (file.size > 1048576) {
    //         alert('Maximum upload file size 1MB');
    //         return;
    //     }

    //     reader.onloadend = () => {
    //         this.setState({ imgAva: reader.result });
    //     };

    //     reader.readAsDataURL(e.target.files[0]);
    // };

    _onUpdateImage = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const imageData = {
                name: file.name,
                content: reader.result.split(',')[1],
                contentType: file.type
            };
            this.setState({ imageLogo: imageData });
        };
        if (!allowedExtensions.exec(file.name)) {
            alert('Invalid file type. Only JPG, JPEG, and PNG files are allowed.');
            e.target.value = null; // reset the input value
            return false;
        }
        reader.onerror = (error) => {
            console.log('Error: ', error);
        };
    };
    _onUpdateImage1 = (e) => {
        const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        const file = e.target.files[0];
        const fileSize = file.size / 1024 / 1024; // in MB
        const fileType = file.type;

        // Validate file extension
        if (!allowedExtensions.exec(file.name)) {
            alert('Invalid file type. Only JPG, JPEG, and PNG files are allowed.');
            e.target.value = null; // reset the input value
            return false;
        }

        // Validate file size
        if (fileSize > 5) {
            alert('File size exceeds 5MB. Please choose a smaller file.');
            e.target.value = null; // reset the input value
            return false;
        }

        // You can add more validations as needed

        if (this.props.onUpdateImage) this.props.onUpdateImage(e);
    };


    _onGetData = (e) => {
        //Get all data
        // let images = this.props.imageLogo
        let check = this.refs['CForm1'].formValidate();
        let dt = this.refs['CForm1'].formValue();

        if (!check) {
            this.isSubmit = true;
            this.setState({ message: 'Fill out all fields or input correct details to the fields and try again' });
            setTimeout(() => {
                this.setState({ message: '' });
                this.isSubmit = false;
            }, 5000);
        }
        else {
            //Data new
            this.setState({
                flagCreate: true
            });
            let params = {
                title: dt.feedTitle.value,
                content: dt.feedContent.value,
                isFav: false,
                attachment: this.props.imageLogo.src ? this.props.imageLogo.src : "",
            };
            if (this.props.createFeed) this.props.createFeed(params, () => {
                this.setState({
                    flagCreate: true
                }, () => {
                    this.setState({
                        flagCreate: false
                    })
                })
            });
        }
    };

    tickLike = () => {
        if (this.props.tickLike) this.props.tickLike();
    };

    render() {
        return (
            <div className="col-md-6 col-sm-12">
                <div className="right_box full_h_1">
                    {!this.state.flagCreate && <CForm styleForm="form-col" ref='CForm1' data={this.dataFeed} />}
                    {this.state.flagCreate && <CForm styleForm="form-col" ref='CForm1' data={this.dataFeedDef} />}
                    <div className="register_error" style={{ width: '100%', top: -12, height: 35, alignItems: 'center', justifyContent: 'flex-end', lineHeight: '16px', textAlign: 'center' }}>
                            {this.state.message}
                        </div>
                    <div className="upload_form" style={{ position: 'relative' }}>
                        
                        <div className="row w-100" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="col-sm-8">
                                <div className="title titleupload">
                                    <label for="task_image">
                                        <Image src={require('../../assets/images/plus.png')} />
                                    UPLOAD IMAGE
                                    </label>
                                    <input className="upload uploadimg" type="file" id='task_image' accept="image/*" onChange={(e) => { this._onUpdateImage1(e) }} />
                                </div>

                                {/*{this.props.imageLogo && this.props.imageLogo.src*/}
                                {this.props.imageLogo && this.props.imageLogo.content
                                    && (
                                        <div className="imgPreview">
                                            <Image //src={this.props.imageLogo}
                                                src={this.props.imageLogo.content}
                                                className="avatar-image" />
                                            <input className="uploadimg_ava" type="file" accept="image/*" onChange={(e) => { this._onUpdateImage(e) }} />
                                        </div>
                                    )}
                                {this.props.imageLogo === true &&
                                    <div style={{ position: 'absolute', top: 43, width: 197, height: 150, backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: 10 }}
                                        className="display_row align-center justify-center imgPreview">
                                        <ReactLoading style={{ fill: '#31CCBC', width: 25, height: 25 }}
                                            type={"spinningBubbles"} />
                                    </div>
                                }
                            </div>
                            <div className="col-sm-4" style={{ height: 60, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className="display_row align-right button-view">
                                    <button onClick={this._onGetData}
                                        type="button"
                                        className="menu-left-action btn-linear-orange shadow_popup btn btn-default">CREATE NEW
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
    checkLogin: state.main.checkLogin
}),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        announcementActions: bindActionCreators(announcementActions, dispatch),
    })
)(FeedPage);