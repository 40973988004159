import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Image } from "react-bootstrap";
import ReactLoading from "react-loading";

import '../register/register.css'
import '../my-team-detail/my-team-detail.css'
import '../calendar/calendar.css'
import './tasks-page.css'

import CForm from "../libs/cform/cform";
import { browserHistory } from 'react-router';
import moment from "moment";
import Config from "../../config";
import Select from 'react-select';
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as taskActions from "../../redux/task/task_actions";
import ImageLazy from "../imageLazy/imageLazy";
import Header from '../header/header';

class CreateTasksPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: null,
            idTeam: this.props.teamId,
            dataChoose: [],
            uploadFiles: [],
            contents: {},
            formError: false
        };
        this.dataForm1 = [
            {
                type: 'TEXT',
                key: "name",
                id: "name",
                placeholder: "Enter task name",
                feedback: false,
                def_value: '',
                // def_value:'TrangHoang',
                validator: 'isRequired',
                maxLength: 40
            },
            // {
            //     type: 'TEXT',
            //     key: "des",
            //     id: "des",
            //     placeholder:"Short description",
            //     feedback: false,
            //     def_value: '',
            //     // def_value:'TrangHoang',
            //     validator: 'isRequired',
            //     maxLength: 150
            // },
            {
                type: 'DATETIME',
                key: "end",
                id: "end",
                placeholder: "Completion date",
                feedback: false,
                alignPopover: 'top',
                def_value: moment().valueOf(),
                // def_value:'TrangHoang',
                validator: 'isRequired',
            },
        ];
        this.vlNote = null;
        this.inputImage = null;
        this.isSubmit = false;
        this.checkTimeError = this.checkTimeError.bind(this)
    }

    componentDidMount() {
        this._getMemberTeamList();
    };
    checkTimeError(state) {
        this.setState({ formError: state })
    }

    setDataListTeam = (data, id) => {
        this.setState({
            idTeam: this.props.teamId ? this.props.teamId : (id ? id : null)
        }, () => {
            this._getMemberTeamList();
            // this.getEvent();
        });
    };

    _getMemberTeamList = () => {
        if (!this.props.teamId) {
            browserHistory.push('/tasks');
            return;
        }
        this.props.teamActions.getMemberTeamList({ team: this.props.teamId }, (err, data) => {
            if (err) {
                Config.popup.show('INFO', err.message);
            }
            else {
                // that._goScreenTeamDetai();
            }
        });
    };

    changeDropDownTeam = (data) => {
        this.setState({
            idTeam: data.id,
            dataChoose: []
        });

        let hasValue = document.getElementsByClassName('select__value-container--has-value') ? document.getElementsByClassName('select__value-container--has-value')[0] : null;
        while (hasValue && hasValue.firstChild) hasValue.removeChild(hasValue.firstChild);
    };

    onChangeSelect = (e, idx) => {
        let { memberList } = this.props;
        let dataChoose = [];
        for (let eChild in e) {
            let dataTeam = memberList.filter(i => i.id === e[eChild].value);
            dataChoose = dataChoose.concat(dataTeam);
        }
        this.setState({
            dataChoose: dataChoose
        })
    };

    ValueContainer = ({ innerProps, selectProps, data }) => {
        let dataChoose = this.state.dataChoose.filter(i => i.id === data.value);
        dataChoose = dataChoose[0] ? dataChoose[0] : null;
        if (!dataChoose || dataChoose.length <= 0) return null;
        return (
            <div className="staff-view">
                <Image className="avatar-staff" src={(dataChoose.user.avatar) ? dataChoose.user.avatar : Config.avaDef} style={{ width: '100%', height: '100%' }} />
                <span className="name-staff">{dataChoose.name}</span>
            </div>
        )
    };

    onUploadImage = (e) => {
        const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        const file = e.target.files[0];
        const fileSize = file.size / 1024 / 1024; // in MB
        const fileType = file.type;

        // Validate file extension
        if (!allowedExtensions.exec(file.name)) {
            alert('Invalid file type. Only JPG, JPEG, and PNG files are allowed.');
            e.target.value = null; // reset the input value
            return false;
        }
        if (e.target.files.length === 0) return null;
        let files = e.target.files[0];
        let reader = new FileReader();
        if (files.size > 1048576) {
            alert('Maximum upload file size 1Mb');
            return;
        }

        reader.onloadend = async () => {
            // Upload to the server
            if (this.state.uploadFiles.length > 6) {
                this.setState({ messageTask: 'You have reached the maximum number of Attachments that can be uploaded.' });
                setTimeout(() => {
                    this.setState({ messageTask: '' });
                    this.isSubmit = false;
                }, 5000);
                return false
            }
            let dataTemp = [...this.state.uploadFiles];
            //dataTemp.push(files.name+'---loading');
            dataTemp.push({ fd: files.name, isLoading: true });
            this.setState({ uploadFiles: dataTemp });
            await this.props.userActions.upload(files, (error, resp) => {
                // if(resp && resp[0] && resp[0].path) {
                //     const idx = dataTemp.indexOf(files.name+'---loading');
                //     dataTemp[idx] = resp[0].path;
                //     this.setState({uploadFiles: dataTemp});
                // }
                if (error) {
                    console.log("upload error")
                }
                else {
                    console.log("===========>2", resp)
                }
                if (resp && resp.path) {
                    console.log("===========>2")
                    //this.setState({imageLogo: {file: resp[0], src : resp[0].path}, updateLogo: false});

                    // 1. start
                    //const idx = dataTemp.indexOf(files.name+'---loading');
                    //dataTemp[idx] = resp[0].fd ;
                    //let contents = this.state.contents;
                    //contents[idx] = reader.result;
                    //this.setState({uploadFiles: dataTemp});
                    // 1. end

                    //this.setState({ uploadFiles: { resp: resp, content: files } });



                    // -----------------------

                    //const idx = dataTemp.indexOf(files.name+'---loading');
                    console.log(dataTemp, files)
                    const idx = dataTemp.findIndex((obj =>
                        obj.fd === files.name
                    ));
                    let image = "data:image/jpeg;base64," + resp.path;
                    dataTemp[idx] = { fd: resp.files[0].fd, src: image, isLoading: false };
                    //let contents = this.state.contents;
                    //contents[idx] = reader.result;

                    this.setState({ uploadFiles: dataTemp });
                }
            });
        };

        reader.readAsDataURL(files);
    };

    createTask = () => {
        if (this.isSubmit) return;
        this.isSubmit = true;
        let check1 = this.refs['CForm'].formValidate();
        let dt1 = this.refs['CForm'].formValue();

        if (!check1) {
            this.setState({ messageTask: 'Fill out all fields or input correct details to the fields and try again' });
            setTimeout(() => {
                this.setState({ messageTask: '' });
                this.isSubmit = false;

            }, 5000);
            return;
        }

        if (this.state.dataChoose.length <= 0) {
            this.setState({ messageTask: '* Please select at least one staff' });
            setTimeout(() => {
                this.setState({ messageTask: '' });
                this.isSubmit = false;
            }, 5000);
            return;
        }

        let params = {
            name: dt1.name.value,
            //description: dt1.des.value,
            deadline: moment(dt1.end.value).toISOString(),
            assignTo: JSON.stringify(this.state.dataChoose),
            team: this.state.idTeam
        };

        if (this.vlNote) {
            params.note = this.vlNote;
        }
        if (this.state.uploadFiles.length >= 0) {
            params.attachments = JSON.stringify(this.state.uploadFiles);
        }

        this.props.taskActions.addTask(params, (err, data) => {
            this.isSubmit = false;
            if (data) {
                // this.getEvent();
                browserHistory.push('/tasks')
            }
            // cb && cb(err, data);
        });
    };

    _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    }

    renderIcon(key) {
        //if(file.indexOf('---loading') !== -1){
        let file = key.fd;
        let src = key.src;
        let isLoading = key.isLoading;
        if (isLoading) {
            return (
                <div className="display_row align-center justify-center" style={{
                    width: '100%',
                    borderRadius: 25,
                    padding: 10,
                    position: 'absolute',
                    height: '100%'
                }}>
                    <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30} />
                </div>
            );
        }
        else {
            let ext = file.split('.');
            ext = ext[ext.length - 1];
            ext = ext.toLowerCase();
            switch (ext) {
                case 'doc':
                    return (<Image src={require("../../assets/images/file/icon-doc.png")} style={{ backgroundColor: 'transparent' }} className="avatar-image" />);
                case 'docx':
                    return (<Image src={require("../../assets/images/file/icon-docx.png")} style={{ backgroundColor: 'transparent' }} className="avatar-image" />);

                case 'xls':
                    return (<Image src={require("../../assets/images/file/icon-xls.png")} style={{ backgroundColor: 'transparent' }} className="avatar-image" />);
                case 'xlsx':
                    return (<Image src={require("../../assets/images/file/icon-xlsx.png")} style={{ backgroundColor: 'transparent' }} className="avatar-image" />);

                case 'ppt':
                    return (<Image src={require("../../assets/images/file/icon-ppt.png")} style={{ backgroundColor: 'transparent' }} className="avatar-image" />);
                case 'pptx':
                    return (<Image src={require("../../assets/images/file/icon-pptx.png")} style={{ backgroundColor: 'transparent' }} className="avatar-image" />);

                case 'pdf':
                    return (<Image src={require("../../assets/images/file/icon-pdf.png")} style={{ backgroundColor: 'transparent' }} className="avatar-image" />);

                case 'txt':
                    return (<Image src={require("../../assets/images/file/icon-txt.png")} style={{ backgroundColor: 'transparent' }} className="avatar-image" />);

                default:
                    return (<ImageLazy src={src} retry={4} style={{ backgroundColor: 'transparent' }} className="avatar-image" />);
            }
        }

    }

    removeAttach = (idx) => {
        this.inputImage.value = '';
        let dataTemp = this.state.uploadFiles.slice(0);
        dataTemp.splice(idx, 1);
        this.setState({
            uploadFiles: dataTemp
        })
    };

    

    render() {
        let { memberList } = this.props;
        let dataOption = [];
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        if (memberList) {
            memberList.filter(i => { if (checkLogin.user.id !== i.user.id && i.status === 'ACTIVE' && i.role !== "OWNER") { dataOption.push({ value: i.id, label: i.name }) } return true; })
        }

        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;

        return (
            
            <div className="my-team my-team-detail create-task create_task_container row col-sm-12 full_h_1">
                <Header isSearch={false} type={1} login={login} onLogout={this._onLogout}/>

                <div className=" my-team-detail create-task row col-sm-12 full_h_1 body_container">
                    <div style={{ position: 'relative' }} className="col-sm-12 full_w display_col padding-0 full_h_1">
                        {this.state.idTeam && <div className="member-view display_col " style={{ paddingBottom: 0 }}>
                            <div className='title-section d-flex'>
                                <div className="display_row align-center back-to-team"
                                    style={{ marginTop: 25 }}
                                    onClick={() => {
                                        browserHistory.push('/tasks')
                                    }}><img
                                        src={require('../../assets/images/roster/icon-back-def.png')} alt={""} />Back to
                                    Task List
                                </div>
                                <div className="txt-condensed-extra-bold full_w text-center task-name" style={{
                                    fontSize: 24,
                                    lineHeight: '30px',
                                    marginBottom: 40,
                                    width: '100%',
                                    textAlign: 'left',
                                    marginTop:'25px'
                                }}>CREATE TASK
                                </div>
                            </div>
                            <div className="display_row col-sm-12 align-between flex_wrap scroll full_h_1" style={{marginTop:"25px"}}>
                                <div className="form-column task-detail">
                                    <div className="txt-condensed-extra-bold full_w text-center" style={{
                                        fontSize: 19,
                                        lineHeight: '27px',
                                        marginBottom: 32,
                                        width: '100%',
                                        textAlign: 'left'
                                    }}>TASK DETAILS
                                    </div>
                                    <CForm checkTimeError={this.checkTimeError} isTaskView={true} data={this.dataForm1} ref="CForm"
                                        styleForm="col-sm-12 form-create-task" />
                                </div>
                                <div className="form-column">
                                    <div className="txt-condensed-extra-bold full_w text-center" style={{
                                        fontSize: 19,
                                        lineHeight: '27px',
                                        marginBottom: 20,
                                        width: '100%',
                                        textAlign: 'left'
                                    }}>INSTRUCTIONS
                                    </div>
                                    <div className="txt-note-task col-sm-12 task-description" rows="10" cols="100">
                                        <textarea placeholder="Type here" className="background-grey instruction-text-area" style={{ "height": "100%", "width": "100%", "padding": "15px" }} onChange={(e) => {
                                            this.vlNote = e.target.value
                                        }} />
                                    </div>
                                </div>
                                <div className="form-column assign-staff">
                                    <div className="txt-condensed-extra-bold full_w text-center" style={{
                                        fontSize: 19,
                                        lineHeight: '27px',
                                        marginBottom: 42,
                                        width: '100%',
                                        textAlign: 'left'
                                    }}>ASSIGN STAFF
                                    </div>
                                    <div className="drop-staff" >
                                        <Select
                                            options={dataOption}
                                            components={{ MultiValueLabel: this.ValueContainer }}
                                            placeholder='Staff Name'
                                            isMulti={true}
                                            className="basic-multi-select staff-select"
                                            classNamePrefix="select"
                                            closeMenuOnSelect={false}
                                            onChange={this.onChangeSelect}
                                            styles={{
                                                control: (base, state) => ({
                                                    ...base,
                                                    background: "#F4F6F6",
                                                    // match with the menu
                                                    //borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
                                                    // Overwrittes the different states of border
                                                    borderColor: "#F4F6F6",
                                                    // Removes weird border around container
                                                    //boxShadow: state.isFocused ? null : null,
                                                    "&:hover": {
                                                        // Overwrittes the different states of border
                                                        borderColor: "#F4F6F6"
                                                    },
                                                    border: 0
                                                }),
                                                menu: base => ({
                                                    ...base,
                                                    // override border radius to match the box
                                                    borderRadius: 0,
                                                    // kill the gap
                                                    marginTop: 0
                                                }),
                                                menuList: base => ({
                                                    ...base,
                                                    // kill the white space on first and last option
                                                    padding: 0
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-column attachment">
                                    <div className="txt-condensed-extra-bold full_w text-center" style={{
                                        fontSize: 19,
                                        lineHeight: '27px',
                                        marginBottom: 44,
                                        width: '100%',
                                        textAlign: 'left'
                                    }}>ATTACHMENTS
                                    </div>
                                    <div className="col-sm-8 padding-0 attach display_row flex_wrap full_w">
                                        <div className="title imgPreview">
                                            <Image //src={require('../../assets/images/task/icon-add-image.png')}
                                                src={require('../../assets/images/upload-img.png')} className='upload-img'
                                                style={{ objectFit: 'contain' }} />
                                            <input className="upload uploadimg"
                                                accept="image/*"
                                                type="file"
                                                ref={el => this.inputImage = el}
                                                // multiple
                                                onChange={this.onUploadImage} />
                                        </div>
                                        {this.state.uploadFiles && this.state.uploadFiles.length > 0 && this.state.uploadFiles.map((key, idx) => {
                                            //let item = this.renderIcon(key, this.state.contents[idx]);
                                            //let item = this.renderIcon(key.fd, key.src);
                                            let item = this.renderIcon(key);
                                            return (
                                                <div className="imgPreview" key={"taskImage" + idx}>
                                                    {item}
                                                    {/*{key.indexOf('---loading') <0 && <i className="fa fa-times" onClick={()=>this.removeAttach(idx)}/>}*/}
                                                    {key.fd.indexOf('---loading') < 0 && <i className="fa fa-times" onClick={() => this.removeAttach(idx)} />}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <p className="text-center drag" style={{ "color": "#C4C4C4" }}>Drag and drop here</p>
                                </div>


                                <div className="display_row align-right button-view">
                                    {this.state.messageTask !== '' && (
                                        <div className="register_error"
                                            style={{
                                                width: '100%',
                                                bottom: 65,
                                                height: 40,
                                                textAlign: 'right',
                                                alignItems: 'flex-end',
                                                justifyContent: 'center'
                                            }}>
                                            {this.state.messageTask}
                                        </div>
                                    )}
                                    <button type="button"
                                        disabled={this.state.formError}
                                        onClick={this.createTask}
                                        className="menu-left-action btn-linear shadow_popup btn btn-default btn-add-task" style={{borderRadius:"25px"}}>
                                        CONFIRM AND SEND NOTIFICATION
                                    </button>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }

}

export default connect(state => ({
    checkLogin: state.main.checkLogin,
    teamList: state.team.teamList,
    memberList: state.team.memberList,
    teamId: state.team.teamId
}),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        taskActions: bindActionCreators(taskActions, dispatch),
    })
)(CreateTasksPage);