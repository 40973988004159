import {types} from "./message_actions";

const initialState = {
    messageList: null,
    messageGroupList:null,
    messageSearch: null,
    messageNew: null,
    messageRoom: null,
    team_pending:false
};

//Nhan action type tu Put va luu data vao store
export default function (state = initialState, action = {}) {
    switch (action.type){
        case types.TEAM_PENDING:
            return{
                ...state,
                team_pending:true
            };
        case types.MESSAGE_LIST+'_SUCCESS':
            return{
                ...state,
                messageList: action.payload,
                team_pending:false
            };
        case types.MESSAGE_GROUP_LIST+'_SUCCESS':
            return{
                ...state,
                messageGroupList: action.payload,
                team_pending:false
            };
        case types.MESSAGE_SEARCH+'_SUCCESS':
            return{
                ...state,
                messageSearch: action.payload,
                team_pending:false
            };
        case types.MESSAGE_NEW+'_SUCCESS':
            return{
                ...state,
                messageNew: action.payload,
                team_pending:false
            };
        case types.MESSAGE_ROOM+'_SUCCESS':
            return{
                ...state,
                messageRoom: action.payload,
                team_pending:false
            };

        default:
            return state;
    }
}