import React,{Component} from 'react';
import '../t&c/t&c.css';


export default class Help extends Component{

    render(){
        let data = [
            {
                text: 'Clanwilliam Court, Block 1, Lower Mount Street, Dublin 2',
                icon: require('../../assets/images/contact/addressicon.png')
            },
            {
                text: 'info@teemieapp.com',
                icon: require('../../assets/images/contact/email.png')
            },
            {
                text: '+353 1 551 1825',
                icon: require('../../assets/images/contact/phone.png')
            }
        ];
        return(
            <div className="full_w full_h scroll">
                <div className="register row terms" style={{minHeight:'100%'}}>
                    <div className="col-sm-12" style={{padding:'0 60px'}}>
                        <div className="txt-condensed-extra-bold full_w text-center" style={{
                            fontSize: 24,
                            lineHeight: '30px',
                            marginBottom: 40,
                            width: '100%',
                            textAlign: 'left'
                        }}>CONTACT
                        </div>
                        <div className="title-small">
                            {data.map((key, idx)=>{
                                return(
                                    <div className="display_row row-hep">
                                        <img alt="" className="icon-help" src={key.icon} />
                                        {key.text}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}