// action types
export const types = {
    SHIFT_LIST: 'SHIFT_LIST',
    SHIFT_LIST_SUCCESS: 'SHIFT_LIST_SUCCESS',
    SHIFT_ADD: 'SHIFT_ADD',
    SHIFT_ADD_SUCCESS: 'SHIFT_ADD_SUCCESS',
    SHIFT_DELETE: 'SHIFT_DELETE',
    SHIFT_EDIT: 'SHIFT_EDIT',
    SHIFT_EDIT_SUCCESS: 'SHIFT_EDIT_SUCCESS',
};

export function getListShift(params,cb) {
    return{
        type: types.SHIFT_LIST,
        params,
        cb
    }
}

export function addShift(params,cb) {
    return{
        type: types.SHIFT_ADD,
        params,
        cb
    }
}

export function removeShift(params,cb) {
    return{
        type: types.SHIFT_DELETE,
        params,
        cb
    }
}

export function editShift(params,cb) {
    return{
        type: types.SHIFT_EDIT,
        params,
        cb
    }
}
