
import React,{Component} from 'react';
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import '../register/register.css'
import {Button, Image} from 'react-bootstrap';
import ImageCrop from '../libs/drop-image1/drop-image';
import Header from "../header/header";

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import Config from "../../config";
import ReactLoading from "react-loading";

class OnBoardingPage extends Component{

    constructor(props){
        super(props);
        this.state = {
            imgAva: null,
            imageProfile:null,
            imageLogo:null,
            updateProfile: false,
            updateLogo: false,
            imageSelectedProfile: false,
            imageSelectedLogo: false,
        };
        this.vlType=null;
        this.isSaveLogo=false;
        this.isSaveAva=false;
    }
    componentDidMount = () => {
        // let checkLogin = localStorage.getItem('LOGIN');
        // checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        // console.log("checkLogincheckLogincheckLogin");
        // console.log(checkLogin);
    };

    onNext = () => {
        if(!this.isSaveLogo){
            this.setState({errNext: Config.alertMess({code:'SAVELOGO', message:''})});
            return;
        }
        if(!this.isSaveAva){
            this.setState({errNext: Config.alertMess({code:'SAVEAVA', message:''})});
            return;
        }
        window.location.href = "/on-boarding-three";
    };

    onUploadImage = (e) => {
        let reader = new FileReader();
        if(e.target.files.length===0) return null;
        let file = e.target.files[0];

        if(file.size > 1048576){
            alert('Maximum upload file size 1MB');
            return;
        }

        reader.onloadend = ()=> {
            this.setState({imgAva : reader.result });            
            if(this.state.imageSelectedProfile === true){
                this.setState({ imageSelectedProfile: true});
            }  
            if(this.state.imageSelectedLogo === true){
                this.setState({ imageSelectedLogo :true});
            }          
            };
        reader.readAsDataURL(e.target.files[0]);
    };

    _uploadAva = (e) => {
        if(this.vlType === 1){
            this.setState({imageProfile: true, updateProfile: false});
        }
        else if(this.vlType===2){
            this.setState({imageLogo: true, updateLogo: false});
        }

        let base64Img = null;
        let reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onloadend = () => {
            base64Img = reader.result;
        if(this.vlType === 1){
            this.setState({imageProfile: {file: base64Img, src : base64Img}, updateProfile: false},()=>{
                this.saveProfile(base64Img);
            });
        }
        else if(this.vlType===2){
            this.setState({imageLogo: {file: base64Img, src : base64Img}, updateLogo: false},()=>{
                this.saveLogo(base64Img);
            });
        }
        this.vlType = null;

        this.setState({imgCrop: null})
        }
    };

    saveLogo = (logo) => {
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        // let logo = this.vlImageLogo;
        if(!checkLogin || !checkLogin.company || !checkLogin.company.id) return;
        if(!logo){
            this.setState({errLogo: Config.alertMess({code:'MSA003', message:''})});
            return;
        }
        let params = {
            id: checkLogin.company.id,
            logo: logo,

        };
        this.props.companyActions.editCompany(params, (err, data) => {
            if(err){
                this.setState({errLogo: Config.alertMess(err)});
            }
            else{
                checkLogin.company = data;
                localStorage.setItem('LOGIN', JSON.stringify(checkLogin));
                this.vlImageLogo = null;
                this.setState({
                    updateLogo:true,
                    errLogo: 'Update success'
                });
                this.isSaveLogo = true;
            }
        });
    };

    saveProfile = (logo) => {
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        // let logo = this.vlImageProfile;
        if(!checkLogin || !checkLogin.user || !checkLogin.user.id) return;
        if(!logo){
            this.setState({errProfile: Config.alertMess({code:'MSA002', message:''})});
            return;
        }
        let params = {
            id: checkLogin.user.id,
            avatar: logo
        };
        this.props.userActions.editUser(params, (err, data) => {
            if(err){
                this.setState({errProfile: Config.alertMess(err)});
            }
            else{
                checkLogin.user = data[0];
                localStorage.setItem('LOGIN', JSON.stringify(checkLogin));
                this.vlImageProfile = null;
                this.setState({
                    updateProfile:true,
                    errProfile: 'Update success'
                });
                this.isSaveAva = true;
            }
        });

    };

    render(){
        return(
            <div className="full_w full_h onboarding-2">
                <Header type={0} login={null}/>
                <div className="register row" style={{marginTop:100}}>
                    <div className="col-sm-12" style={{padding:'0 60px'}}>
                        <div className="txt-condensed-extra-bold"
                             style={{marginBottom:47}}>
                            STEP ONE - PHOTO UPLOAD
                        </div>
                        <div className="display_row justify-center" style={{marginBottom:71}}>
                            <div className="col-sm-6" style={{margin:'0 40px', border:'none'}}>
                                <p className="note-register text-center"
                                   style={{margin:'0 0 15px 0', fontSize: 20}}>Admin Profile Pic</p>
                                <Image src={(this.state.imageProfile && this.state.imageProfile.src) ? this.state.imageProfile.src : require('../../assets/images/photo-upload/img-ava-def.png')} className="avatar-image"/>
                                {this.state.imageProfile && !this.state.imageProfile.src &&
                                    <div style={{position:'absolute',left: 100, top:43, width: 198, height: 198}} className="display_row align-center justify-center">
                                        <ReactLoading style={{fill:'#31CCBC', width: 25, height: 25}}
                                                      type={"spinningBubbles"}/>
                                    </div>
                                }
                                <div className="btn-choose-file">
                                    Choose File
                                    <input type="file" accept="image/*"
                                           ref="vlPhoto"
                                           onClick={()=>{this.refs.vlPhoto.value="",this.state.imageSelectedProfile=true}}
                                           onChange={(e)=>{this.vlType = 1; this.onUploadImage(e); return false;}}/>
                                </div>
                                <div className="reg-error">
                                    {this.state.errProfile && (
                                        <div className="register_error" style={{width:'100%', bottom:-40}}>
                                            {this.state.errProfile}
                                        </div>
                                    )}
                                </div>
                              <Button className="login_btn_login btn-linear shadow"
                                        style={{width: 138, height: 48}} onClick={this.saveProfile} disabled={!this.state.imageSelectedProfile || this.state.updateProfile}>{this.state.updateProfile ? "UPDATED" : "SAVE"}</Button>
                            </div>
                            <div className="col-sm-6" style={{margin:'0 40px'}}>
                                <p className="note-register text-center"
                                   style={{margin:'0 0 15px 0', fontSize: 20}}>Company Logo</p>
                                <Image src={(this.state.imageLogo && this.state.imageLogo.src) ? this.state.imageLogo.src : require('../../assets/images/photo-upload/img-ava-def.png')} className="avatar-image"/>
                                {this.state.imageLogo && !this.state.imageLogo.src &&
                                <div style={{position:'absolute',left: 100, top:43, width: 198, height: 198}} className="display_row align-center justify-center">
                                    <ReactLoading style={{fill:'#31CCBC', width: 25, height: 25}}
                                                  type={"spinningBubbles"}/>
                                </div>
                                }
                                <div className="btn-choose-file">
                                    Choose File
                                    <input type="file" accept="image/*"
                                           ref="vlLogo"
                                           onClick={()=>{this.refs.vlLogo.value="",this.state.imageSelectedLogo=true}}
                                           onChange={(e)=>{this.vlType = 2; this.onUploadImage(e); return false;}}/>                                    
                                </div>
                                <div className="reg-error">
                                    {this.state.errLogo && (
                                        <div className="register_error" style={{width:'100%', bottom:-40}}>
                                            {this.state.errLogo}
                                        </div>
                                    )}
                                </div>
                               <Button className="login_btn_login btn-linear shadow"
                                        style={{width: 137, height: 48}} onClick={this.saveLogo} disabled={!this.state.imageSelectedLogo || this.state.updateLogo}>{this.state.updateLogo ? "UPDATED" : "SAVE"}</Button>
                            </div>
                        </div>
                        <p className="note-register text-center"
                           style={{margin:'0', fontSize: 22}}>
                            Begin by uploading both your personal profile photo as the company admin and the company logo so that they will look good on the app itself. If you are building multiple teams and have multiple admins, then it will be their photo that will be on the app as opposed to yours.
                        </p>

                        
                        {this.state.errNext && (
                            <div className="register_error" style={{width:'calc(100% - 120px)', bottom:50}}>
                                {this.state.errNext}
                            </div>
                        )}
                        { this.state.updateProfile && this.state.updateLogo && 
                       <Button className="next-btn btn-linear shadow"
                       style={{width: 240, height: 56, marginTop: 57, marginBottom:150}} onClick={this.onNext}>NEXT</Button>}
                    </div>
                </div>

                {this.state.imgAva && <ImageCrop data={this.state.imgAva}
                                                 size="squares"
                                                 show={this.state.imgAva !== null}
                                                 onCrop={(e)=>{this._uploadAva(e);}}
                                                 onHide={() => {this.setState({imgAva: null})}}/>}
            </div>
        )
    }
}
export default connect(state => ({
        checkLogin : state.main.checkLogin
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
    })
)(OnBoardingPage);
