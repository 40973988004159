import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./message_actions";
import Api from '../../services/api';
// import error from '../../../components/CError.js';

export function* loadMessage(data) {
    try {
        yield delay(300);
        let message = yield Api.put('/message/load', data.params);
        if(message && message.data){
            yield put({type: types.MESSAGE_LIST + '_SUCCESS', payload: message.data});
            data.cb && data.cb(null, message.data);
            return;
        }
        else{
            yield put({type: types.MESSAGE_LIST + '_SUCCESS', payload: null});
            data.cb && data.cb(message, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchLoadMessage() {
    while (true){
        const watcher = yield takeLatest(types.MESSAGE_LIST,loadMessage);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* loadGroupMessage(data) {
    try {
        yield delay(300);
        let message = yield Api.put('/message/group', data.params);
        if(message && message.data){
            yield put({type: types.MESSAGE_GROUP_LIST + '_SUCCESS', payload: message.data});
            data.cb && data.cb(null, message.data);
            return;
        }
        else{
            yield put({type: types.MESSAGE_GROUP_LIST + '_SUCCESS', payload: null});
            data.cb && data.cb(message, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchLoadGroupMessage() {
    while (true){
        const watcher = yield takeLatest(types.MESSAGE_GROUP_LIST,loadGroupMessage);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* searchMessage(data) {
    try {
        yield delay(300);
        let message = yield Api.put('/message/search', data.params);
        if(message && message.data){
            yield put({type: types.MESSAGE_SEARCH + '_SUCCESS', payload: message.data});
            data.cb && data.cb(null, message.data);
            return;
        }
        else{
            yield put({type: types.MESSAGE_SEARCH + '_SUCCESS', payload: null});
            data.cb && data.cb(message, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchSearchMessage() {
    while (true){
        const watcher = yield takeLatest(types.MESSAGE_SEARCH,searchMessage);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* newMessage(data) {
    try {
        yield delay(300);
            yield put({type: types.MESSAGE_NEW + '_SUCCESS', payload: data.params});
            data.cb && data.cb(null, data.params);
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchNewMessage() {
    while (true){
        const watcher = yield takeLatest(types.MESSAGE_NEW,newMessage);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
export function* roomMessage(data) {
    try {
        yield delay(300);
            yield put({type: types.MESSAGE_ROOM + '_SUCCESS', payload: data.params});
            data.cb && data.cb(null, data.params);
    }
    catch (e) {
        data.cb && data.cb(null,null);
    }
}

export function* watchRoomMessage() {
    while (true){
        const watcher = yield takeLatest(types.MESSAGE_ROOM,roomMessage);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}
