/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/components/popup/loading.js
 */

import React, {Component} from 'react';
import { Modal} from 'react-bootstrap';
import './popup.css';

import PopupInfo from './popup-info.js';
import PopupYesNo from './popup-yes-no.js';

class Popup extends Component{

    _content = {
        INFO: PopupInfo,
        YES_NO: PopupYesNo
    };
    
    constructor(props){
        super(props);
        this.state={
            show: false,
            name: '',
            data: ''
        }
    }
    
    _onHide(){
        this.setState({show: false});
    }
    
    show(name,data,cbConfirm,cbCancel){
        this.setState({
            show: true,
            name: name,
            data: data,
            cbConfirm: ()=>{
                this.setState({show: false});
                cbConfirm && cbConfirm();
            },
            cbCancel: ()=>{
                this.setState({show: false});
                cbCancel && cbCancel();
            }
        });
    }

    render(){
        return(
            <Modal show={this.state.show}
                   bsSize="small"
                   onHide={this._onHide.bind(this)}>
                {this.state.name!=='' &&
                    React.createElement(
                        this._content[this.state.name],
                        {
                            data: this.state.data,
                            cbConfirm: this.state.cbConfirm,
                            cbCancel: this.state.cbCancel
                        }
                    )
                }
            </Modal>
        )
    }
}


export default Popup;