
import React,{Component} from 'react';
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as memberActions from "../../redux/member/member_actions";
import '../register/register.css'
import './staff-calendar.css'

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

class StaffCalendar extends Component{

    constructor(props){
        super(props);
        this.state = {
            tab: false,
            week:0,
            month:0,
            arrDay:null,
            arrDayMonth:null,
        }
    }

    componentDidMount() {
        this.getArrDay();
        this.getDaysArrayByMonth();
    }

    getArrDay = (number) => {
        let week = this.state.week;
        let d = moment().startOf('week');
        let arr = [];
        d.add((week*7), 'days');
        let month = parseInt(moment().format('MM'),10);
        for(let i=1; i<=7; i++){
            let day = d.add(1,'days');
            let monthA = parseInt(day.format('MM'),10);
            if(monthA === month){
                arr.push(day.format());
            }
        }
        if(arr.length <= 0 && number){
            this.setState({
                week: parseInt(this.state.week,10) - number
            });
            return;
        }
        this.setState({
            arrDay: arr
        })
    };

    changeWeek = (number) => {
        this.setState({
            week: parseInt(this.state.week,10) + number
        },()=>{
            this.getArrDay(number);
        });
    };

    generateTime = (total)=>{
        let hourWithMin = total.toString().split('.');
        let minites=hourWithMin[1]!=undefined?hourWithMin[1].toString().padStart(2, '0'):'00'
        let hour=hourWithMin[0]!=undefined?hourWithMin[0].toString().padStart(2, '0'):'00'
        let calculatedTime=parseFloat(hour+'.'+minites).toFixed(2);
        return calculatedTime;
    };

    getTotalTime = (total) => {
        // let hourWithMin = total.toString().split('.');
        // let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
        // let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');

        // let quotient = Math.floor(calminutes / 60);
        // console.log(';quotient;;;;;',quotient);
        // let remainder = calminutes % 60;
        
        // let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
        // let totalMinutes = remainder.toString().padStart(2, '0');
        
        // let returntotalMin=totalHours+ "." +totalMinutes;

        let totalCalculatedMinutes=total*60;
        return this.timeConvertToMintues(totalCalculatedMinutes);
        
    };

    timeConvertToMintues(n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours).toString().padStart(2, '0');
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes).toString().padStart(2, '0');
        return rhours + ":" + rminutes;
        };

    changeMonth = (number) => {
        let monthCurrent = parseInt(moment(this.state.arrDayMonth[0]).format('MM'),10);
        if((monthCurrent >= 12 && number>0) || (monthCurrent <=1 && number<0)) return;
        this.setState({
            month: parseInt(this.state.month,10) + number
        },()=>{
            this.getDaysArrayByMonth();
        });
    };

    getDaysArrayByMonth = (number) => {
        let daysInMonth = moment().endOf('year').format();
        let year = moment(daysInMonth).format('YY');
        let arrDayMonth = [];
        while(year === moment(daysInMonth).format('YY')) {
            arrDayMonth.unshift(moment(daysInMonth).format());
            daysInMonth = moment(daysInMonth).add(-1, 'months');
        }
        this.setState({
            arrDayMonth: arrDayMonth
        })

    };

    render(){
        let {datRoster,memberInfo} = this.props;
        return(
            <div className="staff-calendar display_col calendar">
                <div className="display_row align-between calendar-bar full_w" style={{marginTop:70}}>
                    <div className="display_row align-between time-tab" style={{width:'100%',maxWidth: 130}}>
                        <span className={!this.state.tab?'active':''} onClick={()=>{this.setState({tab: this.state.tab ? !this.state.tab : false})}}>WEEK</span>
                        <span className={this.state.tab?'active':''} onClick={()=>{this.setState({tab: !this.state.tab ? !this.state.tab : true})}}>MONTH</span>
                    </div>
                    {(!this.state.tab|| this.state.tab===0) && this.state.arrDay  && (this.state.week || this.state.week===0) &&
                        <div className="time-scroll align-between justify-center display_row">
                            <i className="fa fa-angle-left" onClick={()=>this.changeWeek(-1)}/>
                            {(moment(this.state.arrDay[0]).format('MMM Do')+'-'+ moment(this.state.arrDay[this.state.arrDay.length-1]).format('MMM Do')).toUpperCase()}
                            <i className="fa fa-angle-right" onClick={()=>this.changeWeek(1)}/>
                        </div>
                    }
                </div>
                {(!this.state.tab|| this.state.tab===0) && (this.state.week || this.state.week===0) && this.state.arrDay && (
                    <div className="week-view display_row">
                        {this.state.arrDay && (this.state.week || this.state.week===0) && this.state.arrDay.map((key, idx)=>{
                            let hours = 0;
                            for(let i in datRoster){
                                if(moment(datRoster[i].time).format('DD MM YYYY') === moment(key).format('DD MM YYYY') && datRoster[i].staff && memberInfo && memberInfo.user && datRoster[i].staff.id === memberInfo.user.id){
                                    // hours+= (datRoster[i].timeEnd - datRoster[i].timeStart);
                                    hours+= datRoster[i].timeEnd >= datRoster[i].timeStart ? (datRoster[i].timeEnd - datRoster[i].timeStart) : (24-datRoster[i].timeStart+datRoster[i].timeEnd);
                                }
                            }
                            return(
                                <div key={"item-day"+idx}  className="shadow day-item display_col align-center justify-center">
                                    <span className="txt-day">{moment(key).format('ddd').toUpperCase()}</span>
                                    {/*<span className="txt-day">{moment(key).format('Do').toUpperCase()}</span>*/}
                                    <span className="txt-hours">{this.getTotalTime(this.generateTime(hours))}</span>
                                    <span className="txt-unit">HRS</span>
                                </div>
                            )
                        })}
                    </div>
                )}
                {this.state.tab && this.state.arrDayMonth && (this.state.month || this.state.month===0) && (
                    <div className="month-view display_row" >
                        {this.state.arrDayMonth && (this.state.month || this.state.month===0) && this.state.arrDayMonth.map((key, idx)=>{
                            let hours = 0;
                            for(let i in datRoster){
                                if(moment(datRoster[i].time).format('MM YYYY') === moment(key).format('MM YYYY') && datRoster[i].staff && datRoster[i].staff.id === memberInfo.user.id){
                                    // hours+= (datRoster[i].timeEnd - datRoster[i].timeStart);
                                    hours+= datRoster[i].timeEnd >= datRoster[i].timeStart ? (datRoster[i].timeEnd - datRoster[i].timeStart) : (24-datRoster[i].timeStart+datRoster[i].timeEnd);
                                }
                            }
                            return(
                                <div key={"item-month"+idx} className="shadow day-item display_col align-center justify-center">
                                    <span className="txt-day">{moment(key).format('MMM').toUpperCase()}</span>
                                    {/*<span className="txt-day">{moment(key).format('Do').toUpperCase()}</span>*/}
                                    <span className="txt-hours">{this.getTotalTime(this.generateTime(hours))}</span>
                                    <span className="txt-unit">HRS</span>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        )
    }
}
export default connect(state => ({
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList,
        memberInfo: state.member.memberInfo
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        memberActions: bindActionCreators(memberActions, dispatch),
    })
)(StaffCalendar);