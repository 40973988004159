/**
 * Created by Trang on 17/10/2017.
 */
import React, {Component} from 'react';
import { Modal, Button } from 'react-bootstrap';

// import ReactCrop, {makeAspectCrop} from "react-image-crop";
import ReactCrop from "react-image-crop";
import "./ReactCrop.css";

class ImageCrop extends Component{

    constructor(props){
        super(props);
        this.state = {
            src: null,
            crop: {
                x: 0,
                y: 0,
                width: 80,
                // height: 80,
            }
        };
    }

    componentDidMount(){
        let aspect= this.props.size !== 'squares' ? (364/190) : (364/364);
        let crop = this.state.crop;
        crop.aspect = aspect;
        this.setState({
            aspect:aspect
        })
    };

    onImageLoaded = async (image, pixelCrop) => {
        this.setState({ image });
        if (pixelCrop) {
            const finalImage = await this.getCroppedImg(
                image,
                pixelCrop,
                "newFile.jpeg"
            );
            this.setState({ finalImage });
        }
    };

    onCropComplete = async (crop, pixelCrop) => {
        if(this.state.image){
            const finalImage = await this.getCroppedImg(
                this.state.image,
                pixelCrop,
                "newFile.jpeg"
            );
            this.setState({ finalImage });
        }
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement("canvas");
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                // Create a File object with the desired name
                const file = new File([blob], fileName, { type: "image/jpeg" });
                resolve(file);
            }, "image/jpeg");
        });
    }

    _onHide = () => {
        if(this.props.onHide) this.props.onHide();
    };

    _crop = () => {
        if(this.state.finalImage){
            if(this.props.onCrop)
                this.props.onCrop(this.state.finalImage);
            this._onHide();
        }
        else{

        }
    };

    render(){
        let {data, show, titleCrop} = this.props;
        if(!data) return null;
        return (
            <Modal show={show} style={{overflow:'hidden'}} className={'crop-modal-img'}>
                {/*<div style={{padding : 10, textAlign: 'center', maxHeight:'80vh', overflow:'scroll'}}>*/}
                <div className="display_col align-center" style={{padding : 10, textAlign: 'center'}}>
                    <ReactCrop
                        src={data}
                        crop={this.state.crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                    <div className="display_row valign-middle align-center" style={{marginTop:10}}>
                        {this.state.finalImage && <img alt="" style={{width:150, height:150}}
                            src={this.state.finalImage ? window.URL.createObjectURL(this.state.finalImage): null}
                        />}
                        <Button onClick={this._crop}
                                style={{height: 45, padding: '0 10px', marginLeft:10, background: '#3379BB', color: 'white'}}>
                            {titleCrop ? titleCrop : "Crop"}
                        </Button>
                        <Button onClick={this._onHide}
                                style={{height: 45, padding: '0 10px' , marginLeft:10, color: '#3379BB', border: '1px solid #3379BB'}}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ImageCrop;