import  firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

export const fbInitializeApp = () => {
    console.log('====== fbInitializeApp ========');

    var config = {
        apiKey: "AIzaSyDRFmFO9UFDLT3qIwU9tfuAMA5XxUS4Dp4",
        authDomain: "teemie-f4a71.firebaseapp.com",
        databaseURL: "https://teemie-f4a71-default-rtdb.firebaseio.com",
        projectId: "teemie-f4a71",
        storageBucket: "teemie-f4a71.appspot.com",
        messagingSenderId: "70464524613",
        appId: "1:70464524613:web:9d728b8fd10c1200ccee2c",
        measurementId: "G-6JQGTS757R"
    };
    firebase.initializeApp(config);
};

export const fbRequestPermission = async (cb) => {
    console.log('====== fbRequestPermission ========');

    try {
        if (!firebase.messaging.isSupported()) {
            return;
        }

        const messaging = firebase.messaging();
        //await messaging.requestPermission();
        const token = await messaging.getToken();

        if (cb) cb(null, token);
        return token;
    } catch (error) {
        if (cb) cb(error);
        console.error(error);
    }
};

export const fbOnMessage = (cb) => {
    console.log('====== fbOnMessage ========');

    try {
        if (!firebase.messaging.isSupported()) {
            return;
        }

        const messaging = firebase.messaging();

        messaging.onMessage(function(payload) {

            if (cb) cb(null, payload);
        });
    } catch (error) {
        if (cb) cb(error);
        console.error(error);
    }
};
