/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/components/login/login.js
 */

import React, { Component } from "react";
import { Button, Image } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as userActions from "../../redux/user/user_actions";
import * as packageActions from "../../redux/package/package_actions";

import "./register.css";
import CForm from "../libs/cform/cform.js";
import Header from "../header/header";
import Config from "../../config";
import ReactLoading from "react-loading";

class RegisterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      isLoading: false,
      listPackage: null,
      queryParams: {
        plan: null,
        team: null,
        price: null,
        planId: null,
      },
    };
    this.isSubmit = false;
    this.dataForm = [
      {
        type: "TEXT",
        key: "name",
        id: "name",
        feedback: false,
        placeholder: "Name",
        className: "",
        def_value: "",
        // def_value: 'Trang',
        validator: "isName",
      },
      {
        type: "TEXT",
        key: "company",
        id: "company",
        feedback: false,
        placeholder: "Company Name",
        className: "",
        def_value: "",
        // def_value: 'Spiritlabs',
        validator: "isName",
      },
      {
        type: "TEXT",
        key: "username",
        id: "username",
        feedback: false,
        placeholder: "Email",
        className: "",
        def_value: "",
        // def_value: 'c01@email.com',
        validator: "isEmailAndMobile",
      },
      {
        type: "PASSWORD",
        key: "password",
        id: "password",
        feedback: false,
        passIcon: true,
        placeholder: "Password",
        className: "",
        def_value: "",
        // def_value: 'password',
        validator: "isPassword",
      },
    ];
    this.setplanDetails = this.setplanDetails.bind(this);
  }
  componentDidMount() {
    let dataPayment = localStorage.getItem('RESPAYMENT');
    console.log('Component-------------',dataPayment);
    console.log(this.props.packageActions);
    this.props.packageActions.getListPackage((err, data) => {
      if (data) {
        this.setState({
          listPackage: data,
        });
        console.log(
          "==============>payment details : ",
          this.state.listPackage
        );
        this.setplanDetails(this.state.listPackage);
      } else {
        Config.popup.show("INFO", [Config.alertMess(err)]);
      }
    });

    
    console.log("window.location", window.location.search);
    if (window.location.search) {
      let params = window.location.search.split("/");
      let plan = params[0].replace("?", "");
      let team = params[1];
      let price = params[2];
      if (plan === "free_plan" && !price) {
        price = "0";
      }
      this.setState({
        queryParams: {
          plan: plan,
          team: team,
          price: price,
        },
      });
    }

    const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        // if (urlParams.has('session_id')) {
        //     console.log(urlParams.get('session_id'));
        //     let params={session_id:urlParams.get('session_id')};
        //     this.props.userActions.updatePaymentLink(params, (err, data) => {
        //         console.log(data);
        //     });
        // }

        if(urlParams.has('session_id')) {

        const savedFormData = JSON.parse(localStorage.getItem('FORMDATA'));

        if (savedFormData !== null) {
          console.log('Updating dataForm with savedFormData:', savedFormData);
          this.dataForm = this.dataForm.map(item => {
            if (savedFormData[item.key]) {
              console.log(`Updating ${item.key} with value: ${savedFormData[item.key]}`);
              return {
                ...item,
                def_value: savedFormData[item.key] || '',
              };
            }           
            return item;
          });
          this.setState({ isDataFormUpdated: true});
          console.log('Updated dataForm:', this.dataForm);        
        }         
      }
      else{
        localStorage.removeItem('FORMDATA');
      }
                
  }
  setplanDetails = (plans) => {
    console.log("plans", plans);
    let paymentDetails = [];
    if (plans) {
      plans.map((plan) => {
        if (
          this.state.queryParams["plan"] === null &&
          plan["type"] == "free_plan"
        ) {
          
          this.setState({
            queryParams: {
              planId: plan["id"],
              plan: plan["type"],
              price: plan["price"],
            },
          });
        } else if (this.state.queryParams["plan"] !== null) {
          if (plan["type"] === this.state.queryParams["plan"]) {
            plan["price"] = this.state.queryParams["price"];
            plan["packageUser"] = this.state.queryParams["team"];
            localStorage.setItem("RESPAYMENT", JSON.stringify(plan));
            this.setState({
              queryParams: {
                planId: plan["id"],
                plan: this.state.queryParams["plan"],
                team: this.state.queryParams["team"],
                price: this.state.queryParams["price"],
              },
            });
          }
        }
      });
    }
    console.log("paymentDetails", paymentDetails);
  };

  onConfirm = (dataPayment) => {
    let check = this.refs["CForm"].formValidate();
    let dt = this.refs["CForm"].formValue();

    this.isSubmit = true;
    this.setState({
      isLoading: true,
    });
    if (!check) {
      let isFieldsEmpty = Object.values(dt).some(field => field.value === '');
      if (isFieldsEmpty){
        this.setState({
          message:
            "* Fill out all fields or input correct details to the fields and try again",
        });        
      } 
      else if(dt.password.validate === "error"){
        this.setState({
          message:
            "* Password must include uppercase, lowercase, at least 1 number, at least 8 characters and maximum 20 characters",
        });
       
      }
      setTimeout(() => {
        this.setState({
          message: "",
          isLoading: false,
        });
        this.isSubmit = false;
      }, 2000);
    } else {
      let params = {
        email: dt.username.value,
      };

      let formdata = {
        name: dt.name.value,
        company: dt.company.value,
        username: dt.username.value
    }; 
    localStorage.setItem("FORMDATA", JSON.stringify(formdata));

      this.props.userActions.checkEmail(params, (err, data) => {
        if (err) {
          this.setState({
            message: Config.alertMess(err),
            isLoading: false,
          });
          this.isSubmit = false;
          // setTimeout(()=>{
          //     this.setState({
          //         message: '',
          //         isLoading: false
          //     });
          //     this.isSubmit=false;
          // },2000);
        } else {
          
            let dataParams = params;
            console.log(dataParams);
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if(urlParams.has('session_id')) {
              const savedDataTeam = JSON.parse(localStorage.getItem('RESPAYMENT')).dataTeam;
              dataParams.dataTeam = savedDataTeam;              
            }else{
              dataParams.dataTeam = dataPayment;
            }            
            console.log(dataPayment);
            console.log("QueryParam", this.state.queryParams);
            console.log("Local storage", localStorage.getItem("RESPAYMENT"));
            dataParams.itemUnit = dataPayment.unit;
            dataParams.packageId = this.state.queryParams["planId"]
              ? this.state.queryParams["planId"]
              : dataPayment.id;
            dataParams.name = dt.name.value;
            dataParams.company = dt.company.value;
            dataParams.password = dt.password.value;
            dataParams.price = this.state.queryParams["price"]
              ? this.state.queryParams["price"]
              : dataPayment.price;
            dataParams.packageUser = this.state.queryParams["team"]
              ? this.state.queryParams["team"]
              : dataPayment.packageUser;
            dataParams.rtoken = dataPayment.rtoken;
            console.log("Before Redirect", dataParams);
            localStorage.setItem("RESPAYMENT", JSON.stringify(dataParams));
            this.isSubmit = false;
            this.setState({
              message: "",
              isLoading: false,
            });

            if(dataPayment.type=='free_plan'){
              let dataPay = this._getDataPayment();
            
            this.props.userActions.registerPayment(dataPay, (err, data)=>{
                if(data){
                    this.setState({isLoading: false});
                    this.isSubmit = false;
                    localStorage.removeItem('RESPAYMENT');
                    window.location.href = "/on-boarding-email";
                }
                else if(err){
                    this.setState({message: Config.alertMess(err),isLoading:false});
                    this.isSubmit = false;
                    // setTimeout(()=>{
                    //     this.setState({message: '', isLoading:false});
                    //     this.isSubmit = false;
                    // },3000);
                }
            });

            }else{
              //window.location.href = "/register-payment";
              this.props.userActions.retrivePaymentLink(dataParams, (err, data) => {
                if(data){
                  console.log(data);
                  window.location.href = data.checkoutUrl;
                }
              });
            }
          }
      });
    }
  };
  _getDataPayment = () => {
    let dataPay = localStorage.getItem('RESPAYMENT');
    dataPay = dataPay && Config.isJSON(dataPay) ? JSON.parse(dataPay) : null;
    if(!dataPay ||(dataPay && (!dataPay.dataTeam || !dataPay.name || !dataPay.company || !dataPay.password))){
        window.location.href = "/home";
    }
    else{
        console.log('Render Details',dataPay);
        return dataPay;
    }
};
  onConfirm1 = (dataPayment, isLink) => {
    let check = this.refs["CForm"].formValidate();
    let dt = this.refs["CForm"].formValue();

    this.isSubmit = true;
    this.setState({
      isLoading: true,
    });

    if (!check) {
      if (dt.password.validate === "error") {
        this.setState({
          message:
            "* Password must include uppercase, lowercase, at least 1 number, at least 8 characters and maximum 20 characters",
        });
      } else {
        this.setState({
          message:
            "Fill out all fields or input correct details to the fields and try again",
        });
      }
      setTimeout(() => {
        this.setState({
          message: "",
          isLoading: false,
        });
        this.isSubmit = false;
      }, 2000);
    } else {
      let params = {
        email: dt.username.value,
      };

      this.props.userActions.checkEmail(params, (err, data) => {
        if (err) {
          this.setState({
            message: Config.alertMess(err),
            isLoading: false,
          });
          this.isSubmit = false;
        } else {
          let dataParams = params;
          console.log(dataParams);
          dataParams.dataTeam = dataPayment;
          console.log(dataPayment);
          console.log("QueryParam", this.state.queryParams);
          console.log("Local storage", localStorage.getItem("RESPAYMENT"));
          dataParams.itemUnit = dataPayment.unit;
          dataParams.packageId = this.state.queryParams["planId"]
            ? this.state.queryParams["planId"]
            : dataPayment.id;
          dataParams.name = dt.name.value;
          dataParams.company = dt.company.value;
          dataParams.password = dt.password.value;
          dataParams.price = this.state.queryParams["price"]
            ? this.state.queryParams["price"]
            : dataPayment.price;
          dataParams.packageUser = this.state.queryParams["team"]
            ? this.state.queryParams["team"]
            : dataPayment.packageUser;
          dataParams.rtoken = dataPayment.rtoken;
          console.log("Before Redirect", dataParams);
          localStorage.setItem("RESPAYMENT", JSON.stringify(dataParams));
          this.isSubmit = false;
          this.setState({
            message: "",
            isLoading: false,
          });
          window.location.href = "/register-payment";
          if (isLink) {
            dataParams.rtoken = dataPayment.rtoken;
            window.location.href = "/on-boarding-email";
          } else {
            this.props.userActions.registerPayment(dataParams, (err, data) => {
              if (data) {
                this.setState({ isLoading: false });
                this.isSubmit = false;
                localStorage.removeItem("RESPAYMENT");
                window.location.href = "/on-boarding-email";
              } else if (err) {
                this.setState({
                  message: Config.alertMess(err),
                  isLoading: false,
                });
                this.isSubmit = false;
              }
            });
          }
        }
      });
    }
  };
  render() {
    let dataPayment = localStorage.getItem("RESPAYMENT");
    dataPayment =
      dataPayment && Config.isJSON(dataPayment)
        ? JSON.parse(dataPayment)
        : null;
    // if(!dataPayment) window.history.back();
    const { isDataFormUpdated } = this.state; 
    return (
      <div className="full_w full_h register-box">
        <Header type={0} login={null} />
        <div className="register row register-company">
          <div className="col-sm-6 register-instruction">
            <Image
              src={require("../../assets/images/register/item1.png")}
              className="image-register"
            />
            <div className="display_row">
              <span className="btn-linear btn-li" />
              <span className="note-register">
                Manage your staff and your company’s daily activities on the go
              </span>
            </div>
            <div className="display_row">
              <span className="btn-linear btn-li" />
              <span className="note-register">
                Improve productivity and efficiency
              </span>
            </div>
            <div className="display_row" style={{ height: 60 }}>
              <span className="btn-linear btn-li" />
              <span className="note-register">
                Build rosters, create and assign tasks, company annoucements and
                more
              </span>
            </div>
            <div className="display_row" style={{ height: 60 }}>
              <span className="btn-linear btn-li" />
              <span className="note-register">
                Watch your company culture and team harmony grow{" "}
              </span>
            </div>
            <div className="display_row" style={{ height: 60 }}>
              <span className="btn-linear btn-li" />
              <span className="note-register">
                Built specifically for the retail and SME sectors
              </span>
            </div>
            <div
              className="display_row text-center align-between"
              style={{
                width: "100%",
                justifyContent: "center",
                margin: "auto",
                position: "absolute",
                bottom: -54,
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <a href="https://teemieapp.com/terms-of-usage/" target="_blank">
                <span
                  className="note-register"
                  style={{ fontSize: 13, margin: 0 }}
                >
                  Terms of Service
                </span>
              </a>
              <a href="https://teemieapp.com/about/" target="_blank">
                <span
                  className="note-register"
                  style={{ fontSize: 13, margin: "0 0 0 20px" }}
                >
                  Privacy Policy
                </span>
              </a>
            </div>
          </div>
          <div className="col-sm-6 register-form" style={{ padding: "0 60px" }}>
            <div className="txt-condensed-extra-bold" style={{ marginTop: -8 }}>
              GREAT CHOICE!
            </div>
            <p
              className="note-register"
              style={{ margin: "20px 0 0", textAlign: "center" }}
            >
              Now lets get you signed up and creating your team
            </p>
            {isDataFormUpdated && (
            <CForm styleForm="formRegister" ref="CForm" data={this.dataForm} /> 
            )}
            {!isDataFormUpdated && (
            <CForm styleForm="formRegister" ref="CForm" data={this.dataForm} /> 
            )}
            <span></span>
            {this.state.message !== "" && (
              <div className="register_error" style={{ bottom: 64 }}>
                {this.state.message}
              </div>
            )}
            <Button
              className="login_btn_login btn-linear shadow"
              onClick={() => this.onConfirm(dataPayment)}
            >
              CONFIRM
              {this.state.isLoading && (
                <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={"#000"}
                    height={30}
                    width={30}
                  />
                </div>
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    listPackage: state.package.listPackage,
  }),
  (dispatch) => ({
    userActions: bindActionCreators(userActions, dispatch),
    packageActions: bindActionCreators(packageActions, dispatch),
  })
)(RegisterPage);
