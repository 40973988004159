import React,{Component} from 'react';
import './t&c.css';


export default class Term extends Component{

    render(){
        return(
            <div className="full_w full_h scroll">
                <div className="register row terms">
                    <div className="col-sm-12" style={{padding:'0 60px'}}>
                        <div className="txt-condensed-extra-bold full_w text-center" style={{
                            fontSize: 24,
                            lineHeight: '30px',
                            marginBottom: 40,
                            width: '100%',
                            textAlign: 'left'
                        }}>TERMS AND USAGE
                        </div>
                        <div className="title-small">
                            <ul>
                                <li>You must be at least 16 years old to use the Service.</li>
                                <li>You may not post violent, nude, partially nude, discriminatory, unlawful, infringing, hateful, pornographic or sexually suggestive photos or other content via the Service.</li>
                                <li>You are responsible for any activity that occurs through your account and you agree you will not sell, transfer, license or assign your account, followers, username, or any account rights. With the exception of people or businesses that are expressly authorized to create accounts on behalf of their employers or clients, Instagram prohibits the creation of and you agree that you will not create an account for anyone other than yourself. You also represent that all information you provide or provided to Instagram upon registration and at all other times will be true, accurate, current and complete and you agree to update your information as necessary to maintain its truth and accuracy.</li>
                                <li>You agree that you will not solicit, collect or use the login credentials of other Instagram users.</li>
                                <li>You are responsible for keeping your password secret and secure.</li>
                                <li>You must not defame, stalk, bully, abuse, harass, threaten, impersonate or intimidate people or entities and you must not post private or confidential information via the Service, including, without limitation, your or any other person's credit card information, social security or alternate national identity numbers, non-public phone numbers or non-public email addresses.</li>
                                <li>You may not use the Service for any illegal or unauthorized purpose. You agree to comply with all laws, rules and regulations (for example, federal, state, local and provincial) applicable to your use of the Service and your Content (defined below), including but not limited to, copyright laws.</li>
                                <li>You are solely responsible for your conduct and any data, text, files, information, usernames, images, graphics, photos, profiles, audio and video clips, sounds, musical works, works of authorship, applications, links and other content or materials (collectively, "Content") that you submit, post or display on or via the Service.</li>
                                <li>You must not change, modify, adapt or alter the Service or change, modify or alter another website so as to falsely imply that it is associated with the Service or Instagram.</li>
                                <li>You must not access Instagram's private API by means other than those permitted by Instagram. Use of Instagram's API is subject to a separate set of terms available here ("API Terms").</li>
                                <li>You must not create or submit unwanted email, comments, likes or other forms of commercial or harassing communications (a/k/a "spam") to any Instagram users.</li>
                                <li>You must not use domain names or web URLs in your username without prior written consent from Instagram.</li>
                                <li>You must not interfere or disrupt the Service or servers or networks connected to the Service, including by transmitting any worms, viruses, spyware, malware or any other code of a destructive or disruptive nature. You may not inject content or code or otherwise alter or interfere with the way any Instagram page is rendered or displayed in a user's browser or device.</li>
                                <li>You must comply with Instagram's Community Guidelines.</li>
                                <li>You must not create accounts with the Service through unauthorized means, including but not limited to, by using an automated device, script, bot, spider, crawler or scraper.</li>
                                <li>You must not attempt to restrict another user from using or enjoying the Service and you must not encourage or facilitate violations of these Terms of Use or any other Instagram terms.</li>
                                <li>Violation of these Terms of Use may, in Instagram's sole discretion, result in termination of your Instagram account. You understand and agree that Instagram cannot and will not be responsible for the Content posted on the Service and you use the Service at your own risk. If you violate the letter or spirit of these Terms of Use, or otherwise create risk or possible legal exposure for Instagram, we can stop providing all or part of the Service to you.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}