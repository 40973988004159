
import React,{Component} from 'react';
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import '../register/register.css'
import './my-team-detail.css'
import {Image} from 'react-bootstrap';
import Config from "../../config";

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

class SearchBox extends Component{

    constructor(props){
        super(props);
        this.state = {
            changeTeam: false,
            idTeam: null,
            memberList: null,
            inviteMember:false,
            teamList:null,
        }
    }

    componentDidMount() {
        let {teamId} = this.props;
        this._init(teamId?teamId:null);
    }

    _init(idTeam){
        let id = window.location.search.split('id=');
        id = (id && id.length>1) ? id[id.length-1] : null;
        id = id ? id: idTeam;
        this.props.teamActions.getTeamList((err, data)=> {
            if(err){
                if(err.code === 'SYS016'){
                    Config.popup.show('INFO',[Config.alertMess(err)],null, ()=>{
                        localStorage.removeItem('LOGIN');
                        window.location.href = '/login';
                        return;
                    });
                    setTimeout(()=>{
                        window.location.href='/login';
                    },3000);
                }
                else{
                    this.setState({
                        teamList:[]
                    });
                }
            }
            if(data){
                let dataTenp = [];
                let checkLogin = localStorage.getItem('LOGIN');
                checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
                for(let i in data){
                    let isStaff = data[i].members.staffs.filter(i=>i.userID === checkLogin.user.id);
                    if(isStaff && isStaff.length <= 0)
                        dataTenp.push(data[i]);
                }

                let location = window.location.href;
                let selectedTid;
                if (window.location.href.indexOf('selectedTid') && location.indexOf('&uid')) {
                    selectedTid = window.location.search.split('selectedTid=');
                    selectedTid = selectedTid && selectedTid.length > 1 ? selectedTid[1] : null;
                }

                let idTemp = selectedTid ? selectedTid : ( id ? id : ((dataTenp && dataTenp.length > 0) ? dataTenp[0].id : null));
                this.setState({
                    idTeam : idTemp,
                    teamList:dataTenp
                },()=>{
                    this._getMemberTeamList();
                    this.props.teamActions.saveTeam(idTemp);
                    if(this.props.dataListTeam) this.props.dataListTeam(dataTenp, idTemp);
                });
            }
        });
    }

    _getMemberTeamList = () => {
        this.props.teamActions.getMemberTeamList({limit:1000, skip:0,team: this.state.idTeam},(err, data)=> {
            if(err){
                if(this.props.searchMember) this.props.searchMember([]);
            }
            else{
                if(this.props.searchMember) this.props.searchMember(data);
            }
        });
    };

    changeTeam = () => {
      this.setState({
          changeTeam: !this.state.changeTeam
      })
    };

    changeDropDownTeam = (data) => {
        this.setState({
            idTeam: data.id
        },()=>{
            this.props.teamActions.saveTeam(data.id);
            this._init(data.id);
            if(this.props.changeDropDownTeam) this.props.changeDropDownTeam(data);
        });
    };

    render(){
        // let {myTeam} = this.props;
        let {teamList} = this.state;
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;        
        let myTeam = null;
        if(this.props.teamList && this.state.idTeam)
            myTeam = this.props.teamList.find(i=>i.id === this.state.idTeam);
        let logo = myTeam && myTeam.members && myTeam.members.admins && myTeam.members.admins.length > 0
            ? myTeam.members.admins[0].userAvatar
            : null;
        let logoCompany = logo ? logo : (checkLogin && checkLogin.company && checkLogin.company.logo) || null;
        return(
                <div className="display_row align-center" style={{width:'100%', maxWidth: 300}}>
                    <Image className="logo-team" src={logoCompany}/>
                    <div className="drop-down-list-team display_row no-hover col-sm-6"
                         onClick={this.changeTeam}>
                        <div className="team-name team-name-title">{(myTeam && myTeam.name) ? myTeam.name : ''}</div>
                        <i className="fa fa-angle-down"/>
                        {this.state.changeTeam && this.state.idTeam && teamList && teamList.length>0 && (
                            <div className="group-team-list-popup shadow-1">
                                <div onClick={this.changeTeam}
                                     className="bg-close-popup full_w full_h"
                                     style={{opacity:0, position:'fixed', top:0, left:0, background:'rgba(0,0,0,0.1)', cursor:'pointer'}}/>
                                {teamList.map((key, idx)=>{
                                    let classView = (key.id === this.state.idTeam);
                                    return(
                                        <div key={idx+'drop-down-item'}
                                             className={"drop-down-list-team display_row col-sm-6 "+(classView ? "background" : "")}
                                             style={{cursor:'pointer'}} onClick={()=>{this.changeDropDownTeam(key)}}>
                                            <div className="team-name">{key.name}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
        )
    }
}
export default connect(state => ({
        checkLogin : state.main.checkLogin,
        teamList: state.team.teamList,
        memberList: state.team.memberList,
        teamId: state.team.teamId
    }),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
    })
)(SearchBox);