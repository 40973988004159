// action types
export const types = {
    MEMBER_INFO: 'MEMBER_INFO',
    MEMBER_EDIT: 'MEMBER_EDIT',
    TEAM_PENDING :'TEAM_PENDING'
};

export function getMemberInfo(params,cb) {
    return{
        type: types.MEMBER_INFO,
        params,
        cb
    }
}

export function editMember(params,cb) {
    return{
        type: types.MEMBER_EDIT,
        params,
        cb
    }
}
