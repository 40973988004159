import {delay} from 'redux-saga';
import {put, takeLatest, take, cancel} from 'redux-saga/effects';
import {types} from "./package_actions";
import Api from '../../services/api';
import Config from '../../config';

//##############################
export function* listPackage(data) {
    try {
        yield delay(300);
        let listPackage = yield Api.get('/package');
        if (listPackage && listPackage.data) {
            yield put({type: types.LIST_PACKAGE + '_SUCCESS', payload: listPackage.data});
            data.cb && data.cb(null, listPackage.data);
            // return;
        }
        else {
            if (data.cb) data.cb(listPackage, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchListPackage() {
    while (true) {
        const watcher = yield takeLatest(types.LIST_PACKAGE, listPackage);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################

//##############################
export function* upgradePackage(data) {
    try {
        yield delay(300);
        console.log('Upgrade Param*************************',data.params);
        let upgradePackage = yield Api.post('/company/upgrade',data.params);
        console.log('Upgrade*************************');
        console.log(upgradePackage);
        if (upgradePackage && upgradePackage.data) {
            Config.tokenLocal = { token: upgradePackage.token };
            console.log('************************Test');
            //yield localStorage.setItem('TOKEN', JSON.stringify(Config.tokenLocal));
            data.cb && data.cb(null, upgradePackage.data);
            // return;
        }
        else {
            if (data.cb) data.cb(upgradePackage, null);
        }
    }
    catch (e) {
        data.cb && data.cb(null, null);
    }
}

export function* watchUpgradePackage() {
    while (true) {
        const watcher = yield takeLatest(types.UPGRADE_PACKAGE, upgradePackage);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

//##############################