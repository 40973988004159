// action types
export const types = {
    TEAM_PENDING:'TEAM_PENDING',
    MESSAGE_LIST: 'MESSAGE_LIST',
    MESSAGE_GROUP_LIST:'MESSAGE_GROUP_LIST',
    MESSAGE_LIST_SUCCESS: 'MESSAGE_LIST_SUCCESS',
    MESSAGE_SEARCH: 'MESSAGE_SEARCH',
    MESSAGE_NEW: 'MESSAGE_NEW',
    MESSAGE_NEW_SUCCESS: 'MESSAGE_NEW_SUCCESS',
    MESSAGE_ROOM: 'MESSAGE_ROOM',
    MESSAGE_ROOM_SUCCESS: 'MESSAGE_ROOM_SUCCESS',
};

export function loadMessage(params,cb) {
    return{
        type: types.MESSAGE_LIST,
        params,
        cb
    }
}

export function loadGroupMessage(params,cb) {
    return{
        type: types.MESSAGE_GROUP_LIST,
        params,
        cb
    }
}

export function searchMessage(params,cb) {
    return{
        type: types.MESSAGE_SEARCH,
        params,
        cb
    }
}

export function newMessage(params,cb) {
    return{
        type: types.MESSAGE_NEW,
        params,
        cb
    }
}

export function messageRoom(params,cb) {
    return{
        type: types.MESSAGE_ROOM,
        params,
        cb
    }
}
