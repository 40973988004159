/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/10/25 16:37
 * @update 2017/10/25 16:37
 * @file src/components/not-found/not-found.js
 */

import React,{Component} from 'react';

export default class ModuleNotFound extends Component{


    render(){

        return(
            <div className="login">
                Error 404: Module not found
            </div>
        )
    }
}