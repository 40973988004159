// action types
export const types = {
    SEARCH_ANNOUNCEMENT: 'SEARCH_ANNOUNCEMENT',
    ADD_ANNOUNCEMENT: 'ADD_ANNOUNCEMENT',
    LIKE_ANNOUNCEMENT: 'LIKE_ANNOUNCEMENT',
    DISLIKE_ANNOUNCEMENT: 'DISLIKE_ANNOUNCEMENT'
};


export function getSearchAnn(params, cb) {
    return {
        type: types.SEARCH_ANNOUNCEMENT,
        params,
        cb
    }
}

export function addAnn(params, cb) {
    return {
        type: types.ADD_ANNOUNCEMENT,
        params,
        cb
    }
}


export function likeAnn(params, cb) {
    return {
        type: types.LIKE_ANNOUNCEMENT,
        params,
        cb
    }
}

export function disLikeAnn(params, cb) {
    return {
        type: types.DISLIKE_ANNOUNCEMENT,
        params,
        cb
    }
}
