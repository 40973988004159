// action types
export const types = {
    REGISTER: 'REGISTER',
    REGISTERLINK: 'REGISTERLINK',
    REGISTERPAYMENT: 'REGISTERPAYMENT',
    EDIT_USER: 'EDIT_USER',
    LOGIN_USER: 'LOGIN_USER',
    LOGIN_USER1: 'LOGIN_USER1',
    FORGOT_PASS: 'FORGOT_PASS',
    FORGOT_PASS_SUCCESS: 'FORGOT_PASS_SUCCESS',
    CHANGE_PASS_USER: 'CHANGE_PASS_USER',
    CHECKOUT_PAYMENT_LINK: 'CHECKOUT_PAYMENT_LINK',
    CHECKOUT_PAYMENT_UPDATE: 'CHECKOUT_PAYMENT_UPDATE',
    CHECK_EMAIL: 'CHECK_EMAIL',
    CHECK_RTOKEN: 'CHECK_RTOKEN',
    CHECK_REGTOKEN: 'CHECK_REGTOKEN',

    CHECK_UPLOAD: 'CHECK_UPLOAD',
    CHECK_DOWNLOAD: 'CHECK_DOWNLOAD',
    CHECK_UPLOAD_MULTI: 'CHECK_UPLOAD_MULTI',
    GET_USER: 'GET_USER',
    SEARCH_USER: 'SEARCH_USER', //list all user or search
    USER_VERIFY: 'USER_VERIFY',
    // CHECK_OUT: 'CHECK_OUT',
};

// export function searchuser(params, cb) {
//     return {
//         type: types.SEARCH_USER,
//         params,
//         cb
//     }
// }

export function verify(params, cb) {
    return {
        type: types.USER_VERIFY,
        params,
        cb
    }
}
export function register(params, cb) {
    return {
        type: types.REGISTER,
        params,
        cb
    }
}
export function registerLink(params, cb) {
    return {
        type: types.REGISTERLINK,
        params,
        cb
    }
}
export function checkEmail(params, cb) {
    return {
        type: types.CHECK_EMAIL,
        params,
        cb
    }
}
export function checKRtoken(params, cb) {
    return {
        type: types.CHECK_RTOKEN,
        params,
        cb
    }
}
export function checKRegtoken(params, cb) {
    return {
        type: types.CHECK_REGTOKEN,
        params,
        cb
    }
}
export function upload(params, cb) {
    return {
        type: types.CHECK_UPLOAD,
        params,
        cb
    }
}

export function download(params, cb) {
    return {
        type: types.CHECK_DOWNLOAD,
        params,
        cb
    }
}

export function uploadMulti(params, cb) {
    return {
        type: types.CHECK_UPLOAD_MULTI,
        params,
        cb
    }
}

export function registerPayment(params, cb) {
    return {
        type: types.REGISTERPAYMENT,
        params,
        cb
    }
}

// export function checkOut(cb) {
//     return{
//         type: types.CHECK_OUT,
//         cb
//     }
// }

export function editUser(params, cb) {
    return {
        type: types.EDIT_USER,
        params,
        cb
    }
}

export function getUser(params, cb) {
    return {
        type: types.GET_USER,
        params,
        cb
    }
}
export function logintok(params,cb) {
    return{
        type: types.LOGIN_USER1,
        params,
        cb
    }
}

export function login(params, cb) {
    return {
        type: types.LOGIN_USER,
        params,
        cb
    }
}

export function forgotPass(params, cb) {
    return {
        type: types.FORGOT_PASS,
        params,
        cb
    }
}

export function forgotPassSuccess(value) {
    return {
        type: types.FORGOT_PASS_SUCCESS,
        value
    }
}

export function changePass(params, cb) {
    return {
        type: types.CHANGE_PASS_USER,
        params,
        cb
    }
}

export function retrivePaymentLink(params, cb) {
    return {
        type: types.CHECKOUT_PAYMENT_LINK,
        params,
        cb
    }
}

export function updatePaymentLink(params, cb) {
    return {
        type: types.CHECKOUT_PAYMENT_UPDATE,
        params,
        cb
    }
}

