/**
 * @copyright 2017 @ Tech Seed Labs
 * @author rocachien
 * @create 2017/08/12 10:37
 * @update 2017/08/12 10:37
 * @file src/components/login/login.js
 */

import React, { Component } from 'react';
import { Button, Image } from 'react-bootstrap';
import * as userActions from '../../redux/user/user_actions';
import CForm from '../libs/cform/cform.js';
import Config from '../../config';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as teamActions from "../../redux/team/team_actions";
import ReactLoading from "react-loading";

class LoginPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            message: '',
            isLoading: false,
            showRedirect: false,
        };

        this.isSubmit = false;
        this.dataForm = [
            {
                type: 'TEXT',
                key: 'username',
                id: 'username',
                feedback: false,
                placeholder: 'Email',
                className: '',
                // def_value: 'c01@email.com',
                def_value: '',
                validator: 'isEmailAndMobile'
            },
            {
                type: 'PASSWORD',
                key: 'password',
                id: 'password',
                feedback: false,
                passIcon: true,
                placeholder: 'Password',
                className: '',
                // def_value: 'password',
                def_value: '',
                validator: 'isRequired'
            },
        ];
    }

    isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    } 

    onLogin() {
        if (this.isSubmit || this.state.isLoading) {
            return;
        }
        let check = this.refs['CForm'].formValidate();
        let dt = this.refs['CForm'].formValue();
        this.setState({
            isLoading: true
        });
        this.isSubmit = true;
        if (!check) {
            this.setState({ message: 'Fill out all fields or input correct details to the fields and try again' });
            this._timer = setTimeout(() => {
                this.setState({
                    message: '',
                    isLoading: false
                });
                this.isSubmit = false;
            }, 5000);
            return null;
        }
        else {
            let params = {
                email: dt.username.value,
                password: dt.password.value
            };
            
            this.props.userActions.login(params, (err, data) => {
                
                if (data) {
                    if (this.isMobileDevice()) {
                        this.setState({ showRedirect: true });
                        return <OnRedirect />;
                    }
                    if (data.user.lastLogin === 0) {
                        window.location.href = "/on-boarding-one";
                    }
                    else window.location.href = '/';
                }
                else if (err) this.loginError(err);
            })
        }
    }

    loginError = (err) => {
        if (err.code === 'US029') {
            this.setState({
                message: Config.alertMess({ code: 'TRAGCODE001' }),
                isLoading: false
            });
        }
        else {
            this.setState({
                message: Config.alertMess(err),
                isLoading: false
            });
        }
        this.isSubmit = false;
    };

    render() {
        return (
            <div>{this.state.showRedirect ? (
                <OnRedirect />
            ) : (
            <div className="full_w full_h_login login-screen">
                <div className='col-lg-6 login-ins'>
                    <div className='logo'>
                        <Image src={require('../../assets/images/teemie-logo.png')} className="image-login" />
                    </div>
                    <div className='instruction'>
                        <ul>
                            <li>With our Roster tool, you can create your team's schedule for the entire week in just seconds on your mobile app. 📱💻</li>
                            <li>Never miss a deadline or task again with Teemie's Task Management tool. Stay on top of your to-do list and keep your team members accountable with ease! 📝
</li>
                            <li>Make important announcements and keep your team up to date with Teemie's Announcement tool. Here's to a more engaged team! 📣</li>
                            <li>And so much more! But you will have to start to find out just how Teemie can optimise your team!</li>
                        </ul>
                    </div>
                    <div className='brand'>
                        <Image src={require('../../assets/images/teemie-brand.png')} className="image-login" />
                    </div>
                </div>
                <div className='col-lg-6 login-fields'>
                    <div className="login">
                    <div className='logo'>
                        <Image src={require('../../assets/images/icon-member.png')} className="image-login" />
                    </div>                        
                        <h3 className='welcome'>Welcome back! Let's get you in and managing your team</h3>
                        <CForm styleForm="textName login-form" ref='CForm' data={this.dataForm} />

                        {this.state.message !== '' && (
                            <div className="register_error">
                                {this.state.message}
                            </div>
                        )}

                        <div className="note-forgot" style={{ cursor: 'pointer' }}
                            onClick={() => { window.location.href = "/forget-password"; }}>
                            Forgot Password?
                        </div>

                        <Button className="login_btn_login btn-linear shadow" onClick={() => this.onLogin()}>
                            CONFIRM
                            {this.state.isLoading &&
                                <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                    <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30} />
                                </div>
                            }
                        </Button>
                        <div className="note-login" style={{ cursor: 'pointer' }}
                            onClick={() => { window.location.href = "https://teemieapp.com/pricing-2/"; }}>
                            Do not have an account? Let's go!
                        </div>
                    </div>
                </div>
            </div>)}
         </div>
        )
    }
}

class OnRedirect extends Component {
    render() {
        return (
                <div className="reg-row" style={{padding:'20px 20px'}} >
                            <div className='onboarding'>
                        <div className='onboarding-logo'>
                        <Image
                    className="logo"
                    src={require("../../assets/images/mobile-responsive-icon.png")}
                    />
                </div>
                   <div className="col-sm-12" style={{padding:'15px 10px 20px'}}>
                        <div className="txt-header"
                             style={{marginBottom:15}}>
                            Thank you and Welcome!
                        </div>
                        <p className="text-center"
                            style={{margin:'20px 0 0'}}>
                            Thank you for signing up to and trying Teemie. As part of your subscription you will have two ways to access the platform. 
                        </p>
                        <div className='mob-logo sm-row'>
                        <Image className="mob-lap col-6"
                            src={require("../../assets/images/imac-pro-space-grey.png")}
                            />
                        <Image className="mob-mobile col-6"
                            src={require("../../assets/images/iphone-13-pro.png")}
                            />
                        </div>
                        <div className='note-change'>   
                        <p className="text-center" style={{margin:'20px 0 0',}}>
                            Our web app which is for management only and gives you a Teemie dashboard on your laptop/desktop to create, manage and edit to the fullest.</p>
                        <p className="text-center" style={{margin:'20px 0 0',}}>
                            Our specialist iOS and Android apps which are for both management and staff. So you can manage on the go.</p>
                        <p className="text-center" style={{margin:'20px 0 0',}}>
                            When initially building your team, we recommend continuing on the web app, or downloading the mobile app.
                        </p>
                        </div> 
                   </div> 
               </div>       
            </div>
        );
    }
}
export default connect(state => ({
}),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
    })
)(LoginPage);