// action types
export const types = {
    TEAM_PENDING:'TEAM_PENDING',
    TEAM_LIST: 'TEAM_LIST',
    TEAM_LIST_SUCCESS: 'TEAM_LIST_SUCCESS',
    TEAM_MEMBER_LIST: 'TEAM_MEMBER_LIST',
    TEAM_MEMBER_LIST_SUCCESS: 'TEAM_MEMBER_LIST_SUCCESS',
    TEAM_MEMBER_SEARCH: 'TEAM_MEMBER_SEARCH',
    TEAM_MEMBER_SEARCH_SUCCESS: 'TEAM_MEMBER_SEARCH_SUCCESS',
    INVITE_MEMBER: 'INVITE_MEMBER',
    REMOVE_MEMBER: 'REMOVE_MEMBER',
    CREATE_TEAM: 'CREATE_TEAM',
    JOIN_TEAM: 'JOIN_TEAM',
    SAVE_TEAM: 'SAVE_TEAM',
    SAVE_TEAM_SUCCESS: 'SAVE_TEAM_SUCCESS',
    REMOVE_TEAM: 'REMOVE_TEAM',
    REMOVE_TEAM_SUCCESS: 'REMOVE_TEAM_SUCCESS',
    EDIT_TEAM: 'EDIT_TEAM',
    TOGGLE_CLOCK: 'TOGGLE_CLOCK',
    GET_TEAM: 'GET_TEAM',
    EDIT_CLOCK_OPTIONS: 'EDIT_CLOCK_OPTIONS',
    GET_CLOCK_OPTIONS: 'GET_CLOCK_OPTIONS'
};

export function getTeamList(cb) {
    return{
        type: types.TEAM_LIST,
        cb
    }
}

export function joinTeam(params, cb) {
    return {
        type: types.JOIN_TEAM,
        params,
        cb
    }
}

export function removeTeam(params, cb) {
    return {
        type: types.REMOVE_TEAM,
        params,
        cb
    }
}

export function createTeam(params,cb) {
    return{
        type: types.CREATE_TEAM,
        params,
        cb
    }
}

export function getMemberTeamList(params,cb) {
    return{
        type: types.TEAM_MEMBER_LIST,
        params,
        cb
    }
}

export function searchMemberTeamList(params,cb) {
    return{
        type: types.TEAM_MEMBER_SEARCH,
        params,
        cb
    }
}

export function inviteMember(params,cb) {
    return{
        type: types.INVITE_MEMBER,
        params,
        cb
    }
}

export function removeMember(params,cb) {
    return{
        type: types.REMOVE_MEMBER,
        params,
        cb
    }
}

export function editTeam(params,cb) {
    return{
        type: types.EDIT_TEAM,
        params,
        cb
    }
}

export function saveTeam(params,cb) {
    return{
        type: types.SAVE_TEAM,
        params,
        cb
    }
}

export function toggleClock(params,cb) {
    return{
        type: types.TOGGLE_CLOCK,
        params,
        cb
    }
}

export function getTeam(params, cb) {
    return{
        type: types.GET_TEAM,
        params,
        cb
    }
}

export function editClockOptions(params, cb) {
    return {
        type: types.EDIT_CLOCK_OPTIONS,
        params,
        cb
    }
}

export function getClockOptions(params, cb) {
    return {
        type: types.GET_CLOCK_OPTIONS,
        params,
        cb
    }
}