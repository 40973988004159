import {mainTypes} from "./main_actions";

const initialState = {
    user: null,
    setting: null,
    loading: null,
    checkLogin:null,
    numberBadge:null
};

export default function (state = initialState, action = {}) {
    switch (action.type){
        case mainTypes.SETTING+'SUCCESS':
            return{
                ...state,
                setting: action.payload
            };
        case mainTypes.LOADING:
            return{
                ...state,
                loading: action.payload
            };
        case mainTypes.UPLOAD_FILE:
            return{
                ...state
            };
        case mainTypes.CHECK_LOGIN+'_SUCCESS':
            return{
                ...state,
                checkLogin: action.payload
            };
        case mainTypes.CHECK_OUT+'_SUCCESS':
            return{
                ...state,
                checkLogin: action.payload
            };
        case mainTypes.GET_BADGE_NUMBER+'_SUCCESS':
            return{
                ...state,
                numberBadge: action.payload
            };
        case mainTypes.UPDATE_BADGE_NUMBER+'_SUCCESS':
            return{
                ...state,
                numberBadge: action.payload
            };
        default:
            return state;
    }
}