
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

//import css
import '../register/register.css'
import './messages.css'
import '../calendar/calendar.css'
import '../my-team/my-team.css'
import '../my-team-detail/my-team-detail.css'

//import action
import * as userActions from "../../redux/user/user_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as messageActions from "../../redux/message/message_actions";

import SearchBar from "../my-team-detail/search-bar";
import Config from "../../config";
import { browserHistory } from 'react-router';
import moment from "moment";
import ReactLoading from "react-loading";
import * as Firebase from "./push-notification";
import { error } from 'jquery';
import {Button} from 'react-bootstrap';
import { isArray } from 'lodash';
import Header from '../header/header';
import Loaderview from '../roster/loaderview';
class Messages extends Component {

    constructor(props) {
        super(props);

        this.state = {
            idTeam: null,
            message: '',
            listChat: null,
            contentChat: [],
            memberActive: null,
            newMess: false,
            memberSearch: [],
            listMess: null,
            userID: null,
            room: null,
            loadMore: false,
            processingMessage:false,
            selectedUsers: [],
            userIDS:[],
            toggleTeamSelection:true,
            isTeamOpened:false,
            isMessageOpen:false
        };

        this.perPage = 10;
        this.onSubmit = false;
        this.dataFilter = {
            limit: 1000,
            skip: 0
        };
        this.onUnload = this.onUnload.bind(this);
    }

    onUnload = () => {
        this.onLeaveRoom();
    };

   componentDidMount () {
        window.addEventListener('beforeunload', this.onUnload);
        let that = this;
        let temp = setInterval(function () {
            console.log("==============Connect socket==============");
            if (Config.socket) {
                
                clearInterval(temp);
                that.onSocketConnection();
            }
        }, 300);
        Firebase.fbOnMessage((error, payload) => {
            this.resetNumberUnread();
        });
        
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
    }

    handleScroll = (event) => {
        let scrollTop = event.target.scrollTop;
        if (scrollTop <= 0) {
            this.searchMessageUser();
        }
    };

    onSocketConnection() {
        console.log("==============Connect socket 1111==============");
        try {
            Config.socket.on('JOIN_ROOM', (data) => {
                // console.log('====== JOIN_ROOM => data: ', data);
                // this.setState({message: '====== JOIN_ROOM => data: ' + JSON.stringify(data)});
            });
            Config.socket.on('LEAVE_ROOM', (data) => {
                // console.log('====== LEAVE_ROOM => data: ', data);
                // this.setState({message: '====== LEAVE_ROOM => data: ' + JSON.stringify(data)});
            });
            Config.socket.on('TYPING_MESSAGE', (data) => {
                // console.log('====== TYPING_MESSAGE => data: ', data);
                // this.setState({message: '====== TYPING_MESSAGE => data: ' + JSON.stringify(data)});
            });
            Config.socket.on('RECEIVE_MESSAGE', (data) => {
              
                
                console.log('====== RECEIVE_MESSAGE => data: ');
                this.setState({ message: '====== RECEIVE_MESSAGE => data: ' + JSON.stringify(data) });
                let params = {
                    company: null,
                    content: data.message,
                    createdAt: moment().valueOf(),
                    id: data.id,
                    receiver: null,
                    room: null,
                    sender: data.id,
                    team: null
                };
                this.updateListMess(params);
                this.scrollBottomAni();
            });

            Config.socket.on('disconnect', function () {
                console.log('================= disconnected =================');
                setTimeout(() => {
                    Config.socket = null;
                }, 1000);
            });
        } catch (error) { 
            console.log('Error on Socket Connection',error);
        }
    }

    onJoinRoom(roomId, userId) {
        console.log('====== onJoinRoom ======');
        if (!Config.socket || Config.socket === null) {
            console.log("socket does not connected");
            return false;
        }

        Config.socket.emit('put',
            {
                url: '/api/message/join',
                data: { room: roomId }
            }, (data) => {
                console.log("join room", data);
                this.setState({ newMess: false });
                // this.resetNumberUnread();
            });
    }

    onLeaveRoom(cb) {
        if (!this.state.room || !this.state.room.id) return;
        if (!Config.socket || Config.socket === null) {
            console.log("socket does not connected");
            return false;
        }

        Config.socket.emit('put',
            {
                url: '/api/message/leave',
                data: { room: this.state.room.id }
            }, (data) => {
                console.log("leave room=================", data);
                this.setState({ room: null });
                if (cb) cb();
            });
    }

    updateListMess = (mess) => {
        console.log('====== Update List Message ======');
        let dataTemp = this.state.listMess ? this.state.listMess.slice(0) : [];
        dataTemp.push(mess);
        this.setState({ listMess: dataTemp }
            // , () => this.loadMessage(() => {
            //     console.log('====== on Load User Message ======');
            //     let params = {
            //         user: mess.receiver,
            //         team: this.state.idTeam
            //     };
            //     this.props.messageActions.loadMessage(params, (err, data) => {
            //         if (data) {
            //             this.props.messageActions.messageRoom(data.room);
            //             this.setState({
            //                 room: data.room,
            //                 listMess: data.message.reverse(),
            //             }, () => {
            //                 this.scrollBottom();
            //             });
            //         }
            //     })
            // })
        );
    };

    loadMessage(cb) { //get list member on team and list message on team.
        
        
        console.log('====== on Load Message ======');
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        const loggedInMemberId = checkLogin.user.id
        
        let params = {
            team: this.state.idTeam
        };
        this.props.messageActions.loadMessage(params, (err, data) => {
            if (data) {
                const filteredMembers = data.member.filter(member => member.id !== loggedInMemberId);
                this.props.messageActions.messageRoom(data.room);
                this.setState({
                    listChat: filteredMembers,
                    room: data.room,
                }, () => {
                    this.scrollBottom();
                    cb && cb();
                    
                });
            }
           
                let showFirstUser = this.state.listChat[0] ? this.state.listChat[0].id : null;
                if (!this.state.isMessageOpen) {
                    this.setState({
                        isMessageOpen:true
                    }, () =>{
                    })
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    if (urlParams && urlParams.has('uid')) {
                        // this.loadMessageUser(showFirstUser)
                    }  
                    else{
                        this.loadMessageUser(showFirstUser)
                    }       
            } 
            
            
        })
    }

    loadGroupMessage(cb) { //get list member on team and list message on team.
        console.log('====== on Load Group Message ======');
        let params = {
            team: this.state.idTeam,
            members:this.state.userIDS,
        };
       
        this.props.messageActions.loadGroupMessage(params, (err, data) => {
            if (data) {
          
                this.props.messageActions.messageRoom(data.room);
                this.setState({
                    listChat: data.member,
                    room: data.room,
                    selectedUsers:[],
                }, () => {
                    this.scrollBottom();
                    cb && cb();
                });
            }
        })
    }

    resetNumberUnread(cb) { // reset number unread meassage on list chat.
        console.log('====== resetNumberUnread ======');
        let params = {
            team: this.state.idTeam
        };
        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
        const loggedInMemberId = checkLogin.user.id
        this.props.messageActions.loadMessage(params, (err, data) => {
            if (data) {
                const filteredMembers = data.member.filter(member => member.id !== loggedInMemberId);
                this.setState({
                    listChat: filteredMembers,
                });
            }
        })
    }

    loadGroupMessageUser = (id) => { 
        console.log('====== on Load Group Message ======');
        let idArray = [];
        if (typeof id === 'string'){
            idArray = id.split(',');
        }
        else{
            idArray=id;
        }
        let params = {
            members: idArray,
            team: this.state.idTeam
        };
        this.setState({memberActive:id})
            console.log("========================params",params);
            this.props.messageActions.loadGroupMessage(params, (err, data) => {
                if (data) {
                    console.log("data==================================="+JSON.stringify(data));
                    this.props.messageActions.messageRoom(data.room);
                    this.setState({
                        room: data.room,
                        listMess: data.message.reverse(),
                        selectedUsers:[],
                    }, () => {
                        this.scrollBottom();
                    });
                    console.log("joinroom==================================="+data.room.id);
                    this.onJoinRoom(data.room.id, id);
                }
            })    
    }
     
    loadMessageUser = (id) => { //get list message of user and user
        browserHistory.push('/messages?type=user&uid=' + id + '&tid=' + this.state.idTeam);
        if (!id) return;
        let u_id =null;
        u_id= id.toString();
        const userArray = u_id.split(',');
        if (this.state.room && this.state.room.id) {            
            if(userArray.length == 1){
            this.onLeaveRoom(() => {
                this.setState({
                    listMess: null,
                    memberActive: id,
                }, () => {

                    this.loadMessage(() => {
                        console.log('====== on Load User Message ======');
                        let singleId=id.toString();
                        let params = {
                            user: singleId,
                            team: this.state.idTeam
                        };
                        this.props.messageActions.loadMessage(params, (err, data) => {
                            if (data) {
                                this.props.messageActions.messageRoom(data.room);
                                this.setState({
                                    room: data.room,
                                    listMess: data.message.reverse(),
                                    selectedUsers:[],
                                }, () => {
                                    this.scrollBottom();
                                    this.onJoinRoom(data.room.id, id);
                                });
                            }
                        })
                    });
                });
            });
            } else{
                this.onLeaveRoom(() => {
                    this.setState({
                        listMess: null,
                        memberActive: id,
                    }, () => {
                this.loadGroupMessageUser(id);
                    });
                })                                  
            }
        } else {
            if(userArray.length == 1){
                this.loadMessage(() => {
                console.log('====== on Load User Message ======');
                let singleId=id.toString();
                    let params = {
                    user: singleId,
                    team: this.state.idTeam
                };
                this.props.messageActions.loadMessage(params, (err, data) => {
                    if (data) {
                        this.props.messageActions.messageRoom(data.room);
                        this.setState({
                            room: data.room,
                            listMess: data.message.reverse(),
                        }, () => {
                            this.scrollBottom();
                        });
                        this.onJoinRoom(data.room.id, id);
                    }
                })
            });
            } else {
                console.log('================================5',id);
                this.loadGroupMessageUser(id);
            };
      }
    }

    
    searchMessageUser() {
        if (!this.state.room || !this.state.listMess) return;
        console.log('====== on Search Message User ======');
        this.setState({
            loadMore: true
        });
        let params = {
            skip: this.state.listMess.length,
            limit: this.perPage,
            sort: JSON.stringify([{ createdAt: 'DESC' }]),
            condition: JSON.stringify({ room: this.state.room.id })
        };
        // console.log(params);
        this.props.messageActions.searchMessage(params, (err, data) => {
            if (data) {
                let dataTemp = this.state.listMess ? this.state.listMess.slice(0) : [];
                let dataReverse = data.reverse();
                dataTemp = dataReverse.concat(dataTemp);
                this.setState({
                    listMess: dataTemp,
                    loadMore: false
                });
            }
            else {
                this.setState({
                    loadMore: false
                })
            }
        })
    }

    onSendMessage() {
        console.log('====== onSendMessage ======');

        if (this.onSubmit){
            console.log('************* onSendMessage Failed to trigger (Previous message response not processed)*************')
            return;
        } 
        this.onSubmit = true;
        this.setState({
            processingMessage: true
        });
        if (!Config.socket || Config.socket === null) {
            console.log("socket does not connected");
            return false;
        }
        console.log('Socket Check Passed');
        let messageInfo={
            team: this.state.idTeam,
            room: this.state.room.id,
            message: this.vlMess ? this.vlMess : ""
        };
        console.log('message Request',messageInfo);
        Config.socket.emit('put',
            {
                url: '/api/message/send',
                data: messageInfo
            }, (data) => {
                console.log('Successfully Message processed',data);
                if (data.body.data) {
                    
                    console.log('====== Message data fetched ======');
                    this.refs.inputChat.value = "";
                    this.onSubmit = false;
                    this.vlMess = "";
                    this.updateListMess(data.body.data);
                    this.setState({
                        processingMessage: false
                    });
                    this.scrollBottomAni();
                        console.log('====== on Load User Message123 ======',this.state.room.id);
                        let checkLogin = localStorage.getItem('LOGIN');
                        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
                        const queryString = window.location.search;
                        const urlParams = new URLSearchParams(queryString); 
                        let memberids;
                        if (urlParams && urlParams.has('uid')) {
                            const uidParam = urlParams.get('uid');     
                            const userIds = uidParam.split(',');
                            memberids=userIds;
                        }
                        if(memberids && memberids.length == 1){
                        let params = {
                            user: data.body.data.receiver,
                            team: this.state.idTeam
                        };
                        this.props.messageActions.loadMessage(params, (err, data) => {
                          
                            if (data) {
                                this.props.messageActions.messageRoom(data.room);
                                this.setState({
                                    room: data.room,
                                    listMess: data.message.reverse(),                                                                    
                                },
                                this.resetNumberUnread(),
                                () => {
                                    this.scrollBottom();                                    
                                });                                                        
                            }
                            if(err){
                                console.log('Error on loading Message',err);
                            }
                            
                        })
                    }else{                  
                            let userid=memberids;
                            let params = {
                                team: this.state.idTeam,
                                members:userid,
                            };
                            this.props.messageActions.loadGroupMessage(params, (err, data) => {
                                if (data) {
                                    this.props.messageActions.messageRoom(data.room);
                                    this.setState({
                                        room: data.room,
                                        userIDS:[],
                                    },
                                    this.resetNumberUnread(),
                                    () => {
                                        this.scrollBottom();                                   
                                    });
                                    if(err){
                                        console.log('Error on loading Message',err);
                                    }
                                }
                            })                       
                        }
                    
                }
                else {
                    console.log('Else for send message',data);
                    this.onSubmit = false;
                    Config.alertMess({ code: 'SOCKETWAIT' })
                }
            });
    }

    onTypingMessage() {
        // console.log('====== onTypingMessage ======');
        if (!Config.socket || Config.socket === null) {
            // console.log("socket does not connected");
            return false;
        }

        Config.socket.emit('put',
            {
                url: '/api/message/typing',
                data: { team: 'ROOM01' }
            }, function (data) {
                // console.log('typing message', data);
                this.setState({ message: JSON.stringify(data) });
            });
    }

    scrollBottom = () => {
        let scroll = setInterval(() => {
            if (this.refs.chat) {
                let el = this.refs.chat;
                let a = el.scrollHeight;
                if (el) {
                    el.scrollTop = a;
                    a += el.scrollHeight;
                    if (a >= el.scrollHeight) {
                        clearInterval(scroll);
                    }
                }
            }
        }, 10);
    };

    scrollBottomAni = () => {
        if (this.refs.chat) {
            let el = this.refs.chat;
            let a = el.scrollTop;
            if (el) {
                let scroll = setInterval(() => {
                    el.scrollTop = a;
                    a += 10;
                    if (a >= el.scrollHeight) {
                        clearInterval(scroll);
                    }
                }, 10)
            }
        }
    };

    _getMemberTeamList = (keyword) => {
        let condition = '';
        if (keyword) {
            condition = JSON.stringify({ "team": this.state.idTeam, "name": (keyword ? keyword : '') });
        }
        else {
            condition = JSON.stringify({ "team": this.state.idTeam });
        }
        this.dataFilter.condition = condition;
        this.props.teamActions.searchMemberTeamList(this.dataFilter);
    };

    _searchgetMemberTeamList = (keyword) => {
        let condition = '';
        if (keyword) {
            condition = JSON.stringify({ "team": this.state.idTeam, "name": keyword });
        } else {
            condition = JSON.stringify({ "team": this.state.idTeam });
        }
        
        this.dataFilter.condition = condition;
        console.log("---------dataFilter condition-", this.dataFilter.condition);
        
        const filteredList = this.state.listChat.filter((member) => {
            return member.fullName.toLowerCase().includes(keyword.toLowerCase()) || 
                   member.title.toLowerCase().includes(keyword.toLowerCase());
        });
            this.setState({ filteredList });
    }
   

    setDataListTeam = (data, id) => {
        this.setState({
            idTeam: this.state.idTeam ? this.state.idTeam : (id ? id : null)
        }, () => {
            this._getMemberTeamList();
            this.loadMessage(() => {
                let arrId = this.getId();
                if (arrId.teamId && arrId.userId) {
                    this.setState({
                        idTeam: arrId.teamId
                    }, () => {
                        this.loadMessageUser(arrId.userId);
                    })
                }
                this.scrollBottom();
            });
        });
    };

    newMess = () => {
        this.setState({
            newMess: !this.state.newMess,
            selectedUsers:[],
        });
        this._getMemberTeamList();
    };

    changeDropDownTeam = (data) => {
        this.setState({
            idTeam: data.id,
            message: '',
            listChat: null,
            contentChat: [],
            memberActive: null,
            newMess: false,
            memberSearch: [],
            userID: null,
            isMessageOpen:false
        }, () => {
            this._getMemberTeamList();
            this.onLeaveRoom(() => {
                this.setState({
                    listMess: null
                }, () => {
                    //browserHistory.push('/messages');
                    browserHistory.push('/messages?selectedTid=' + data.id);
                    this.loadMessage();
                    this.setState({
                        toggleTeamSelection:false
                    });
                });
            });
        });
    };

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            console.log('Send message handleKeyPress success');
            if (!this.vlMess) return;
            console.log('Send message triggred start ');
            this.onSendMessage();
            console.log('Send message triggred End ');
        }
    };

    getId = () => {
        let location = window.location.href;

        if (location.indexOf('&tid') && location.indexOf('&uid')) {
            let teamId = window.location.search.split('&tid=');
            let userId = teamId && teamId.length > 1 ? teamId[0].split('&uid=') : null;

            teamId = teamId && teamId.length > 1 ? teamId[1] : null;
            userId = userId && userId.length > 1 ? userId[1] : null;

            return { teamId: teamId, userId: userId };
        }
        else {
            if(location.indexOf('selectedTid') ) {
                let teamId = window.location.search.split('selectedTid=');
                teamId = teamId && teamId.length > 1 ? teamId[1] : null;

                return { teamId: teamId, userId: null };
            }
            return { teamId: null, userId: null }
        }
    };

    handleMemberClick = (user) => {
        const { selectedUsers } = this.state;    
        // Check if the user is already selected
        const isUserSelected = selectedUsers.some((selectedUser) => selectedUser.id === user.id);   
        // Update the selected users based on the click
        if (isUserSelected) {
          // Deselect the user
          const updatedUsers = selectedUsers.filter((selectedUser) => selectedUser.id !== user.id);
          this.setState({ selectedUsers: updatedUsers });
        } else {
          // Select the user
          this.setState({ selectedUsers: [...selectedUsers, user] });
        }
      };

      selectedMemberUsers=() => {     
        if(this.state.selectedUsers && this.state.selectedUsers.length == 1){        
            this.loadMessageUser([this.state.selectedUsers[0].id]);
            this.setState({selectedUsers:[]})
        }
        else{
            let checkLogin = localStorage.getItem('LOGIN');
            checkLogin = checkLogin ? JSON.parse(checkLogin) : null;
            const userIds = this.state.selectedUsers.map((user) => user.id);
            console.log("(checkLogin.user.id)",checkLogin.user.id);
            userIds.push(checkLogin.user.id);           
            this.setState({userIDS:userIds});
            this.loadMessageUser(userIds);
        }
      }

      _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };

    toggleTeamSelectionClick = () => {
        console.log("toggleTeamSelection",this.state.toggleTeamSelection,this.state.isTeamOpened);
        if(this.state.isTeamOpened){
            console.log("isTeamOpened",this.state.isTeamOpened)
            this.setState({
                toggleTeamSelection: false,
                isTeamOpened: false
            });
        }
        else{
            this.setState({
                toggleTeamSelection: true
            });
    }
}
    isTeamOpened = (value) => {
        console.log("isTeamOpened", value);
        this.setState({
            isTeamOpened: value
        })
    }

    render() {
        let { teamList, memberList } = this.props;
        const { selectedUsers } = this.state;
        let myTeam = [];

        if (teamList && this.state.idTeam) {
            myTeam = teamList.find(i => { return (i.id === this.state.idTeam) });
        }

        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        let listSearch = [];
        let memberActive = [];
        const activeMem=this.state.memberActive;   
        
        const listToRender = this.state.filteredList && this.state.filteredList.length > 0
        ? this.state.filteredList
        : this.state.listChat;

        
  
        if (memberList) {
            listSearch = memberList.filter(i => checkLogin.user && i.user && i.user.id !== checkLogin.user.id && i.status === 'ACTIVE');
            if( activeMem !== null){
            memberActive = memberList.filter(i => activeMem.includes(i.user.id));
            }
        } 

        
        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
        let toggleTeamSelection = this.state.toggleTeamSelection;

        
        return (
            <div className="calendar-view row my-team-detail my-team roster-view1 message-view full_w" onClick={this.toggleTeamSelectionClick}>
                {/*<div style={{position:'relative'}} className="full_w full_h_1">*/}
                <div className="col-sm-12 display_col padding-0 full_h_1 height-100vh" style={{ position: 'relative' }}>
                {!this.state.listChat && <div style={{marginLeft:"-11px"}}> <Loaderview/> </div>}
                <Header isSearch={true}
                                teamList={teamList}
                                myTeam={myTeam}
                                toggleTeamSelection={toggleTeamSelection}
                                dataListTeam={this.setDataListTeam}
                                changeDropDownTeam={this.changeDropDownTeam} type={1} login={login} onLogout={this._onLogout}
                                isTeamOpened={this.isTeamOpened}
                                />

                    <div className="body_container display_row full_h_1 full_w" style={{ height: 'calc(100vh - 50px)' }}>
                   
                       
                        {!this.state.newMess && 
                        <div className="member-view list-chat">
                            <div className="searchDiv">
                                <input 
                                    className="search_bar" 
                                    placeholder='Search here...' 
                                    onChange={(e) => this._searchgetMemberTeamList(e.target.value)} 
                                /> 
                                <div className=''>
                                    <img className="img-search" alt="" src={require('../../assets/images/search-icon.png')} />  
                                </div>     
                                <div className="new-message" onClick={this.newMess}>
                                Group Message 
                                <img className="img-plus" alt="" src={require('../../assets/images/message/icon-plus.png')} />
                            </div>                                               
                            </div>
                            

                            <div className="scroll listOfChat" style={{ maxHeight: 'calc(100% - 96px)' }}>
                                {listToRender && 
                                listToRender.length > 0 && 
                                listToRender.map((key, idx) => {
                                    let memberActive = key.id === this.state.memberActive ? "active" : "";
                                    if(memberActive=="" && Array.isArray(this.state.memberActive) && this.state.memberActive.length<2){
                                        memberActive= this.state.memberActive.indexOf(key.id) !== -1  ? "active" : ""; 
                                    }

                                    let userId = key.id;
                                    if (!Array.isArray(userId)) {
                                        userId = [key.id];
                                    }

                                    if (!key || (checkLogin && checkLogin.id === key.id)) return null;

                                    memberList.map((item, b) => {
                                        if (key && item.user.id === key.id && item.role === "OWNER") {
                                            let a = item.team.name
                                            key.title = a;
                                        }
                                        return item;
                                    });

                                    return (
                                        <div 
                                            key={"member-chat-" + idx} 
                                            className={"member-chat display_row align-center " + memberActive}
                                            onClick={() => this.loadMessageUser(key ? userId : null)}>
                                            {Array.isArray(key.avatar) ? (
                                                <div className='group-images'>
                                                    {key.avatar.slice(0, 3).map((avatar, index) => (
                                                        <img key={index} alt="" src={avatar ? avatar : Config.avaDef} className={`profile-images${index}`} />
                                                    ))}
                                                </div>                                                         
                                            ) : (
                                                <img alt="" src={key.avatar ? key.avatar : Config.avaDef} />
                                            )}                                            
                                            <div className={"display_col align-left chat-member-name"}>
                                                <span className="member-chat-name">{key.fullName}</span>
                                                <span className="member-chat-title">{/\d/.test(key.title) ? null : key.title}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
    



                        {this.state.newMess && <div className="member-view list-chat">
                            <div className="new-message">
                                <input className="input-search" placeholder='Search here...' onChange={(e) => this._getMemberTeamList(e.target.value)} />
                                <span className="text-cancel" onClick={this.newMess}>Cancel</span>                                                           
                            </div>
                            <div className="scroll" style={{ maxHeight: 'calc(100% - 130px)'}}>
                                {listSearch && listSearch.length > 0 && listSearch.map((key, idx) => {

                                    let memberChatTitle;

                                    if (key.role === 'OWNER') {
                                        memberChatTitle = key.team.name;
                                    } else if (key.role === 'STAFF' || key.role === 'ADMIN') {
                                        memberChatTitle = key.title ? key.title : key.team.name;
                                    } else {
                                        memberChatTitle = 'N/A';
                                    }
                                    
                                    return (
                                        <div key={"member-chat-" + idx} className={"member-chat display_row align-center "}
                                            onClick={() => this.handleMemberClick(key.user)}>
                                           {selectedUsers.some((selectedUser) => selectedUser.id === key.user.id) ? (
                                            <div className='group-chat-close'>
                                                <img alt="" src={key.user && key.user.avatar ? key.user.avatar : Config.avaDef} className='group-close'/>
                                            </div>                                               
                                            ) : (                                                                                            
                                                    <img alt="" src={key.user && key.user.avatar ? key.user.avatar : Config.avaDef} />                                                                                            
                                            )}
                                            <div className="display_col align-left" style={{ maxWidth: 'calc(100% - 120px)' }}>
                                                <span className="member-chat-name">{ key.name}</span>
                                                <span className="member-chat-title">{memberChatTitle}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>                       
                            <div className="create-group" style={{ position: 'relative' }}>
                            <Button
                                className={`group-message btn-edit-group menu-left-action btn-linear-orange shadow_popup ${this.state.selectedUsers.length === 0 ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (this.state.selectedUsers.length > 0) {
                                        this.selectedMemberUsers(this.state.selectedUsers);
                                    }
                                }}
                                disabled={this.state.selectedUsers.length === 0}
                            >
                                CONFIRM MESSAGE GROUP
                            </Button>
                            </div>
                        </div>} 
                        
                        {/* {!this.state.memberActive  && this.state.listChat &&  
                        <div className='chat-screen'>
                                <p>Have a chat with your teammates....</p>
                            </div>
                        } */}
                      
                        {this.state.memberActive !== null && memberActive && memberActive.length > 0 &&
                            <div className="member-view chat-view full_w"> 
                                <div className="member-info" style={{ overflowY: this.state.memberActive.length > 4 ? 'auto' : 'hidden' }}>
                                    {memberActive.map(member => (
                                        <div className={"member-chat display_row align-center  col-sm"} style={{ height: 30 }}>
                                            <img alt="" src={member.user && member.user.avatar ? member.user.avatar : Config.avaDef} style={{ height: 40, width: 40, minWidth: 40 }} className='memberChatList'/>
                                            <div className="display_col align-left" style={{ width: '100%' }}>
                                                <span className="member-chat-name">{member.user && member.user.fullName ? member.user.fullName : ''}</span>
                                                <span className="member-chat-title">{member.role === 'OWNER' ? member.team.name : (member.role === 'STAFF' || member.role === 'ADMIN') ? (member.title ? member.title : member.team.name) : 'N/A'} </span>
                                            </div>
                                        </div>
                                    ))} 
                                    {this.state.loadMore &&
                                        <div className="load-more">
                                            <ReactLoading
                                                type={"spinningBubbles"}
                                                height={30}
                                                width={30}
                                                color={'#31CCBC'}
                                            />
                                        </div> 
                                    }
                                </div>
                                <hr className='list-border-bottom' />
                                {this.state.listMess && this.state.listMess.length < 1 &&
                                <div className="chat-box not-started">
                                <p>this conversation has not started yet</p>
                                </div>
                                }
                                <div className="list-message full_h_1 full_w scroll" ref="chat" onScroll={this.handleScroll} style={{ position: 'relative',display: this.state.listMess && this.state.listMess.length < 1 ? 'none' : 'block' }}>
                                    {this.state.listMess && this.state.listMess.length > 0 && this.state.listMess.map((key, idx) => {
                                        if (!key) return null;
                                        let itMe = checkLogin && checkLogin.user && key.sender === checkLogin.user.id ? 'is-me' : '';
                                        let userAvatar = memberActive.filter(i => i.user.id === key.sender);            
                                        let ava;
                                        if (itMe) {
                                            ava = (checkLogin && checkLogin.user && checkLogin.user.avatar) ? checkLogin.user.avatar : Config.avaDef;
                                        } else {
                                            ava = (userAvatar.length > 0 && userAvatar[0].user.avatar) ? userAvatar[0].user.avatar : Config.avaDef;
                                        }
                                          
                                        return (
                                            <div key={"content-chat-" + idx} className={"display_row content-chat " + itMe}>                                                
                                                <img alt="" src={ava} />
                                                <div className="content-text">
                                                    {key.content}           
                                                    <p className="time-chat">{moment(key.createdAt).format('DD-MM-YYYY HH:mm')}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {!this.state.listMess &&
                                        <ReactLoading type={"spinningBubbles"}
                                            color={'#31CCBC'}
                                            height={30}
                                            width={30}
                                            className={'loading-view'} />
                                    }

                                </div>
                                <div className="stick-to-bottom">
                                {this.state.listMess && ( 
                                    <div className="chat-box">
                                    {this.state.processingMessage &&
                                    <div className='message-loading'>
                                    <p>Please wait</p>
                                    </div>
    }
    
                                        <textarea ref="inputChat" placeholder="Type" onChange={e => this.vlMess = e.target.value} onKeyPress={this._handleKeyPress} />
                                        <span>
                                            Press Enter to Send
                                        </span>
                                    </div>
                                )}
                                </div>
                            </div>
                        }
                       
                         { memberActive.length == 0 &&
                            <div className="member-view chat-view full_w"> 
                                <div className="member-info" >
                                    {this.state.loadMore &&
                                        <div className="load-more">
                                            <ReactLoading
                                                type={"spinningBubbles"}
                                                height={30}
                                                width={30}
                                                color={'#31CCBC'}
                                            />
                                        </div> 
                                    }
                                </div>
                              
                                {!this.state.listMess &&
                                <div className="chat-box not-started">
                                <p>Please add  team members to use  messages</p>
                                </div>
                                } 
                            </div>
                        }
                     
                    </div>
                </div>
                {/*</div>*/}
            </div>
        )
    }
}

export default connect(state => ({
    checkLogin: state.main.checkLogin,
    teamList: state.team.teamList,
    memberList: state.team.memberList,
    eventList: state.team.eventList,
    messageList: state.message.messageList,
    messageNew: state.message.messageNew
}),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        messageActions: bindActionCreators(messageActions, dispatch),
    })
)(Messages);